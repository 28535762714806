/*
    Imports
*/
import { Container } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CenterLoading from 'src/components/misc/CenterLoading';
import ListPageTitle from 'src/components/misc/ListPageTitle';
import Page from 'src/components/Page';
import customersService from 'src/services/CustomersServiceClass';
import productsService from 'src/services/ProductsServiceClass';
import ReturnBillForm from './forms/ReturnBillForm';

/*
    Main Working
*/
export default () => {
    const navigate = useNavigate();

    const customerNo = useParams().customerNo;

    const [products, setProducts] = useState();

    const getData = () => {
        productsService
            .getAll()
            .then((data) => {
                console.log(data)
                setProducts(data);
            })
            .catch((error) => {
                if (error.response) if (error.response.status === 401) navigate('/401');
                console.log(error);
            });
    };

    useEffect(() => {
        getData();
    }, []);
    const loading = !products;

    console.log(loading);

    /*
      Main Design
    */
    return (
        <Page title={`Return Bill`}>
            <Container maxWidth="xl">
                <ListPageTitle>{'Returning Bill'}</ListPageTitle>
                {loading ? (
                    <CenterLoading />
                ) : (
                    <ReturnBillForm products={products} />
                )}
            </Container>
        </Page>
    );
};
