import GenericService from './GenericService';
import qs from 'qs';

class ServiceClass extends GenericService {
    constructor() {
        super();
    }

    generalJournal = (date) => this.get(`/accounting/journal?${qs.stringify(date)}`);
    allAccounts = (date) => this.get(`/dashboard/allaccounts?${qs.stringify(date)}`);
    profitLoss = (date) => this.get(`/accounting/profitloss?${qs.stringify(date)}`);
}

const accountingService = new ServiceClass();

export default accountingService;
