/*
  Imports
*/
import { Container } from '@material-ui/core';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import productsService from 'src/services/ProductsServiceClass';
import CenterLoading from 'src/components/misc/CenterLoading';
import Page from '../components/Page';
import SingleProductDetails from './body/SingleProductDetails';
import StocksDetails from './tables/StocksDetails';
import stocksService from 'src/services/StocksServiceClass';
import ListPageTitle from 'src/components/misc/ListPageTitle';
import { SETTINGS } from 'src/config/settings';
import FloatingAdd from 'src/components/misc/Buttons/FloatingButton';
import { StockIcon } from 'src/config/icons';
import SingleRsoProductDetails from './body/SingleRsoProductDetails';
import SingleRsoProductDet from './tables/SingleRsoProductDet';

/*
  Main Working
*/
export default () => {
    /*
    States, Params, Navigation, Query, Variables.
  */
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);

    const [data, setData] = useState({});
    const [totals, setTotals] = useState({
        quantity: 0,
        total_for: 0,
        sold_for: 0
    });

    const [details, setDetails] = useState();

    const { user, product } = useParams();

    const [pagination, setPagination] = useState({
        search: '',
        perPage: SETTINGS.defaultPerPage,
        page: 0,
        order: 'asc',
        sort_by: 'id'
    });

    const title = `Employee Product Details`;

    /*
      Handlers, Functions
    */
    const getData = () => {
        setLoading(true);
        productsService
            .getOne(product)
            .then((res) => {
                console.log('Product', res);
                setData(res);
            })
            .catch((error) => {
                if (error.response) if (error.response.status === 401) navigate('/401');
                navigate('/404');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getProductDetails = () => {
        setLoading(true);
        console.log('Error', pagination);
        productsService
            .getRsoProduct(user, product, { ...pagination })
            .then((res) => {
                setDetails(res);

                let quantity = 0;
                let total_for = 0;
                let received = 0;
                let expense = 0;
                for (let i of res) {
                    console.log('Item', i);
                    quantity += +i.quantity || 0;
                    total_for += +i.total || 0;
                    received += +i.received || 0;
                    expense += +i.expense || 0;
                }
                let sold_for = received + expense;

                console.log('Totals', quantity, total_for, sold_for);

                setTotals({
                    quantity,
                    total_for,
                    sold_for
                });
                setLoading(false);
            })
            .catch((error) => {
                if (error.response) if (error.response.status === 401) navigate('/401');
                console.log('Error', error);
                navigate('/404');
            })

    };

    /*
      Use Effect Hooks.
    */
    useEffect(getProductDetails, [pagination]);

    useEffect(getData, []);


    return (
        <Page title={title}>
            <Container>
                {loading ? (
                    <CenterLoading />
                ) : (
                    <>
                        <ListPageTitle>{title}</ListPageTitle>
                        <SingleRsoProductDetails data={{ ...data, ...totals }} />
                        <br />
                        {!!details &&
                            <SingleRsoProductDet
                                data={details}
                            />
                        }
                    </>
                )}
            </Container>
        </Page>
    );
};
