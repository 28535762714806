/*
  Imports
*/
import { Container } from '@material-ui/core';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import customersService from 'src/services/CustomersServiceClass';
import CenterLoading from 'src/components/misc/CenterLoading';
import Page from '../components/Page';
import CustomersDetails from './tables/CustomersDetails';
import ListPageTitle from 'src/components/misc/ListPageTitle';
import { SETTINGS } from 'src/config/settings';
import FloatingAdd from 'src/components/misc/Buttons/FloatingButton';
import { PaymentsIcon } from 'src/config/icons';

/*
  Main Working
*/
export default () => {
    /*
    States, Params, Navigation, Query, Variables.
  */
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);

    const [data, setData] = useState([]);

    const [pagination, setPagination] = useState({
        search: '',
        perPage: SETTINGS.defaultPerPage,
        page: 0,
        order: 'desc',
        sort_by: 'balance'
    });

    const title = `Customers Details`;

    /*
      Handlers, Functions
    */
    const getData = () => {
        setLoading(true);
        console.log('Error', pagination);
        customersService
            .getAll(pagination)
            .then((res) => {
                setData(res);
            })
            .catch((error) => {
                if (error.response) if (error.response.status === 401) navigate('/401');
                navigate('/404');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    /*
      Use Effect Hooks.
    */
    useEffect(getData, [pagination]);

    const handleAdd = () => {
        navigate('./add');
    };

    /*
    Use Effect Hooks.
  */
    /*
    Main Design
  */
    return (
        <Page title={title}>
            <Container>
                <ListPageTitle>{title}</ListPageTitle>
                {loading ? (
                    <CenterLoading />
                ) : (
                    <>
                        <CustomersDetails
                            data={data}
                            pagination={pagination}
                            setPagination={setPagination}
                        />
                        <FloatingAdd
                            tooltip="add customer payment"
                            onClick={handleAdd}
                            bottomSpacing={(theme) => theme.spacing(2)}
                            icon={PaymentsIcon}
                        />
                    </>
                )}
            </Container>
        </Page>
    );
};
