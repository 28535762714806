/*
  Imports
*/
import { Container } from '@material-ui/core';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import logsService from 'src/services/LogsServiceClass';
import CenterLoading from 'src/components/misc/CenterLoading';
import Page from '../components/Page';
import SingleLogsDetails from './body/SingleLogsDetails';
import ListPageTitle from 'src/components/misc/ListPageTitle';

/*
  Main Working
*/
export default () => {
  /*
    States, Params, Navigation, Query, Variables.
  */
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [data, setData] = useState([]);

  const { id } = useParams();

  const title = `Logs Details`;

  /*
      Handlers, Functions
    */
  const getData = () => {
    setLoading(true);
    logsService
      .getOne(id)
      .then((res) => {
        console.log('Logs', res);
        setData(res);
      })
      .catch((error) => {
        if (error.response) if (error.response.status === 401) navigate('/401');
        navigate('/404');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /*
      Use Effect Hooks.
    */
  useEffect(getData, []);

  /*
    Use Effect Hooks.
  */
  /*
    Main Design
  */
  return (
    <Page title={title}>
      <Container>
        {loading ? (
          <CenterLoading />
        ) : (
          <>
            <ListPageTitle>{title}</ListPageTitle>
            <SingleLogsDetails data={data}  />
          </>
        )}
      </Container>
    </Page>
  );
};
