import { Icon } from '@iconify/react';
import { Fab, Tooltip } from '@material-ui/core';

export default ({ onClick, tooltip, bottomSpacing, icon }) => {
    return (
        <>
            {tooltip ? (
                <Tooltip title={tooltip}>
                    <Fab
                        sx={{
                            '&:hover': {
                                backgroundColor: (theme) => theme.palette.action.hover,
                                color: (theme) => theme.palette.primary.main
                            },
                            color: 'grey.0',
                            backgroundColor: (theme) => theme.palette.primary.main,
                            position: 'fixed',
                            bottom: bottomSpacing,
                            right: (theme) => theme.spacing(2)
                        }}
                        onClick={onClick}
                    >
                        <Icon icon={icon} inline="true" style={{ fontSize: 30 }} />
                    </Fab>
                </Tooltip>
                
            ) : (
                <Fab
                    sx={{
                        '&:hover': {
                            backgroundColor: (theme) => theme.palette.action.hover,
                            color: (theme) => theme.palette.primary.main
                        },
                        color: 'grey.0',
                        backgroundColor: (theme) => theme.palette.primary.main,
                        position: 'fixed',
                        bottom: bottomSpacing,
                        right: (theme) => theme.spacing(2)
                    }}
                     onClick={onClick}
                >
                    <Icon icon={icon} inline="true" style={{ fontSize: 30 }} />
                </Fab>
            )}
        </>
    );
};
