/*
	Imports
*/
import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

/*
	Imports:
		Material UI
*/
import { Grid, InputLabel, TextField, Typography } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { ThemeProvider } from '@material-ui/core/styles';
/*
	Imports:
		Our Imports
		Components and Settings
		Services
*/
import Dialog from 'src/components/misc/alerts/Dialog';
import ServerError from 'src/components/misc/alerts/ServerError';
import LoadingFormButton from 'src/components/misc/Buttons/LoadingFormButton';
import { AddStockSchema, EditStockSchema, ReturnStockSchema } from 'src/config/form-schemas';
import { RouteProducts } from 'src/config/routes';
import { ContentStyle, FormTheme } from '../../theme/form-pages';
import stockService from 'src/services/StockServiceClass';
import productsService from 'src/services/ProductsServiceClass';

/*
	Main Working
*/
export default ({ stock, editing }) => {
    /*
		States, Params, Navigation, Query, Variables.
	*/
    const [serverError, setServerError] = useState('');
    const [openDia, setOpenDia] = useState(false);
    const [product, setProduct] = useState('');

    const navigate = useNavigate();

    /*
		Form Setup
	*/
    console.log(stock);
    const formik = useFormik({
        initialValues: {
            stock: stock.id,
            quantity: editing ? stock.price : 0,
            max: stock.left,
            price: stock.price
        },
        validationSchema: editing ? EditStockSchema : ReturnStockSchema,
        onSubmit: (_values, { setFieldError }) => {
            addData();
        }
    });

    const {
        values,
        errors,
        touched,
        isSubmitting,
        handleSubmit,
        getFieldProps,
        setFieldValue,
        setSubmitting,
        resetForm,
        initialValues,
        setFieldError
    } = formik;

    /*
		Handlers
	*/
    const addData = () => {
        setSubmitting(true);
        if (editing) {
            values.price = values.quantity;
            delete values.quantity;
            delete values.max;
            delete values.stock;
        } else delete values.price;

        console.log(stock.id);
        console.log(values);

        let FuncToCall = stockService.return;
        if (editing) FuncToCall = stockService.update;
        FuncToCall({ ...values }, stock.id)
            .then(() => {
                setOpenDia(true);
            })
            .catch((err) => {
                console.log('Error', err, err.response);
                if (err.response) {
                    for (const key in values) {
                        if (Object.hasOwnProperty.call(values, key)) {
                            if (err.response.data.message.includes(key))
                                setFieldError(key, 'Invalid');
                        }
                    }
                }

                console.log('Error', err, err.response);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const handleClose = () => {
        setOpenDia(false);
        navigate('./..');
    };

    const getProduct = () => {
        productsService
            .getOne(stock.product)
            .then((data) => {
                setProduct(data.name);
            })
            .catch((error) => {
                if (error.response) if (error.response.status === 401) navigate('/401');
            });
    };

    /*
		Use Effect Hooks.
	*/

    useEffect(getProduct, []);

    /*
		Main Design
	*/
    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Typography variant="h6" gutterBottom>
                    Stock Details
                </Typography>

                <ContentStyle>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={6}>
                            <ThemeProvider theme={FormTheme}>
                                <InputLabel>Product</InputLabel>
                            </ThemeProvider>
                            <TextField fullWidth disabled={true} value={product} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <ThemeProvider theme={FormTheme}>
                                <InputLabel>Purchase price</InputLabel>
                            </ThemeProvider>
                            <TextField fullWidth disabled={true} value={stock.price} />
                        </Grid>
                    </Grid>
                </ContentStyle>

                <ContentStyle>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={6}>
                            <ThemeProvider theme={FormTheme}>
                                <InputLabel>Sold</InputLabel>
                            </ThemeProvider>
                            <TextField fullWidth disabled={true} value={stock.sold} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <ThemeProvider theme={FormTheme}>
                                <InputLabel>Left quantity</InputLabel>
                            </ThemeProvider>
                            <TextField fullWidth disabled={true} value={stock.left} />
                        </Grid>
                    </Grid>
                </ContentStyle>

                <ContentStyle>
                    {!editing && (
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={6}>
                                <ThemeProvider theme={FormTheme}>
                                    <InputLabel>Quantity</InputLabel>
                                </ThemeProvider>
                                <NumberFormat
                                    fullWidth
                                    customInput={TextField}
                                    type="text"
                                    {...getFieldProps('quantity')}
                                    inputProps={{
                                        inputMode: 'numeric'
                                    }}
                                    error={Boolean(touched.quantity && errors.quantity)}
                                    helperText={touched.quantity && errors.quantity}
                                />
                            </Grid>
                        </Grid>
                    )}
                    {editing && (
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={6}>
                                <ThemeProvider theme={FormTheme}>
                                    <InputLabel>Price</InputLabel>
                                </ThemeProvider>
                                <NumberFormat
                                    fullWidth
                                    customInput={TextField}
                                    {...getFieldProps('quantity')}
                                    inputProps={{
                                        inputMode: 'numeric'
                                    }}
                                    error={Boolean(touched.quantity && errors.quantity)}
                                    helperText={touched.quantity && errors.quantity}
                                />
                            </Grid>
                        </Grid>
                    )}
                </ContentStyle>

                <Dialog buttonText={'Close'} openDialog={openDia} handleButton={handleClose}>
                    {editing ? `Stock is updated` : `Stock is returned`}
                </Dialog>

                <LoadingFormButton loading={isSubmitting}>
                    {editing ? `Edit` : 'Return'}
                </LoadingFormButton>
                <ServerError open={serverError}>{serverError}</ServerError>
            </Form>
        </FormikProvider>
    );
};
