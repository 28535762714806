/*
    Imports
*/
import { FieldArray, Form, FormikProvider, useFormik } from 'formik';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

/*
    Imports:
        Material UI
*/
import { Grid, InputLabel, TextField, Typography, Select, MenuItem, Box, FormHelperText } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { ThemeProvider } from '@material-ui/core/styles';
import Autocomplete from '@mui/material/Autocomplete';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import DatePicker from '@material-ui/lab/DatePicker';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';

/*
    Imports:
        Our Imports
        Components and Settings
        Services
*/
import Dialog from 'src/components/misc/alerts/Dialog';
import ServerError from 'src/components/misc/alerts/ServerError';
import LoadingFormButton from 'src/components/misc/Buttons/LoadingFormButton';
import { AddPaymentSchema } from 'src/config/form-schemas';
import { RoutePayments, RouteProducts } from 'src/config/routes';
import { ContentStyle, FormTheme } from '../../theme/form-pages';
import productsService from 'src/services/ProductsServiceClass';
import paymentsService from 'src/services/PaymentsServiceClass';
import { SETTINGS } from 'src/config/settings';
import CenterLoading from 'src/components/misc/CenterLoading';


/*
    Main Working
*/
export default ({ editing, rsos, account, products }) => {

    /*
        States, Params, Navigation, Query, Variables.
    */
    const [serverError, setServerError] = useState('');
    const [openDia, setOpenDia] = useState(false);
    const [value, setValue] = useState(rsos[0]);
    const [inputValue, setInputValue] = useState(rsos[0]?.username);
    const [valueA, setValueA] = useState(account[0]);
    const [inputValueA, setInputValueA] = useState('');
    const [loading, setLoading] = useState(false);


    const navigate = useNavigate();

    const paymentID = useParams().paymentID

    /*
        Form Setup
    */
    const formik = useFormik({
        initialValues: {
            details: '',
            note: '',
            amount: 0,
            member: rsos[0],
            extras: [{
                product: products[0],
                amount: 0,
                expense: 0,
                expense_for: null
            }],
            expenseAccount: account[0],
            date: new Date(),
        },
        validationSchema: AddPaymentSchema,
        onSubmit: (_values, { setFieldError }) => {
            addData();
        }
    });

    const { values, errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue, setSubmitting, resetForm, initialValues, setFieldError } = formik;

    /*
        Handlers
    */
    const addData = () => {

        console.log(values, values.extras)

        if (
            values.extras[values.extras.length - 1].amount == 0 &&
            values.extras[values.extras.length - 1].expense == 0
        )
            values.extras.pop()


        for (const prod in values.extras) {
            values.extras[prod].product = values.extras[prod].product.id
            if (values.extras.filter(a => a.expense > 0).length > 0) values.extras[prod].expense_for = values.expenseAccount
            else delete values.extras[prod].expense_for
        }

        setSubmitting(true);
        let FuncToCall = paymentsService.add;
        if (editing) FuncToCall = paymentsService.update;
        FuncToCall({ ...values, member: values.member.id }, paymentID)
            .then(() => {
                setOpenDia(true);
            })
            .catch((err) => {
                console.log("Error", err, err.response);
                if (err.response) {
                    let found = false;
                    for (const key in values) {
                        if (Object.hasOwnProperty.call(values, key)) {
                            if (err.response.data.message.includes(key)) {
                                setFieldError(key, 'Invalid or Already Added');
                                found = true;
                            }
                        }
                    }

                    if (!found) setServerError(err.response.data.message);
                }

                console.log("Error", err, err.response);
            }).finally(() => {
                setSubmitting(false);
            });
    };

    const handleClose = () => {
        setOpenDia(false);
        navigate(RoutePayments);
    };

    const handleEditing = () => {
        if (editing) {
            setLoading(true)
            paymentsService.getOne(paymentID)
                .then((data) => {
                    showExtras(data)
                    setFieldValue('amount', data.amount);
                    setFieldValue('details', data.details);
                    setFieldValue('member', rsos.find(a => a.id == data.member));
                    setFieldValue('note', data.note)
                    setValue(rsos.find(a => a.id == data.member))
                    setInputValue(rsos.find(a => a.id == data.member).username);
                    setFieldValue("date", data.date)
                })
                .catch((_err) => console.log(_err.response))
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    const showExtras = (payment) => {

        setFieldValue('amount', payment.amount);
        setFieldValue('expenseAccount', payment.extras[0]?.expense_for);
        setValueA(
            payment.extras[0]?.expense_for ?
                payment.extras[0]?.expense_for :
                account[0]
        )

        const u = [];

        payment.extras?.map((a) => {
            if (a.amount > 0 || a.expense > 0) {
                u.push({
                    product: products.find(b => a.product == b.id),
                    amount: a.amount,
                    expense: a.expense,
                    expense_for: a.expense_for
                });
            }
            setFieldValue('extras', u);
        });
    }


    const handleTotal = () => {
        setFieldValue('amount', values.extras.reduce((total, item) => (total += +item.amount), 0));
    }

    const handleProductsChange = () => {
        if (!editing) {
            const lastIndex = values.extras?.length - 1;

            if (values.extras[lastIndex]?.amount || values.extras[lastIndex]?.expense) {
                const u = [...values.extras];
                u.push({
                    product: products[0],
                    amount: 0,
                    expense: 0,
                    expense_for: null
                });
                setFieldValue('extras', u);
            }

            setServerError('');
        }
    };


    /*
        Use Effect Hooks.
    */


    useEffect(handleEditing, []);
    useEffect(handleProductsChange, [values.extras]);
    useEffect(handleTotal, [values.extras]);


    /*
        Main Design
    */
    return (
        <>
            {loading &&
                <CenterLoading></CenterLoading>
            }
            {!loading &&
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>

                        {/* <Typography variant="h6" gutterBottom>
                    Product Details
                </Typography> */}

                        <ContentStyle>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6} md={6}>
                                    <ThemeProvider theme={FormTheme}>
                                        <InputLabel label="date">Date</InputLabel>
                                    </ThemeProvider>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            value={values.date}
                                            onChange={(newValue) => {
                                                setFieldValue('date', newValue);
                                            }}
                                            renderInput={(params) => <TextField
                                                fullWidth
                                                {...params}
                                                {...getFieldProps('date')}

                                                error={Boolean(touched.date && errors.date)}
                                                helperText={touched.date && errors.date}
                                            />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>
                        </ContentStyle>
                        <ContentStyle>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6} md={6}>
                                    <ThemeProvider theme={FormTheme}>
                                        <InputLabel>RSO</InputLabel>
                                    </ThemeProvider>
                                    <Autocomplete
                                        disableClearable={true}
                                        options={rsos}
                                        defaultValue={rsos[0]}
                                        value={value}
                                        onChange={(event, newValue) => {
                                            setValue(newValue)
                                            values.member = newValue;
                                        }}
                                        inputValue={inputValue}
                                        onInputChange={(event, newInputValue) => {
                                            setInputValue(newInputValue)
                                        }}
                                        disabled={editing}
                                        getOptionLabel={(option) => option.username}
                                        renderOption={(props, option) => (
                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                {option.name} + {option.username}
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                {...getFieldProps('member')}
                                                disabled={editing}
                                            />
                                        )}
                                    />
                                    <FormHelperText error>{errors ? errors.member : ''}</FormHelperText>
                                </Grid>


                                <Grid item xs={12} sm={6} md={6}>
                                    <ThemeProvider theme={FormTheme}>
                                        <InputLabel>Details</InputLabel>
                                    </ThemeProvider>
                                    <Select
                                        fullWidth
                                        {...getFieldProps('details')}
                                        error={Boolean(touched.details && errors.details)}
                                        helperText={touched.details && errors.details}
                                    >

                                        {SETTINGS.banks.map(a => {
                                            return <MenuItem key={a} value={a}>{a}</MenuItem>
                                        })}

                                    </Select>
                                </Grid>

                            </Grid>
                        </ContentStyle>
                        <ContentStyle>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6} md={6}>
                                    <ThemeProvider theme={FormTheme}>
                                        <InputLabel>Note</InputLabel>
                                    </ThemeProvider>
                                    <TextField
                                        fullWidth
                                        type="text"
                                        {...getFieldProps('note')}
                                        error={Boolean(touched.note && errors.note)}
                                        helperText={touched.note && errors.note}
                                    />
                                </Grid>
                            </Grid>
                        </ContentStyle>

                        <FieldArray name="extras">
                            <>
                                {
                                    values.extras.map((product, index) => (

                                        <ContentStyle>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} sm={6} md={6}>
                                                    <ThemeProvider theme={FormTheme}>
                                                        <InputLabel>Product</InputLabel>
                                                    </ThemeProvider>
                                                    <Autocomplete
                                                        disableClearable={true}
                                                        options={products}
                                                        defaultValue={values.extras[index].product}
                                                        // value={values.extras[index].product}
                                                        onChange={(event, newValue) => {
                                                            const extras = values.extras;
                                                            extras[index].product = newValue;
                                                            setFieldValue('extras', extras);
                                                        }}
                                                        getOptionLabel={(option) => option.name}
                                                        renderOption={(props, option) => (
                                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                                {option.name}
                                                            </Box>
                                                        )}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                fullWidth
                                                                {...getFieldProps(`extras.${index}.product`)}
                                                            />
                                                        )}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={3} md={3}>
                                                    <ThemeProvider theme={FormTheme}>
                                                        <InputLabel>Amount</InputLabel>
                                                    </ThemeProvider>
                                                    <NumberFormat
                                                        name={`extras.${index}.amount`}
                                                        fullWidth
                                                        customInput={TextField}
                                                        type="text"
                                                        allowEmptyFormatting="true"
                                                        inputProps={{
                                                            inputMode: 'numeric'
                                                        }}
                                                        {...getFieldProps(`extras.${index}.amount`)}
                                                    />

                                                </Grid>

                                                <Grid item xs={12} sm={3} md={3}>
                                                    <ThemeProvider theme={FormTheme}>
                                                        <InputLabel>Expense</InputLabel>
                                                    </ThemeProvider>
                                                    <NumberFormat
                                                        name={`extras.${index}.expense`}
                                                        fullWidth
                                                        customInput={TextField}
                                                        type="text"
                                                        allowEmptyFormatting="true"
                                                        inputProps={{
                                                            inputMode: 'numeric'
                                                        }}
                                                        {...getFieldProps(`extras.${index}.expense`)}
                                                    />

                                                </Grid>

                                            </Grid>
                                        </ContentStyle>
                                    ))}
                            </>
                        </FieldArray>

                        <ContentStyle>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6} md={6}>
                                    <ThemeProvider theme={FormTheme}>
                                        <InputLabel>Amount</InputLabel>
                                    </ThemeProvider>
                                    <TextField
                                        fullWidth
                                        disabled={true}
                                        customInput={TextField}
                                        autoComplete="number"
                                        type="text"
                                        {...getFieldProps('amount')}
                                        inputProps={{
                                            inputMode: 'numeric',
                                        }}
                                        error={Boolean(touched.amount && errors.amount)}
                                        helperText={touched.amount && errors.amount}
                                    />
                                </Grid>
                                {
                                    values.extras.filter(a => a.expense > 0).length > 0 &&
                                    <Grid item xs={12} sm={6} md={6}>
                                        <ThemeProvider theme={FormTheme}>
                                            <InputLabel>Expense Account</InputLabel>
                                        </ThemeProvider>
                                        <Autocomplete
                                            disableClearable={true}
                                            options={account}
                                            value={valueA}
                                            onChange={(event, newValue) => {
                                                setValueA(newValue);
                                            }}
                                            inputValue={inputValueA}
                                            onInputChange={(event, newInputValue) => {
                                                values.expenseAccount = newInputValue;
                                                setInputValueA(newInputValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    fullWidth
                                                    {...getFieldProps('expenseAccount')}
                                                    error={Boolean(touched.expenseAccount && errors.expenseAccount)}
                                                    helperText={touched.expenseAccount && errors.expenseAccount}
                                                />
                                            )}
                                        />
                                    </Grid>
                                }
                            </Grid>


                        </ContentStyle>


                        <Dialog
                            buttonText={"Close"}
                            openDialog={openDia}
                            handleButton={handleClose}
                        >
                            {editing ? `Payment is updated` : `Payment is added`}
                        </Dialog>

                        <LoadingFormButton loading={isSubmitting}>
                            {editing ? 'Edit' : 'Add'}
                        </LoadingFormButton>
                        <ServerError open={serverError}>
                            {serverError}
                        </ServerError>
                    </Form>
                </FormikProvider >
            }
        </>
    );
};

