import * as Yup from 'yup';
import {
    nameRegExp,
    phoneRegExp,
} from './regex';

export const ResetSchema = (password) =>
    Yup.object().shape({
        password: Yup.string()
            .required('Password is required')
            .min(6, 'Password must be atleast 6 characters')
            .max(30, 'Password must not be more than 30 characters'),
        confirm: Yup.string()
            .equals([password], 'Passwords do not match')
            .required('Confirm Password is required'),
    });

export const ForgetSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('Valid Email is required'),
});

export const LoginSchema = Yup.object().shape({
    username: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required'),
});

export const AddUserSchema = Yup.object().shape({
    username: Yup.string().required('Username is required'),
    name: Yup.string().required('Name is required').matches(nameRegExp, 'Enter a valid name'),
    email: Yup.string().required('Email is required').email('Enter correct email'),
    editing: Yup.boolean(),
    password: Yup.string().when('editing', {
        is: true,
        then: Yup.string(),
        otherwise: Yup.string().required('Password is required'),
    }),
    number: Yup.string()
        .required('Enter Contact Number')
        .matches(phoneRegExp, 'Enter Correct Number'),
    salary: Yup.number().required('Salary is required').min(0, 'Enter Correct Salary'),
});

export const AddProductSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    price: Yup.number().required('Price is required').min(0, 'Enter correct price'),
    sale_p: Yup.number().when('type', {
        is: 'Percentage',
        then: Yup.number()
            .required('Please enter percentage')
            .min(0.0, 'Please enter correct percentage')
            .max(100.0, 'Please enter correct percentage'),
        otherwise: Yup.number()
            .required('Please enter sale price')
            .min(0, 'Please enter correct price'),
    }),

    purchase_p: Yup.number().when('type', {
        is: 'Percentage',
        then: Yup.number()
            .required('Please enter percentage')
            .min(0.0, 'Please enter correct percentage')
            .max(100.0, 'Please enter correct percentage'),
        otherwise: Yup.number(),
    }),
});

export const AddStockSchema = Yup.object().shape({
    product: Yup.string().required('Product is required'),
    price: Yup.number().required('Price is required').min(0, 'Enter correct price'),
    quantity: Yup.number().required('Quantity is required').min(1, 'Enter correct quantity'),
});

export const ReturnStockSchema = Yup.object().shape({
    max: Yup.number(),
    quantity: Yup.number()
        .required('Quantity is required')
        .min(0, 'Please enter correct quantity')
        .when('max', (max, schema) => {
            return schema.test({
                test: (quantity) => !!max && quantity <= max,
                message: 'You cannot return more than available',
            });
        }),
});

export const EditStockSchema = Yup.object().shape({
    quantity: Yup.number().required('Price is required').min(0, 'Please enter correct price'),
});

export const CustomerSchema = Yup.object().shape({
    number: Yup.string().matches(phoneRegExp, 'Enter correct number'),
    name: Yup.string().matches(nameRegExp, 'Enter correct name'),
    email: Yup.string().email('Enter correct email'),
});

export const GenerateBillSchema = Yup.object().shape({
    grandTotal: Yup.number(),
    items: Yup.array().of(
        Yup.object().shape({
            oprice: Yup.number(),
            left: Yup.number(),
            price: Yup.number()
                .required('Price is required')
                .min(0, 'Enter correct price')
            ,
            quantity: Yup.number()
                .transform((value) => Number.isNaN(value) ? null : value)
                .nullable()
                .when('left', (left, schema) => {
                    return schema.test({
                        test: (quantity) => (!!left && quantity <= left) || quantity == 0,
                        message: 'Enter less than stock available',
                    });
                })
            ,
            taxs: Yup.number().min(0, 'Enter correct tax'),

            discount: Yup.number()
                .transform((value) => Number.isNaN(value) ? null : value)
                .nullable()
                .min(0, 'Enter correct discount')

            ,
        }),
    ),
    paid: Yup.number()
        .required('Enter Paid Value')
        .min(0, 'Enter Correct Value')
        .when('grandTotal', (grandTotal, schema) => {
            return schema.test({
                test: (paid) => (!!grandTotal && paid <= grandTotal) || paid == 0,
                message: 'Paying more than total?',
            });
        }),
});

export const GenerateRsoBillSchema = Yup.object().shape({
    rso: Yup.object().required('Rso is required'),
    grandTotal: Yup.number(),
    items: Yup.array().of(
        Yup.object().shape({
            oprice: Yup.number(),
            left: Yup.number(),
            price: Yup.number().required('Price is required').min(0, 'Enter correct price'),
            quantity: Yup.number()
                .required('Quantity is required')
                .when('left', (left, schema) => {
                    return schema.test({
                        test: (quantity) => (!!left && quantity <= left) || quantity == 0,
                        message: 'Enter less than stock available',
                    });
                }),
            taxs: Yup.number().min(0, 'Enter correct tax'),

            discount: Yup.number()
                .min(0, 'Enter correct discount')
                .when('price', (price, schema) => {
                    return schema.test({
                        test: (discount) => (!!price && discount <= price) || discount == 0,
                        message: 'Enter less than price',
                    });
                }),
        }),
    ),
});

export const ReturnBillSchema = Yup.object().shape({
    grandTotal: Yup.number(),
    paid: Yup.number()
        .required('Enter Paid Value')
        .min(0, 'Enter Correct Value')
        .when('grandTotal', (grandTotal, schema) => {
            return schema.test({
                test: (paid) => (!!grandTotal && paid <= grandTotal) || paid == 0,
                message: 'Returning more than total?',
            });
        }),
    items: Yup.array().of(
        Yup.object().shape({
            oprice: Yup.number(),
            left: Yup.number(),
            price: Yup.number()
                .required('Price is required')
                .min(0, 'Enter correct price')
                .when('oprice', (oprice, schema) => {
                    return schema.test({
                        test: (price) => (!!oprice && price <= oprice) || price == 0,
                        message: 'Enter less than price sold',
                    });
                }),
            quantity: Yup.number()
                .required('Quantity is required')
                .when('left', (left, schema) => {
                    return schema.test({
                        test: (quantity) => (!!left && quantity <= left) || quantity == 0,
                        message: 'Enter less than quantity sold',
                    });
                }),
        }),
    ),
});

export const ReturnRsoBillSchema = Yup.object().shape({
    rso: Yup.object().required('Rso is required'),
    items: Yup.array().of(
        Yup.object().shape({
            oprice: Yup.number(),
            left: Yup.number(),
            price: Yup.number()
                .required('Price is required')
                .min(0, 'Enter correct price'),
            quantity: Yup.number()
                .required('Quantity is required')
                .when('left', (left, schema) => {
                    return schema.test({
                        test: (quantity) => (!!left && quantity <= left) || quantity == 0,
                        message: 'Enter less than quantity sold',
                    });
                }),
        }),
    ),
});

export const AddPaymentSchema = Yup.object().shape({
    member: Yup.object().required('Rso is required'),
    amount: Yup.number().required('Amount is required').min(0, 'Enter correct amount'),
    details: Yup.string().required('Please enter details'),
    extras: Yup.array().of(
        Yup.object().shape({
            amount: Yup.number().required('Amount is required').min(0, 'Enter correct amount'),
            expense: Yup.number().min(0, 'Enter correct expense'),
        }),
    ),
});

export const AddExpenseSlipSchema = Yup.object().shape({
    amount: Yup.number().required('Amount is required').min(0, 'Enter correct amount'),
    details: Yup.string().required('Please enter details'),
});

export const AddTransferSlipSchema = Yup.object().shape({
    amount: Yup.number().required('Amount is required').min(0, 'Enter correct amount'),
    details: Yup.string().required('Please enter details'),
});

export const SettingsSchema = (password) =>
    Yup.object().shape({
        email: Yup.string().required('Email is required').email('Enter correct email'),
        password: Yup.string()
            .min(6, 'Password must be atleast 6 characters')
            .max(30, 'Password must not be more than 30 characters'),
        confirm: Yup.string().equals([password], 'Passwords do not match'),
    });


export const AddServiceSchema = Yup.object().shape({
    price: Yup.number().required('Price is required').min(0, 'Enter correct price'),
    tax: Yup.number().min(0, 'Enter correct tax').nullable(),
    name: Yup.string(),
    items: Yup.array().of(
        Yup.object().shape({
            oprice: Yup.number(),
            left: Yup.number(),
            price: Yup.number()
                .required('Price is required')
                .min(0, 'Enter correct price')
            ,
            quantity: Yup.number()
                .transform((value) => Number.isNaN(value) ? null : value)
                .nullable()

            ,
            taxs: Yup.number().min(0, 'Enter correct tax'),

            discount: Yup.number()
                .transform((value) => Number.isNaN(value) ? null : value)
                .nullable()
                .min(0, 'Enter correct discount')
                .when('price', (price, schema) => {
                    return schema.test({
                        test: (discount) => (!!price && discount <= price) || discount == 0,
                        message: 'Enter less than price',
                    });
                })
            ,
        }),
    ),
});