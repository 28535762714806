/*
  Imports
*/
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow
} from '@material-ui/core';
import { useState } from 'react';
import {SETTINGS} from 'src/config/settings';
import { Link, useNavigate } from 'react-router-dom';
import palette from 'src/theme/palette';
import SearchNotFound from '../../components/misc/alerts/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../components/misc/table';
import { getDateString } from 'src/utils/dateTime';
import Scrollbar from '../../components/Scrollbar';
import { ViewIcon } from 'src/config/icons';

/*
  Global Variables, Functions
*/

/*
  Main Working
*/
export default ({ data, pagination, setPagination }) => {
  /*
    States, Params, Navigation, Query, Variables.
  */

  const TableHeader = [
    { id: 'date', label: 'Date', align: 'left' },
    { id: 'name', label: 'Name', align: 'left' },
    { id: 'details', label: 'Details', align: 'left' }
  ];

  const [selected, setSelected] = useState([]);
  const [search, setSearch] = useState(pagination.search);

  const emptyRows = pagination.page > 0 ? data.length : 0;
  const isUserNotFound = data.length === 0;

  /*
    Handlers, Functions
  */
  const navigate = useNavigate();

  const handleRequestSort = (_event, property) => {
    const isAsc = pagination.sort_by == property && pagination.order == 'asc';
    setPagination({ ...pagination, sort_by: property, order: isAsc ? 'desc' : 'asc' });
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (_event, newPage) => {
    setPagination({ ...pagination, page: newPage });
  };

  const handleChangePerPage = (event) => {
    setPagination({ ...pagination, perPage: parseInt(event.target.value, 10), page: 0 });
  };

  const handleSearchInput = (event) => {
    setSearch(event.target.value);
  };

  const handleSearch = (event) => {
    if (event.keyCode == 13 && search != pagination.search) {
      setPagination({ ...pagination, search, page: 0 });
    }
  };

  const handleMoreMenuCell = (event) => {
    event.preventDefault();
  };

  const handleView = (ID) => {
    navigate(`./${ID}`);
  };

  const MORE_MENU = [{ text: 'View', icon: ViewIcon, event: handleView, id: 0 }];
  /*
    Main Design.
  */
  return (
    <>
      <Card>
        <UserListToolbar
          numSelected={selected.length}
          filterName={search}
          onFilterName={handleSearchInput}
          handleSearch={handleSearch}
        />
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead
                order={pagination.order}
                orderBy={pagination.sort_by}
                headLabel={TableHeader}
                rowCount={data.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
                hideCheckBoxes={true}
              />
              <TableBody>
                {data.map((row) => {
                  const { id, name, details, date } = row;
                  const isItemSelected = selected.indexOf(id) !== -1;

                  return (
                    <TableRow
                      hover
                      key={id}
                      component={Link}
                      to={`./${id}`}
                      tabIndex={-1}
                      role="checkbox"
                      selected={isItemSelected}
                      aria-checked={isItemSelected}
                      style={{
                        backgroundColor: palette.background.hover,
                        textDecoration: 'none'
                      }}
                    >
                      <TableCell padding="checkbox"></TableCell>
                      <TableCell align="left"> {getDateString(date)}</TableCell>
                      <TableCell align="left"> {name}</TableCell>
                      <TableCell align="left"> {details}</TableCell>
                      <TableCell align="right" onClick={handleMoreMenuCell}>
                        <UserMoreMenu ID={[id]} Options={MORE_MENU} />
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ emptyRows }}>
                    <TableCell colSpan={TableHeader.length + 1} />
                  </TableRow>
                )}
              </TableBody>
              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={TableHeader.length + 1} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={search} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={SETTINGS.rowsPerPageList}
          component="div"
          count={
            data.length >= pagination.perPage
              ? -1
              : pagination.perPage * pagination.page + data.length
          }
          rowsPerPage={pagination.perPage}
          page={pagination.page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangePerPage}
        />
      </Card>
    </>
  );
};
