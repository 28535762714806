import GenericService from './GenericService';
import qs from 'qs';

class ServiceClass extends GenericService {
    constructor() {
        super();
    }

    getAll = () => this.get('/salary');

    // getByProject = (ID) => this.get(`/expenses?project=${ID}`);

    getOne = (ID) => this.get(`/salary/${ID}`);

    getByUser = (ID) => this.get(`/salary/member/${ID}`);

    // updateTranscation = (id, paid) => this.put(`/payments/transactions/${id}`, { paid });

    getAccounts = () => this.get('/salary/accounts');

    add = (data, ID) => this.post('/salary', data);

    update = (data, ID) => this.patch(`/salary/${ID}`, data);

    getSalary = (ID, pagination, date) =>
        this.get(`/salary/member/${ID}?${qs.stringify(pagination)}&${qs.stringify(date)}`);
}

const salaryService = new ServiceClass();

export default salaryService;
