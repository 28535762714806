import GenericService from './GenericService';
import qs from 'qs';

class ServiceClass extends GenericService {
    constructor() {
        super();
    }

    getAll = (pagination) => this.get(`/stocks?${qs.stringify(pagination)}`);

    getByProduct = (ID, pagination) =>
        this.get(`/stocks?product=${ID}&${qs.stringify(pagination)}`);

    getOne = (ID) => this.get(`/stocks/${ID}`);

    getReturnStock = (ID) => this.get(`/stocks/return?stock=${ID}`);

    remove = (ID) => this.delete(`/stocks/${ID}`);

    removeReturnStock = (ID) => this.delete(`/stocks/return/${ID}`);
}

const stocksService = new ServiceClass();

export default stocksService;
