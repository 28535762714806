/*
    Imports
*/
import { Container } from '@material-ui/core';
import ListPageTitle from 'src/components/misc/ListPageTitle';
import Page from 'src/components/Page';
import AddCommissionForm from './forms/AddCommissionForm';

/*
    Main Working
*/
export default ({ editing }) => {

    /*
      Main Design
    */
    return (
        <Page title={editing ? `Edit Commission` : `Add Commission`} >
            <Container maxWidth="xl">
                <ListPageTitle>
                    {editing ? `Editing Commission` : "Adding Commission"}
                </ListPageTitle>
                <AddCommissionForm editing={editing} />
            </Container>
        </Page >
    );
};
