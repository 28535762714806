/*
    Imports
*/
import { FieldArray, Form, FormikProvider, useFormik } from 'formik';
import { Children, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useNavigate } from 'react-router-dom';

/*
    Imports:
        Material UI
*/
import {
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import Autocomplete from '@mui/material/Autocomplete';

/*
    Imports:
        Our Imports
        Components and Settings
        Services
*/
import Dialog from 'src/components/misc/alerts/Dialog';
import ServerError from 'src/components/misc/alerts/ServerError';
import LoadingFormButton from 'src/components/misc/Buttons/LoadingFormButton';
import { AddServiceSchema, GenerateBillSchema } from 'src/config/form-schemas';
import billService from 'src/services/BillServiceClass';
import customersService from 'src/services/CustomersServiceClass';
import { ContentStyle, FormTheme } from '../../theme/form-pages';
import { fNumber } from 'src/utils/formatNumber';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import DatePicker from '@material-ui/lab/DatePicker';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import { RouteServices } from 'src/config/routes';
import servicesService from 'src/services/ServicesServiceClass';

/*
    Main Working
*/
export default ({ products, service }) => {


    const navigate = useNavigate();
    let prodName = products.map((a) => a.name);

    const [serverError, setServerError] = useState('');
    const [openDia, setOpenDia] = useState(false);


    /*
        Form Setup
    */

    let initialValues = {
        price: null,
        tax: null,
        name: '',
        items: [
            {
                product: products[0].name,
                price: products[0].price,
                oprice: products[0].price,
                quantity: null,
                tax: null,
                left: products[0].left,
                discount: null,
                total: 0
            }
        ],
    }

    if (service) {
        initialValues = {
            price: service.price,
            tax: service.tax,
            name: service.name,
            items: service.items.map((item) => {
                let p = products.find((prod) => prod.id == item.product);
                console.log('p', p);
                return {
                    product: p.name,
                    price: item.price,
                    oprice: p.price,
                    quantity: item.quantity,
                    tax: item.tax,
                    left: p.left,
                    discount: item.discount,
                    total: item.total
                };
            })
        };
    }


    const formik = useFormik({
        initialValues,
        validationSchema: AddServiceSchema,
        onSubmit: (_values, { setFieldError }) => {
            addData();
        }
    });

    const {
        values,
        errors,
        touched,
        isSubmitting,
        handleSubmit,
        getFieldProps,
        setFieldValue,
        setSubmitting,
        setFieldError,
    } = formik;


    /*
        Handlers
    */
    const addData = () => {
        setSubmitting(true);

        let valuess = { ...values };

        const newitems = valuess.items.filter((item) => item.quantity > 0).map((item) => {
            // item.price = item.oprice - item.discount;
            return { ...item, product: products.find((prod) => prod.name == item.product).id };
        });

        valuess.products = newitems;
        delete valuess.items;
        delete values.grandTotal;


        valuess.products.forEach((o) => {
            delete o.left;
            delete o.total;
            o.price = o.price - +o.discount;
            delete o.oprice;
            o.tax = o.tax || 0;
        });

        let FuncToCall = servicesService.add;
        if (service) FuncToCall = servicesService.update;

        FuncToCall(valuess, service?.id)
            .then((response) => {
                setOpenDia(true);
            })
            .catch((err) => {
                console.log('Error', err, err.response);
                if (err.response) {
                    for (const key in values) {
                        if (Object.hasOwnProperty.call(values, key)) {
                            if (err.response.data.message.includes(key))
                                setFieldError(key, 'Invalid');
                        }
                    }
                }
                console.log('Error', err, err.response);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const handleClose = () => {
        setOpenDia(false);
        navigate(RouteServices);
    };

    const handleProductsChange = () => {
        const lastIndex = values.items.length - 1;

        if (values.items[lastIndex].quantity) {
            const u = [...values.items];
            u.push({
                product: products[0].name,
                price: products[0].price,
                oprice: products[0].price,
                quantity: null,
                tax: null,
                left: products[0].left,
                discount: null,
                total: 0
            });
            setFieldValue('items', u);
        }

        setServerError('');
    };


    function prefillData(customer) {
        setFieldValue('name', customer.name);
        setFieldValue('email', customer.email);
        setFieldValue('address', customer.address);
    }

    function getTotalDiscount() {
        return values.items.reduce((total, item) => (total += +item.quantity * +item.discount), 0);
    }

    function getGrandTotal() {
        return values.items.reduce((total, item) => (total += item.total), 0);
    }

    useEffect(handleProductsChange, [values.items]);

    values.grandTotal = getGrandTotal();
    if (values.paidStatus == 'Paid') values.paid = getGrandTotal();

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Typography variant="h6" gutterBottom>
                    Service Details
                </Typography>
                <ContentStyle>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={6}>
                            <ThemeProvider theme={FormTheme}>
                                <InputLabel>Name</InputLabel>
                            </ThemeProvider>
                            <TextField
                                fullWidth
                                autoComplete="name"
                                {...getFieldProps('name')}
                                error={Boolean(touched.name && errors.name)}
                                helperText={touched.name && errors.name}
                            />
                        </Grid>



                        <Grid item xs={12} sm={6} md={6}>
                            <ThemeProvider theme={FormTheme}>
                                <InputLabel>Price</InputLabel>
                            </ThemeProvider>
                            <NumberFormat
                                fullWidth
                                customInput={TextField}
                                {...getFieldProps('price')}
                                error={Boolean(touched.price && errors.price)}
                                helperText={touched.price && errors.price}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                            <ThemeProvider theme={FormTheme}>
                                <InputLabel>Tax</InputLabel>
                            </ThemeProvider>
                            <NumberFormat
                                fullWidth
                                customInput={TextField}
                                {...getFieldProps('tax')}
                                error={Boolean(touched.tax && errors.tax)}
                                helperText={touched.tax && errors.tax}
                            />
                        </Grid>
                    </Grid>

                </ContentStyle>

                <Typography variant="h6" gutterBottom>
                    Product Details
                </Typography>
                <FieldArray name="items">
                    <>
                        {Children.toArray(
                            values.items.map((product, index) => {



                                return (
                                    <ContentStyle>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={4} md={4}>
                                                <ThemeProvider theme={FormTheme}>
                                                    <InputLabel>Product</InputLabel>
                                                </ThemeProvider>
                                                <Autocomplete
                                                    disableClearable={true}
                                                    options={prodName}
                                                    defaultValue={product.product}
                                                    onChange={(event, newValue) => {
                                                        const items = values.items;
                                                        items[index].product = newValue;

                                                        let p = products.find(
                                                            (a) => a.name == newValue
                                                        )

                                                        let price = p.sale_p;
                                                        if (p.type == 'Percentage')
                                                            price = p.price;

                                                        items[index].price = price;
                                                        items[index].oprice = price;
                                                        items[index].left = p.left;
                                                        setFieldValue('items', items);
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            fullWidth
                                                            {...getFieldProps(`items.${index}.product`)}
                                                        />
                                                    )}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={2} md={2}>
                                                <ThemeProvider theme={FormTheme}>
                                                    <InputLabel>Price</InputLabel>
                                                </ThemeProvider>
                                                <NumberFormat
                                                    name={`items.${index}.price`}
                                                    fullWidth
                                                    customInput={TextField}
                                                    type="text"
                                                    allowEmptyFormatting="true"
                                                    inputProps={{
                                                        inputMode: 'numeric'
                                                    }}
                                                    {...getFieldProps(`items.${index}.price`)}
                                                />
                                                <FormHelperText>
                                                    Orignal price: {values.items[index].oprice}
                                                </FormHelperText>
                                                <FormHelperText error={true}>
                                                    {errors?.items?.length > 0 &&
                                                        errors.items[index]?.price}
                                                </FormHelperText>
                                            </Grid>

                                            <Grid item xs={12} sm={2} md={2}>
                                                <ThemeProvider theme={FormTheme}>
                                                    <InputLabel>Quantity</InputLabel>
                                                </ThemeProvider>
                                                <NumberFormat
                                                    fullWidth
                                                    customInput={TextField}
                                                    type="text"
                                                    allowEmptyFormatting="true"
                                                    inputProps={{
                                                        inputMode: 'numeric'
                                                    }}
                                                    {...getFieldProps(`items.${index}.quantity`)}
                                                />
                                                <FormHelperText>
                                                    In Stock: {fNumber(values.items[index].left)}
                                                </FormHelperText>
                                                <FormHelperText error={true}>
                                                    {errors?.items?.length > 0 &&
                                                        errors.items[index]?.quantity}
                                                </FormHelperText>
                                            </Grid>

                                            <Grid item xs={12} sm={1} md={1}>
                                                <ThemeProvider theme={FormTheme}>
                                                    <InputLabel>Discount</InputLabel>
                                                </ThemeProvider>
                                                <NumberFormat
                                                    fullWidth
                                                    customInput={TextField}
                                                    type="text"
                                                    allowEmptyFormatting="true"
                                                    inputProps={{
                                                        inputMode: 'numeric'
                                                    }}

                                                    {...getFieldProps(`items.${index}.discount`)}
                                                />
                                                <FormHelperText>
                                                    Total Discount:{' '}
                                                    {Math.floor(
                                                        (
                                                            values.items[index].discount *
                                                            values.items[index].quantity
                                                        ).toFixed(3)
                                                    )}
                                                </FormHelperText>
                                                <FormHelperText error={true}>
                                                    {errors?.items?.length > 0 &&
                                                        errors.items[index]?.discount}
                                                </FormHelperText>
                                            </Grid>

                                            <Grid item xs={12} sm={1} md={1}>
                                                <ThemeProvider theme={FormTheme}>
                                                    <InputLabel>Tax</InputLabel>
                                                </ThemeProvider>
                                                <NumberFormat
                                                    fullWidth
                                                    customInput={TextField}
                                                    type="text"
                                                    allowEmptyFormatting="true"
                                                    inputProps={{
                                                        inputMode: 'numeric'
                                                    }}
                                                    {...getFieldProps(`items.${index}.tax`)}
                                                />
                                                <FormHelperText>
                                                    Total Tax:{' '}
                                                    {values.items[index].tax *
                                                        values.items[index].quantity}
                                                </FormHelperText>
                                                <FormHelperText error={true}>
                                                    {errors?.items?.length > 0 &&
                                                        errors.items[index]?.tax}
                                                </FormHelperText>
                                            </Grid>

                                            <Grid item xs={12} sm={2} md={2}>
                                                <ThemeProvider theme={FormTheme}>
                                                    <InputLabel>Total Rs. </InputLabel>
                                                </ThemeProvider>
                                                <TextField
                                                    value={fNumber(
                                                        (values.items[index].total = Math.ceil(
                                                            +values.items[index].quantity *
                                                            (+values.items[index].price +
                                                                +values.items[index].tax -
                                                                +values.items[index].discount)
                                                        ))
                                                    )}
                                                    disabled={true}
                                                />
                                            </Grid>
                                        </Grid>
                                    </ContentStyle>
                                )
                            })
                        )}
                    </>
                </FieldArray>
                <ContentStyle>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={6}>
                            <ThemeProvider theme={FormTheme}>
                                <InputLabel label="totalDiscount">
                                    Total Discount {fNumber(Math.floor(getTotalDiscount()))}
                                </InputLabel>
                            </ThemeProvider>

                        </Grid>
                    </Grid>
                </ContentStyle>

                <Dialog
                    buttonText={'Close'}
                    openDialog={openDia}
                    handleButton={handleClose}
                >
                    {`Service is ${service ? 'Updated' : 'Added'} Successfully`}
                </Dialog>

                <LoadingFormButton disabled={!!serverError} loading={isSubmitting}>
                    {service ? 'Update' : 'Add'}
                </LoadingFormButton>
                <ServerError open={!!serverError}>{serverError}</ServerError>
            </Form>
        </FormikProvider>
    );
};
