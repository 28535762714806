import GenericService from './GenericService';

class StakeServiceClass extends GenericService {
  constructor() {
    super();
  }

  getMonthlyMoney = () => this.get('/monthly');

  getMonthlyBestUsers = () => this.get('/monthly/best');

  getInBank = () => this.get('/account');
}

const statsService = new StakeServiceClass();

export default statsService;
