/*
  Imports
*/
import { Grid, Typography } from '@material-ui/core';
import userService from 'src/services/UserService';
import { SETTINGS } from 'src/config/settings';
import { fNumber } from 'src/utils/formatNumber';

/*
  Main Working
*/
export default ({ data }) => {
    /*
    States, Params, Navigation, Query, Variables.
  */

    const loggedInUser = userService.getLoggedInUser();

    const {
        name,
        type,
        price,
        total,
        total_for,
        returned,
        sold,
        sold_for,
        left,
        left_for,
        purchase_p,
        sale_p,
    } = data;

    /*
    Main Design
  */
    return (
        <>
            <Grid container spacing={0} style={{ paddingBottom: 10, paddingTop: 20 }}>
                <Grid item xs={12} sm={2} md={2}>
                    <Typography fontWeight="bold">Name: </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <Typography>{name}</Typography>
                </Grid>
                <Grid item xs={12} sm={2} md={2}>
                    <Typography fontWeight="bold">Type: </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <Typography>{type}</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={0} style={{ paddingBottom: 10, paddingTop: 10 }}>
                <Grid item xs={12} sm={2} md={2}>
                    <Typography fontWeight="bold">Price: </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <Typography>{price > 1 ? `Rs. ${fNumber(price)}` : `Rs. ${price}`}</Typography>
                </Grid>
                <Grid item xs={12} sm={2} md={2}>
                    <Typography fontWeight="bold">Total: </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <Typography>
                        {total}{' '}
                        {loggedInUser.rank >= SETTINGS.RANKS.manager &&
                            `(Rs. ${fNumber(total_for)})`}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={0} style={{ paddingBottom: 10, paddingTop: 10 }}>
                <Grid item xs={12} sm={2} md={2}>
                    <Typography fontWeight="bold">Returned: </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <Typography>{returned}</Typography>
                </Grid>
                <Grid item xs={12} sm={2} md={2}>
                    <Typography fontWeight="bold">Sold: </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <Typography>
                        {sold}{' '}
                        {loggedInUser.rank >= SETTINGS.RANKS.manager &&
                            `(Rs. ${fNumber(sold_for)})`}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={0} style={{ paddingBottom: 10, paddingTop: 10 }}>
                <Grid item xs={12} sm={2} md={2}>
                    <Typography fontWeight="bold">Left: </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <Typography>
                        {left}{' '}
                        {loggedInUser.rank >= SETTINGS.RANKS.manager &&
                            `(Rs. ${fNumber(left_for)})`}
                    </Typography>
                </Grid>
                {type == 'Percentage' && (
                    <>
                        <Grid item xs={12} sm={2} md={2}>
                            <Typography fontWeight="bold">Purchase Percentage: </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                            <Typography>{purchase_p} %</Typography>
                        </Grid>
                    </>
                )}
            </Grid>
            {type == 'Percentage' && (
                <>
                    <Grid container spacing={0} style={{ paddingBottom: 10, paddingTop: 10 }}>
                        <Grid item xs={12} sm={2} md={2}>
                            <Typography fontWeight="bold">Sale Percentage: </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                            <Typography>{sale_p} %</Typography>
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
};
