/*
    Imports
*/
import { Container } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CenterLoading from 'src/components/misc/CenterLoading';
import ListPageTitle from 'src/components/misc/ListPageTitle';
import Page from 'src/components/Page';
import productsService from 'src/services/ProductsServiceClass';
import GenerateBillForm from './forms/GenerateBillForm';
import servicesService from 'src/services/ServicesServiceClass';

/*
    Main Working
*/
export default () => {

    const navigate = useNavigate();

    const [products, setProducts] = useState([]);
    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(true);

    const getData = async () => {

        try {
            setProducts(await productsService.getAll());
            setServices(await servicesService.getAll());
        }
        catch (error) {
            if (error.response.status === 401) navigate('/401');
        }

        setLoading(false);
    };

    useEffect(getData, []);

    /*
      Main Design
    */
    return (
        <Page title={`Add Bill`}  >
            <Container maxWidth="xl" >
                <ListPageTitle>
                    {"Adding Bill"}
                </ListPageTitle>
                {loading ?
                    <CenterLoading />
                    :
                    <GenerateBillForm products={products} services={services} />
                }

            </Container>
        </Page >
    );
};