/*
    Imports
*/
import { Container } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CenterLoading from 'src/components/misc/CenterLoading';
import ListPageTitle from 'src/components/misc/ListPageTitle';
import Page from 'src/components/Page';
import billService from 'src/services/BillServiceClass';
import productsService from 'src/services/ProductsServiceClass';
import rsoService from 'src/services/RsoServiceClass';
import userService from 'src/services/UserService';
import ReturnBillForm from './forms/ReturnBillForm';
import ReturnRsoBillForm from './forms/ReturnRsoBillForm';

/*
    Main Working
*/
export default () => {
    const navigate = useNavigate();

    const customerNo = useParams().customerNo;

    const [products, setProducts] = useState();

    const [rsos, setRsos] = useState();

    const getData = () => {
        productsService
            .getAll()
            .then((data) => {
                setProducts(data);
            })
            .catch((error) => {
                if (error.response) if (error.response.status === 401) navigate('/401');
                console.log(error);
            });
    };

    const getUsers = () => {
        userService
            .getAll()
            .then((data) => {
                setRsos(data);
            })
            .catch((error) => {
                if (error.response) if (error.response.status === 401) navigate('/401');
                console.log(error);
            });
    };

    useEffect(() => {
        getData();
        getUsers();
    }, []);
    const loading = !products || !rsos;

    console.log(loading);

    /*
      Main Design
    */
    return (
        <Page title={`Return RSO Bill`}>
            <Container maxWidth="xl">
                <ListPageTitle>{'Returning RSO Bill'}</ListPageTitle>
                {loading ? (
                    <CenterLoading />
                ) : (
                    <ReturnRsoBillForm products={products} rsos={rsos} />
                )}
            </Container>
        </Page>
    );
};
