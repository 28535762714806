import { Grid, Typography } from '@material-ui/core';
import { SETTINGS } from 'src/config/settings';

export default function Profile({ user }) {
  const { bank, account, salary } = user;
  return (
    <>
      <Grid container spacing={0} style={{ paddingBottom: 10, paddingTop: 10 }}>
        <Grid item xs={12} sm={2} md={2}>
          <Typography fontWeight="bold">Bank: </Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <Typography>{bank}</Typography>
        </Grid>
        <Grid item xs={12} sm={2} md={2}>
          <Typography fontWeight="bold">Account #: </Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <Typography>{account}</Typography>
        </Grid>
      </Grid>
      {user.rank < SETTINGS.RANKS.owner && (
        <Grid container spacing={0} style={{ paddingBottom: 10, paddingTop: 10 }}>
          <Grid item xs={12} sm={2} md={2}>
            <Typography fontWeight="bold">Salary: </Typography>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Typography>{salary}</Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
}
