/*
  Imports
*/
import {
    Card,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
} from '@material-ui/core';
import { filter } from 'lodash';
import { useState } from 'react';
import { SETTINGS } from 'src/config/settings';
import palette from 'src/theme/palette';
import { getComparator, stabilize } from 'src/utils/table';
import SearchNotFound from '../../components/misc/alerts/SearchNotFound';
import { UserListHead, UserListToolbar } from '../../components/misc/table';
import Scrollbar from '../../components/Scrollbar';
import { fNumber } from 'src/utils/formatNumber';
import CenterLoading from 'src/components/misc/CenterLoading';
import { getDateString } from 'src/utils/dateTime';
import Label from 'src/components/misc/Label';

/*
  Global Variables, Functions
*/

function applySortFilter(array, comparator, query) {
    let toSort = array;
    if (query) {
        toSort = filter(
            array,
            (element) =>
                element.sold_to.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
                String(element.quantity).toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
                String(element.total).toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
                String(element.gtotal).toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
                String(element.return_bill).toLowerCase().indexOf(query.toLowerCase()) !== -1,
        );
    }
    return stabilize(toSort, comparator);
}

/*
  Main Working
*/
export default ({ data, loading }) => {
    /*
    States, Params, Navigation, Query, Variables.
  */

    const TableHeader = [
        { id: 'date', label: 'Date', align: 'left' },
        { id: 'sold_to', label: 'Sold To', align: 'left' },
        { id: 'quantity', label: 'Quantity', align: 'left' },
        { id: 'total', label: 'Total', align: 'left' },
        { id: 'gtotal', label: 'Grand Total', align: 'left' },
        { id: 'return_bill', label: 'Status', align: 'left' },
    ];

    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState(TableHeader[1].id);
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(SETTINGS.defaultPerPage);

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;
    const filteredUsers = applySortFilter(data, getComparator(order, orderBy), filterName);
    const isUserNotFound = filteredUsers.length === 0;
    /*
    Handlers, Functions
  */

    const handleRequestSort = (_event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = data.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (_event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    /*
    Main Design.
  */
    return (
        <>
            {loading ? (
                <CenterLoading />
            ) : (
                <>
                    <Card>
                        <UserListToolbar
                            numSelected={selected.length}
                            filterName={filterName}
                            onFilterName={handleFilterByName}
                        />
                        <Scrollbar>
                            <TableContainer sx={{ minWidth: 800 }}>
                                <Table>
                                    <UserListHead
                                        order={order}
                                        orderBy={orderBy}
                                        headLabel={TableHeader}
                                        rowCount={data.length}
                                        numSelected={selected.length}
                                        onRequestSort={handleRequestSort}
                                        onSelectAllClick={handleSelectAllClick}
                                        hideCheckBoxes={true}
                                    />
                                    <TableBody>
                                        {filteredUsers
                                            .slice(
                                                page * rowsPerPage,
                                                page * rowsPerPage + rowsPerPage,
                                            )
                                            .map((row, index) => {
                                                const {
                                                    date,
                                                    sold_to,
                                                    gtotal,
                                                    quantity,
                                                    total,
                                                    return_bill,
                                                } = row;
                                                const isItemSelected =
                                                    selected.indexOf(index) !== -1;

                                                return (
                                                    <TableRow
                                                        hover
                                                        key={index}
                                                        tabIndex={-1}
                                                        role="checkbox"
                                                        selected={isItemSelected}
                                                        aria-checked={isItemSelected}
                                                        style={{
                                                            backgroundColor:
                                                                palette.background.hover,
                                                            textDecoration: 'none',
                                                        }}
                                                    >
                                                        <TableCell padding="checkbox"></TableCell>
                                                        <TableCell align="left">
                                                            {' '}
                                                            {getDateString(date)}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {' '}
                                                            {sold_to}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {' '}
                                                            {quantity}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            Rs. {fNumber(total)}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            Rs. {fNumber(gtotal)}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <Label
                                                                variant="ghost"
                                                                color={
                                                                    return_bill
                                                                        ? 'error'
                                                                        : 'success'
                                                                }
                                                            >
                                                                {return_bill ? 'Returned' : 'Sold'}
                                                            </Label>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        {emptyRows > 0 && (
                                            <TableRow style={{ height: 53 * emptyRows }}>
                                                <TableCell colSpan={TableHeader.length + 1} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                    {isUserNotFound && (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell
                                                    align="center"
                                                    colSpan={TableHeader.length + 1}
                                                    sx={{ py: 3 }}
                                                >
                                                    <SearchNotFound searchQuery={filterName} />
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )}
                                </Table>
                            </TableContainer>
                        </Scrollbar>

                        <TablePagination
                            rowsPerPageOptions={SETTINGS.rowsPerPageList}
                            component="div"
                            count={data.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Card>
                </>
            )}
        </>
    );
};
