/*
  Imports
*/
import { Container } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ListPageTitle from 'src/components/misc/ListPageTitle';
import rsoService from 'src/services/RsoServiceClass';
import Page from '../components/Page';
import RSOBillsList from './tables/RSOBillsList';
import { SETTINGS } from 'src/config/settings';
import { BillsIcon, ReturnBillIcon } from 'src/config/icons';
import FloatingAdd from 'src/components/misc/Buttons/FloatingButton';
import userService from 'src/services/UserService';

/*
  Main Working
*/
export default () => {
    /*
    States, Params, Navigation, Query, Variables.
  */
    const navigate = useNavigate();

    const [billTableLoading, setBillTableLoading] = useState(true);

    const [bill, setBill] = useState([]);

    const [pagination, setPagination] = useState({
        search: '',
        perPage: SETTINGS.defaultPerPage,
        page: 0,
        order: 'asc',
        sort_by: 'id',
    });

    const title = 'RSO';

    const user = userService.getLoggedInUser();

    /*
      Handlers, Functions
    */

    const getBills = () => {
        setBillTableLoading(true);
        console.log('Error', pagination);
        rsoService
            .getRSOBills(pagination)
            .then((res) => {
                setBill(res);
            })
            .catch((error) => {
                if (error.response) if (error.response.status === 401) navigate('/401');
                navigate('/404');
            })
            .finally(() => {
                setBillTableLoading(false);
            });
    };

    /*
          Use Effect Hooks.
        */
    useEffect(getBills, [pagination]);

    const handleAdd = () => {
        navigate('./bills/generate');
    };

    const handleReturn = () => {
        navigate('./bills/return');
    };

    const handleDeleteBill = (ID) => {
        rsoService
            .remove(ID)
            .then(() => {
                getBills();
            })
            .catch((err) => console.log(err));
    };

    /*
    Handlers, Functions
  */

    /*
    Main Design
  */
    return (
        <Page title={title}>
            <Container>
                <>
                    <ListPageTitle>RSO Bills</ListPageTitle>
                    <RSOBillsList
                        data={bill}
                        pagination={pagination}
                        setPagination={setPagination}
                        loading={billTableLoading}
                        handleDelete={handleDeleteBill}
                    />
                    <FloatingAdd
                        tooltip="add rso bill"
                        onClick={handleAdd}
                        bottomSpacing={(theme) => theme.spacing(2)}
                        icon={BillsIcon}
                    />

                    {user.rank >= SETTINGS.RANKS.manager && (
                        <FloatingAdd
                            tooltip="return bill"
                            onClick={handleReturn}
                            bottomSpacing={(theme) => theme.spacing(10)}
                            icon={ReturnBillIcon}
                        />
                    )}
                </>
            </Container>
        </Page>
    );
};
