// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
// components
import ScrollToTop from './components/misc/ScrollToTop';
import { AppProvider } from './pages/context/AppContext';
import { useEffect } from 'react';
import { SETTINGS } from './config/settings';

// ----------------------------------------------------------------------

export default function App() {

  const handleFavicon = () => {
    const favicon1 = document.getElementById("favicon1");
    const favicon2 = document.getElementById("favicon2");
    const favicon3 = document.getElementById("favicon3");
    favicon1.href = favicon2.href = favicon3.href = SETTINGS.FaviconImage;
  };

  useEffect(() => {
    handleFavicon()
  }, [])

  return (
    <ThemeConfig>
      <ScrollToTop />
      <AppProvider>
        <Router />
      </AppProvider>
    </ThemeConfig>
  );
}
