/*
  Imports
*/
import { Field, Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

/*
  Imports:
    Material UI
*/
import {
  Avatar,
  Box,
  Button,
  Grid,
  InputAdornment,
  IconButton,
  InputLabel,
  TextField,
  Select,
  MenuItem,
  Typography
} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { ThemeProvider } from '@material-ui/core/styles';

/*
  Imports:
    Our Imports
    Components and Settings
    Services
*/
import { Icon } from '@iconify/react';
import AlertSnackbar from 'src/components/misc/alerts/AlertSnackbar';
import Dialog from 'src/components/misc/alerts/Dialog';
import ServerError from 'src/components/misc/alerts/ServerError';
import LoadingFormButton from 'src/components/misc/Buttons/LoadingFormButton';
import { AddUserSchema } from 'src/config/form-schemas';
import { RouteUsers } from 'src/config/routes';
import { SETTINGS } from '../../config/settings';
import userService from '../../services/UserService';
import { ContentStyle, FormTheme } from '../../theme/form-pages';
import { getImage } from 'src/utils/misc';
import { HidePasswordIcon, ShowPasswordIcon } from 'src/config/icons';
import CenterLoading from 'src/components/misc/CenterLoading';
import { Switch } from '@mui/material';

/*
  Main Working
*/
export default ({ ranks, editing }) => {
  /*
    States, Params, Navigation, Query, Variables.
  */
  const [serverError, setServerError] = useState('');
  const [openDia, setOpenDia] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [wrongFile, setWrongFile] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const userID = useParams().userID;
  const loggedInUser = userService.getLoggedInUser();

  const navigate = useNavigate();

  /*
    Form Setup
  */
  const formik = useFormik({
    initialValues: {
      name: '',
      username: '',
      email: '',
      number: '',
      address: '',
      bank: '',
      account: '',
      salary: '',
      image: null,
      rank: 1,
      editing: editing,
      retired: 0,
    },
    validationSchema: AddUserSchema,
    onSubmit: () => {
      addData();
    }
  });

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    setSubmitting,
    setFieldError
  } = formik;

  /*
    Handlers
  */
  const addData = () => {
    setSubmitting(true);
    let FuncToCall = userService.addUser;
    if (editing) FuncToCall = userService.updateUser;
    let valuess = { ...values };
    delete valuess.image;
    FuncToCall(valuess, selectedImage, userID)
      .then(() => {
        setOpenDia(true);
      })
      .catch((err) => {
        console.log('Error', err, err.response);
        if (err.response) {
          for (const key in values) {
            if (Object.hasOwnProperty.call(values, key)) {
              if (err.response.data.message.includes(key))
                setFieldError(key, 'Invalid or Already Added');
            }
          }
        }
        if (err.fileUploadError) {
          setImageUrl(values.image);
          setWrongFile(err.msg);
          setTimeout(() => setWrongFile(false), hideFileAlertIn);
        }
        setServerError('An Error Occured');

        console.log('Error', err, err.response);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleClose = () => {
    setOpenDia(false);
    navigate(RouteUsers);
  };

  const handleImageChange = () => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  };

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const handlePasswordUpdate = () => {
    setPassword(values.password);
  };

  const handleEditing = () => {
    if (editing) {
      setLoading(true)
      userService
        .getUser(userID)
        .then((data) => {
          setFieldValue('username', data.username);
          setFieldValue('name', data.name);
          setFieldValue('account', data.account);
          setFieldValue('email', data.email);
          setFieldValue('number', data.number);
          setFieldValue('address', data.address);
          setFieldValue('bank', data.bank);
          setFieldValue('salary', data.salary);
          setFieldValue('rank', data.rank);
          setFieldValue('image', data.image ? data.image : null);
          setFieldValue('retired', data.retired);
          setImageUrl(getImage(data.image));
        })
        .catch((_err) => console.log(_err.response))
        .finally(() => setLoading(false))
    }
  };

  /*
    Use Effect Hooks.
  */
  useEffect(handleImageChange, [selectedImage]);
  useEffect(handleEditing, []);

  /*
    Main Design
  */
  return (
    <>
      {loading &&
        <CenterLoading></CenterLoading>
      }
      {!loading &&
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Typography variant="h6" gutterBottom>
              Personal Details
            </Typography>

            <ContentStyle>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={6}>
                  <ThemeProvider theme={FormTheme}>
                    <InputLabel>Name</InputLabel>
                  </ThemeProvider>
                  <TextField
                    fullWidth
                    autoComplete="name"
                    {...getFieldProps('name')}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <ThemeProvider theme={FormTheme}>
                    <InputLabel>Username</InputLabel>
                  </ThemeProvider>
                  <TextField
                    fullWidth
                    autoComplete="username"
                    {...getFieldProps('username')}
                    error={Boolean(touched.username && errors.username)}
                    helperText={touched.username && errors.username}
                  />
                </Grid>
              </Grid>
            </ContentStyle>

            <ContentStyle>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={6}>
                  <ThemeProvider theme={FormTheme}>
                    <InputLabel>Email</InputLabel>
                  </ThemeProvider>
                  <TextField
                    fullWidth
                    autoComplete="email"
                    {...getFieldProps('email')}
                    inputProps={{
                      inputMode: 'email'
                    }}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <ThemeProvider theme={FormTheme}>
                    <InputLabel label="Address">Contact Number</InputLabel>
                  </ThemeProvider>
                  <NumberFormat
                    fullWidth
                    customInput={TextField}
                    autoComplete="number"
                    type="text"
                    format="####-#######"
                    {...getFieldProps('number')}
                    inputProps={{
                      inputMode: 'numeric'
                    }}
                    error={Boolean(touched.number && errors.number)}
                    helperText={touched.number && errors.number}
                  />
                </Grid>
              </Grid>
            </ContentStyle>

            <ContentStyle>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={6}>
                  <ThemeProvider theme={FormTheme}>
                    <InputLabel>Address</InputLabel>
                  </ThemeProvider>
                  <TextField
                    fullWidth
                    autoComplete="address"
                    {...getFieldProps('address')}
                    error={Boolean(touched.address && errors.address)}
                    helperText={touched.address && errors.address}
                  />
                </Grid>
              </Grid>
            </ContentStyle>

            <ContentStyle>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={6}>
                  <ThemeProvider theme={FormTheme}>
                    <InputLabel>Bank</InputLabel>
                  </ThemeProvider>
                  <TextField
                    fullWidth
                    autoComplete="bank"
                    {...getFieldProps('bank')}
                    error={Boolean(touched.bank && errors.bank)}
                    helperText={touched.bank && errors.bank}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <ThemeProvider theme={FormTheme}>
                    <InputLabel>Account #</InputLabel>
                  </ThemeProvider>
                  <TextField
                    fullWidth
                    autoComplete="account"
                    {...getFieldProps('account')}
                    inputProps={{
                      inputMode: 'numeric'
                    }}
                    error={Boolean(touched.account && errors.account)}
                    helperText={touched.account && errors.account}
                  />
                </Grid>
              </Grid>
            </ContentStyle>

            <ContentStyle>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={6}>
                  <ThemeProvider theme={FormTheme}>
                    <InputLabel>Salary</InputLabel>
                  </ThemeProvider>
                  <TextField
                    fullWidth
                    autoComplete="salary"
                    {...getFieldProps('salary')}
                    inputProps={{
                      inputMode: 'numeric'
                    }}
                    error={Boolean(touched.salary && errors.salary)}
                    helperText={touched.salary && errors.salary}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <ThemeProvider theme={FormTheme}>
                    <InputLabel label="rank">Rank</InputLabel>
                  </ThemeProvider>
                  <Select
                    fullWidth
                    {...getFieldProps('rank')}
                    error={Boolean(touched.rank && errors.rank)}
                  >
                    {ranks.map((row) => (
                      <MenuItem key={row.id} value={row.id}>
                        {row.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </ContentStyle>

            <ContentStyle>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={6}>
                  <input
                    accept={SETTINGS.acceptImageUpload}
                    type="file"
                    id="select-image"
                    style={{ display: 'none' }}
                    onChange={(e) => setSelectedImage(e.target.files[0])}
                  />
                  <label htmlFor="select-image">
                    <Button variant="outlined" color="primary" component="span">
                      Upload Image
                    </Button>
                  </label>
                  {imageUrl && (
                    <Box mt={2} textAlign="center">
                      <Avatar alt={values.name} src={imageUrl} />
                    </Box>
                  )}
                </Grid>
              </Grid>
            </ContentStyle>

            <ContentStyle>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={6}>
                  <ThemeProvider theme={FormTheme}>
                    <InputLabel>New Password</InputLabel>
                  </ThemeProvider>
                  <TextField
                    fullWidth
                    type={showPassword ? 'text' : 'password'}
                    {...getFieldProps('password')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleShowPassword} edge="end">
                            <Icon icon={showPassword ? HidePasswordIcon : ShowPasswordIcon} />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                  />
                </Grid>


                {loggedInUser.rank >= SETTINGS.RANKS.manager && (
                  <Grid item xs={12} sm={6} md={6}>
                    <ThemeProvider theme={FormTheme}>
                      <InputLabel>Old User</InputLabel>
                    </ThemeProvider>
                    <Select
                      fullWidth
                      {...getFieldProps('retired')}
                      error={Boolean(touched.retired && errors.retired)}
                    >

                      <MenuItem value={1}>
                        Yes
                      </MenuItem>
                      <MenuItem value={0}>
                        No
                      </MenuItem>

                    </Select>
                  </Grid>

                )}
              </Grid>
            </ContentStyle>

            <Dialog buttonText={'Close'} openDialog={openDia} handleButton={handleClose}>
              {editing ? `User is updated` : `User is added`}
            </Dialog>
            <AlertSnackbar severity="warning" open={wrongFile}>
              Extenstion not allowed
            </AlertSnackbar>

            <LoadingFormButton loading={isSubmitting}>{editing ? 'Edit' : 'Add'}</LoadingFormButton>
            <ServerError open={serverError}>{serverError}</ServerError>
          </Form>
        </FormikProvider>
      }
    </>
  );
};
