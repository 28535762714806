import GenericService from './GenericService';
import qs from 'qs';

class ServiceClass extends GenericService {
    constructor() {
        super();
    }

    getAll = () => this.get('/expenses');

    getByProject = (ID) => this.get(`/expenses?project=${ID}`);

    getOne = (ID) => this.get(`/expense/${ID}`);

    updateTranscation = (id, paid) => this.put(`/payments/transactions/${id}`, { paid });

    getAccounts = () => this.get('/expense/accounts');

    add = (data, ID) => this.post('/expense', data);

    update = (data, ID) => this.patch(`/expense/${ID}`, data);

    getExpense = (pagination, date) =>
        this.get(`/expense?${qs.stringify(pagination)}&${qs.stringify(date)}`);

    getExpenseByMember = (ID, pagination, date) =>
        this.get(`/expense/member/${ID}?${qs.stringify(pagination)}&${qs.stringify(date)}`);

    remove = (ID) => this.delete(`/expense/${ID}`);

    // add = (data) => this.post('/expenses?add=true', data);
}

const expenseService = new ServiceClass();

export default expenseService;
