import GenericService from './GenericService';

class ServiceClass extends GenericService {
    constructor() {
        super();
    }

    getAll = () => this.get('/stocks');

    getByProject = (ID) => this.get('/payments?project=' + ID);

    getOne = (ID) => this.get(`/stocks/${ID}`);

    updateTranscation = (id, paid) => this.put(`/payments/transactions/${id}`, { paid });

    getTypes = () => this.get('/payments/types');

    return = (data, ID) => this.post('/stocks/return', data);

    update = (data, ID) => this.patch(`/stocks/${ID}`, data);

    add = (data) => this.post('/stocks', data);
}

const stockService = new ServiceClass();

export default stockService;
