/*
    Imports
*/
import { Container } from '@material-ui/core';
import ListPageTitle from 'src/components/misc/ListPageTitle';
import Page from 'src/components/Page';
import AddServiceForm from './forms/AddServiceForm';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import productsService from 'src/services/ProductsServiceClass';
import CenterLoading from 'src/components/misc/CenterLoading';
import servicesService from 'src/services/ServicesServiceClass';

export default ({ editing }) => {

    const navigate = useNavigate();

    const [products, setProducts] = useState();
    const [service, setService] = useState();
    const [loading, setLoading] = useState(true);

    const id = useParams().id;

    const getData = async () => {
        try {
            setProducts(await productsService.getAll());
        } catch (error) {
            if (error.response.status === 401) navigate('/401');
        }

        if (editing) {
            try {
                setService(await servicesService.getOne(id));
            } catch (error) {
                if (error.response.status === 401) navigate('/401');
                navigate('/404');
            }
        }
        setLoading(false);

    };

    useEffect(getData, []);

    ;

    let title = editing ? `Edit Service` : `Add Service`;

    return (
        <Page title={title} >
            <Container maxWidth="xl">
                <ListPageTitle>
                    {title}
                </ListPageTitle>
                {loading ?
                    <CenterLoading />
                    :
                    <AddServiceForm products={products} service={service} />
                }
            </Container>
        </Page >
    );
};
