import { Container } from '@material-ui/core';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CenterLoading from 'src/components/misc/CenterLoading';
import Page from '../components/Page';
import ProductsDetails from './tables/ProductsDetails';
import ListPageTitle from 'src/components/misc/ListPageTitle';
import { ServicesIcon } from 'src/config/icons';
import FloatingAdd from 'src/components/misc/Buttons/FloatingButton';
import userService from 'src/services/UserService';
import { SETTINGS } from 'src/config/settings';
import servicesService from 'src/services/ServicesServiceClass';
import ServicesDetails from './tables/ServicesDetails';


export default () => {

    const navigate = useNavigate();

    const loggedInUser = userService.getLoggedInUser();

    const [loading, setLoading] = useState(true);

    const [data, setData] = useState([]);

    const title = `Services Details`;

    const getData = () => {
        setLoading(true);
        servicesService
            .getAll()
            .then((res) => {
                console.log('Logs', res);
                setData(res);
            })
            .catch((error) => {
                if (error.response) if (error.response.status === 401) navigate('/401');
                navigate('/404');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(getData, []);

    const handleAdd = () => {
        navigate('./add');
    };

    const handleDelete = (ID) => {
        servicesService
            .remove(ID)
            .then(() => {
                getData();
            })
            .catch((err) => console.log(err));
    };


    return (
        <Page title={title}>
            <Container>
                {loading ? (
                    <CenterLoading />
                ) : (
                    <>
                        <ListPageTitle>{title}</ListPageTitle>
                        <ServicesDetails data={data} handleDelete={handleDelete} />
                        {loggedInUser.rank >= SETTINGS.RANKS.manager && (
                            <FloatingAdd
                                tooltip="add service"
                                onClick={handleAdd}
                                bottomSpacing={(theme) => theme.spacing(2)}
                                icon={ServicesIcon}
                            />
                        )}
                    </>
                )}
            </Container>
        </Page>
    );
};
