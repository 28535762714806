/*
    Imports
*/
import { FieldArray, Form, FormikProvider, useFormik } from 'formik';
import { Children, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useNavigate } from 'react-router-dom';

/*
    Imports:
        Material UI
*/
import {
    FormHelperText,
    Grid,
    InputLabel,
    TextField,
    Typography
} from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';

/*
    Imports:
        Our Imports
        Components and Settings
        Services
*/
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import DatePicker from '@material-ui/lab/DatePicker';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import LoadingFormButton from 'src/components/misc/Buttons/LoadingFormButton';
import Dialog from 'src/components/misc/alerts/Dialog';
import ServerError from 'src/components/misc/alerts/ServerError';
import { CustomerSchema, GenerateBillSchema } from 'src/config/form-schemas';
import { MembersIcon } from 'src/config/icons';
import billService from 'src/services/BillServiceClass';
import customersService from 'src/services/CustomersServiceClass';
import { fNumber } from 'src/utils/formatNumber';
import { ContentStyle } from '../../theme/form-pages';

/*
    Main Working
*/
export default ({ products, services }) => {
    /*
        States, Params, Navigation, Query, Variables.
    */
    let prodName = products.map((a) => a.name);
    let servName = services.map((a) => a.name);

    const [serverError, setServerError] = useState('');
    const [openDia, setOpenDia] = useState(false);
    const [openCustomerDia, setOpenCustomerDia] = useState(false);
    const [billID, setBillId] = useState(0);


    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            date: new Date(),
            paidStatus: 'Paid',
            paid: 0,
            tDiscount: '',
            items: [
                {
                    product: products[0].name,
                    price: products[0].price,
                    oprice: products[0].price,
                    quantity: null,
                    tax: null,
                    left: products[0].left,
                    discount: null,
                    total: 0
                }
            ],
            services: [
                {
                    service: services[0].name,
                    price: services[0].price,
                    tax: services[0].tax,
                    discount: services[0].discount,
                    quantity: null,
                    total: 0
                }
            ],
            grandTotal: 0
        },
        validationSchema: GenerateBillSchema,
        onSubmit: () => {
            setOpenCustomerDia(true);
        }
    });

    const {
        values,
        errors,
        touched,
        isSubmitting,
        handleSubmit,
        getFieldProps,
        setFieldValue,
        setSubmitting,
        setFieldError,
        handleBlur
    } = formik;

    const qrFormik = useFormik({
        initialValues: {
            qrJson: ''
        },
        onSubmit: (result, { setFieldValue: setFieldValueQr }) => {
            try {
                let qr = JSON.parse(result.qrJson);
                if (Array.isArray(qr)) {

                    let new_services = [...values.services];

                    for (let q of qr) {

                        if (q.type == 'service') {
                            let s = services.find((a) => a.id == q.id);

                            //Check if service is already added
                            let alreadyAdded = false;
                            for (let i of new_services) {
                                if (i.service == s.name && s.price == i.price && s.tax == i.tax && s.discount == i.discount) {
                                    alreadyAdded = true;

                                    if (!i.quantity)
                                        i.quantity = 0;
                                    i.quantity += (q.quantity ?? 1);
                                    break;
                                }
                            }
                            if (alreadyAdded) continue;

                            new_services.push({
                                service: s.name,
                                price: s.price,
                                quantity: (q.quantity ?? 1),
                                tax: s.tax ?? 0,
                                discount: s.discount ?? 0,
                                total: s.price * (q.quantity ?? 1)
                            });

                        }
                    }
                    setFieldValue('services', new_services);
                }
                setFieldValueQr('qrJson', '')
            } catch (error) {
                console.log('JSON Scan Error', error);
                setFieldValueQr('qrJson', '')
            }
        }
    });

    const {
        getFieldProps: getQrFieldProps,
    } = qrFormik;

    const customerFormik = useFormik({
        initialValues: {
            name: '',
            number: '',
            address: '',
            email: '',
        },
        validationSchema: CustomerSchema
    });

    const {
        getFieldProps: getCustomerFieldProps,
        values: customerValues,
        setFieldValue: setFieldValueCustomer,
        errors: customerErrors,
        touched: customerTouched,
    } = customerFormik;



    const addData = () => {
        setSubmitting(true);
        const customer = {
            name: customerValues.name,
            address: customerValues.address,
            number: customerValues.number,
            email: customerValues.email
        };
        console.log('Customer', customer.number.length)
        if (customer.number.length < 5) {
            customer.number = "0300-0000000"
        }
        let valuess = { ...values };
        valuess = { ...valuess, ...{ customer } };

        const newitems = valuess.items.filter((item) => item.quantity && item.quantity > 0).map((item) => {
            // item.price = item.oprice - item.discount;
            return { ...item, product: products.find((prod) => prod.name == item.product).id };
        });

        valuess.items = newitems;
        valuess.services = valuess.services.filter((item) => item.quantity && item.quantity > 0).map((item) => {
            return { ...item, serv: services.find((serv) => serv.name == item.service).id };
        });

        valuess.customer.number = valuess.customer.number.replace('-', '');

        let { paidStatus, tDiscount, ...rest } = valuess;
        rest.items.forEach((o) => {
            o.price = o.price - o.discount;
            delete o.left;
            delete o.total;
            delete o.discount;
        });

        billService
            .add(rest)
            .then((response) => {
                setBillId(response.id);
                handlePrintBill(response.id);
                window.location.reload();
            })
            .catch((err) => {
                console.log('Error', err, err.response);
                let has = false;
                if (err.response) {
                    for (const key in values) {
                        if (Object.hasOwnProperty.call(values, key)) {
                            if (err.response.data.message.includes(key)) {
                                setFieldError(key, 'Invalid or Already Added');
                                has = true;
                            }
                        }
                    }
                    if (!has) {
                        setServerError(err.response.data.message);
                    }
                }
                console.log('Error', err, err.response);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    };

    const handleClose = () => {
        setOpenDia(false);
        navigate(RoutePayments);
    };

    const handleCloseCustomerDialog = () => {
        setOpenCustomerDia(false);
        addData();
    };

    const handlePrintE_Bill = (id) => {
        openInNewTab(`/bills/${id ?? billID}/print`);
    };
    const handlePrintBill = (id) => {
        openInNewTab(`/bills/${id ?? billID}/print-thermal`);
    };

    const handleProductsChange = () => {

        let services_total = values.services.reduce((total, item) => (total += item.price * item.quantity), 0);

        if (services_total > 0) {
            return;
        }

        const lastIndex = values.items.length - 1;

        if (values.items[lastIndex]?.quantity) {
            const u = [...values.items];
            u.push({
                product: products[0].name,
                price: products[0].price,
                oprice: products[0].price,
                quantity: null,
                tax: null,
                left: products[0].left,
                discount: null,
                total: 0
            });
            setFieldValue('items', u);
        }

        setServerError('');
    };

    const handleServiceChange = () => {
        // NOTE: Improve this code

        const lastIndex = values.services.length - 1;


        if (values.services[lastIndex].quantity) {
            const u = [...values.services];
            u.push({
                service: services[0].name,
                price: services[0].price,
                tax: services[0].tax,
                discount: services[0].discount,
                quantity: null,
                total: 0
            });
            setFieldValue('services', u);
        }


        let services_total = values.services.reduce((total, item) => (total += item.price * item.quantity), 0);

        if (services_total > 0) {

            let new_items = [];

            for (const s of values.services) {

                let service = services.find((a) => a.name == s.service);
                if (!service) continue;

                let discount = s.discount / service.items.length;

                for (let i of service.items) {
                    if (s.quantity < 1) continue;
                    console.log('Item', i);

                    let prod = products.find((a) => a.id == i.product);

                    console.log('Product', prod);
                    new_items.push({
                        product: prod.name,
                        price: i.price,
                        oprice: i.price,
                        quantity: i.quantity * s.quantity,
                        tax: i.tax,
                        left: prod.left,
                        discount: discount / i.quantity,
                        total: i.total * s.quantity,
                        disbaled_service: true
                    })
                }
            }
            setFieldValue('items', new_items);
        }


        setServerError('');
    };

    const handleOldCustomer = () => {
        if (!customerValues.number) return;
        if (customerValues.number.replace(' ', '').length > 11) {
            customersService
                .getCustomer(customerValues.number.replace('-', ''))
                .then((_customer) => {
                    prefillData(_customer);
                })
                .catch(() => {
                    console.log('Customer Not Found');
                });
        }
    };

    function prefillData(customer) {
        setFieldValueCustomer('name', customer.name);
        setFieldValueCustomer('email', customer.email);
        setFieldValueCustomer('address', customer.address);
    }

    function getCurrentTotal() {

        let services_total = values.services.reduce((total, item) => (total += item.price * item.quantity), 0);
        let items_total = values.items.reduce((total, item) => (total += item.price * item.quantity), 0);

        return services_total > items_total ? services_total : items_total;
    }

    function getTotalDiscount() {
        return values.items.reduce((total, item) => (total += +item.quantity * +item.discount), 0);
    }

    function getGrandTotal() {

        let services_total = values.services.reduce((total, item) => (total += item.total), 0);
        let items_total = values.items.reduce((total, item) => (total += item.total), 0);

        return services_total > items_total ? services_total : items_total;
    }

    const handleDiscountDistribustion = () => {
        if (values.tDiscount.length > 0 && values.tDiscount != 0 && values.tDiscount != NaN) {
            const num = +values.tDiscount.replace('%', '');
            const total = getCurrentTotal();

            let percent = 0;

            if (values.tDiscount.includes('%')) percent = num / 100;
            else if (total != 0) percent = num / total;

            for (const item of values.items) {
                item.discount = 0;
                const discountPerItem = +item.price * percent;
                if (discountPerItem > 1) item.discount = Math.floor(discountPerItem);
                else item.discount = discountPerItem.toFixed(3);
            }
        } else values.tDiscount = 0;
    };


    /*
        Use Effect Hooks.
    */

    useEffect(handleProductsChange, [values.items]);

    useEffect(handleServiceChange, [values.services]);

    useEffect(handleOldCustomer, [customerValues.number]);

    useEffect(handleDiscountDistribustion, [values.tDiscount, values.items]);


    /*
        Main Design
    */

    values.grandTotal = getGrandTotal();
    if (values.paidStatus == 'Paid') values.paid = getGrandTotal();

    return (

        <>
            <FormikProvider value={qrFormik}>
                <Form autoComplete='off' noValidate >
                    <ContentStyle style={{
                        paddingTop: '0px',
                        paddingBottom: '5px',
                    }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={6}>

                                <InputLabel label='QR'>QR</InputLabel>
                                <TextField
                                    fullWidth
                                    autoFocus={true}
                                    {...getQrFieldProps('qrJson')}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>

                                <Typography variant='h4'>
                                    Total Amount
                                </Typography>
                                <Typography variant='h4'>
                                    Rs. {fNumber(Math.ceil(getGrandTotal()))}
                                </Typography>
                            </Grid>


                        </Grid>
                    </ContentStyle>
                </Form>
            </FormikProvider >

            <Dialog openDialog={openCustomerDia} buttonText={"Bill Customer"} handleButton={handleCloseCustomerDialog} disabledButton1={customerErrors.address || customerErrors.email || customerErrors.name || customerErrors.number} width={"80%"} icon={MembersIcon}>
                <FormikProvider value={customerFormik}>
                    <Form autoComplete='off' noValidate >

                        <ContentStyle>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6} md={6}>
                                    <InputLabel label="Number">Contact Number</InputLabel>

                                    <NumberFormat
                                        fullWidth
                                        customInput={TextField}
                                        autoComplete="number"
                                        type="text"
                                        format="####-#######"
                                        onBlur={handleBlur}
                                        {...getCustomerFieldProps('number')}
                                        inputProps={{
                                            inputMode: 'numeric'
                                        }}
                                        error={Boolean(customerTouched.number && customerErrors.number)}
                                        helperText={customerTouched.number && customerErrors.number}
                                    />
                                    {/* <FormHelperText error={true}>{errors.length > 0 ? errors?.number.length > 0 ? `${errors.number}` : '' : ''}</FormHelperText> */}
                                </Grid>

                                <Grid item xs={12} sm={6} md={6}>

                                    <InputLabel>Name</InputLabel>

                                    <TextField
                                        fullWidth
                                        autoComplete="name"
                                        {...getCustomerFieldProps('name')}
                                        disabled={Boolean(!customerTouched.number || customerErrors.number)}
                                        error={Boolean(customerTouched.name && customerErrors.name)}
                                        helperText={customerTouched.name && customerErrors.name}
                                    />
                                </Grid>
                            </Grid>
                        </ContentStyle>

                        <ContentStyle>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6} md={6}>

                                    <InputLabel>Email</InputLabel>

                                    <TextField
                                        fullWidth
                                        autoComplete="email"
                                        {...getCustomerFieldProps('email')}
                                        inputProps={{
                                            inputMode: 'email'
                                        }}
                                        disabled={Boolean(!customerTouched.number || customerErrors.number)}
                                        error={Boolean(customerTouched.email && customerErrors.email)}
                                        helperText={customerTouched.email && customerErrors.email}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} md={6}>

                                    <InputLabel label="Address">Address</InputLabel>

                                    <TextField
                                        fullWidth
                                        autoComplete="address"
                                        {...getCustomerFieldProps('address')}
                                        disabled={Boolean(!customerTouched.number || customerErrors.number)}
                                        error={Boolean(customerTouched.address && customerErrors.address)}
                                        helperText={customerTouched.address && customerErrors.address}
                                    />
                                </Grid>
                            </Grid>
                        </ContentStyle>
                    </Form>
                </FormikProvider>
            </Dialog>


            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Typography variant="h6" gutterBottom>
                        Bill Details
                    </Typography>
                    <ContentStyle style={{
                        paddingTop: '0px',
                        paddingBottom: '0px',
                    }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={6}>

                                <InputLabel label="date">Date</InputLabel>

                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        value={values.date}
                                        onChange={(newValue) => {
                                            setFieldValue('date', newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                fullWidth
                                                {...params}
                                                {...getFieldProps('date')}
                                                error={Boolean(touched.date && errors.date)}
                                                helperText={touched.date && errors.date}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                    </ContentStyle>

                    <FieldArray name="items">
                        <>
                            {Children.toArray(
                                values.items.map((product, index) => (
                                    <ContentStyle style={{
                                        paddingTop: '0px',
                                        paddingBottom: '0px',
                                    }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={4} md={4}>

                                                <InputLabel>Product</InputLabel>

                                                <Autocomplete
                                                    disabled={!!product.disbaled_service}
                                                    disableClearable={true}
                                                    options={prodName}
                                                    value={product.product}
                                                    onChange={(event, newValue) => {
                                                        const items = values.items;
                                                        items[index].product = newValue;

                                                        let p = products.find(
                                                            (a) => a.name == newValue
                                                        )

                                                        let price = p.sale_p;
                                                        if (p.type == 'Percentage')
                                                            price = p.price;

                                                        items[index].price = price;
                                                        items[index].oprice = price;
                                                        items[index].left = p.left;
                                                        setFieldValue('items', items);
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            fullWidth
                                                            {...getFieldProps(`items.${index}.product`)}
                                                        />
                                                    )}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={2} md={2}>

                                                <InputLabel>Price</InputLabel>

                                                <NumberFormat
                                                    disabled={!!product.disbaled_service}
                                                    name={`items.${index}.price`}
                                                    fullWidth
                                                    customInput={TextField}
                                                    type="text"
                                                    allowEmptyFormatting="true"
                                                    inputProps={{
                                                        inputMode: 'numeric'
                                                    }}
                                                    {...getFieldProps(`items.${index}.price`)}
                                                />
                                                <FormHelperText>
                                                    Orignal price: {values.items[index].oprice}
                                                </FormHelperText>
                                                <FormHelperText error={true}>
                                                    {errors?.items?.length > 0 &&
                                                        errors.items[index]?.price}
                                                </FormHelperText>
                                            </Grid>

                                            <Grid item xs={12} sm={2} md={2}>

                                                <InputLabel>Quantity</InputLabel>

                                                <NumberFormat
                                                    disabled={!!product.disbaled_service}
                                                    fullWidth
                                                    customInput={TextField}
                                                    type="text"
                                                    allowEmptyFormatting="true"
                                                    inputProps={{
                                                        inputMode: 'numeric'
                                                    }}
                                                    {...getFieldProps(`items.${index}.quantity`)}
                                                />
                                                <FormHelperText>
                                                    In Stock: {fNumber(values.items[index].left)}
                                                </FormHelperText>
                                                <FormHelperText error={true}>
                                                    {errors?.items?.length > 0 &&
                                                        errors.items[index]?.quantity}
                                                </FormHelperText>
                                            </Grid>

                                            <Grid item xs={12} sm={1} md={1}>

                                                <InputLabel>Discount</InputLabel>

                                                <NumberFormat

                                                    fullWidth
                                                    customInput={TextField}
                                                    type="text"
                                                    allowEmptyFormatting="true"
                                                    inputProps={{
                                                        inputMode: 'numeric'
                                                    }}
                                                    disabled={!!values.tDiscount || !!product.disbaled_service}
                                                    {...getFieldProps(`items.${index}.discount`)}
                                                />
                                                <FormHelperText>
                                                    Total Discount:{' '}
                                                    {Math.floor(
                                                        (
                                                            values.items[index].discount *
                                                            values.items[index].quantity
                                                        ).toFixed(3)
                                                    )}
                                                </FormHelperText>
                                                <FormHelperText error={true}>
                                                    {errors?.items?.length > 0 &&
                                                        errors.items[index]?.discount}
                                                </FormHelperText>
                                            </Grid>

                                            <Grid item xs={12} sm={1} md={1}>

                                                <InputLabel>Tax</InputLabel>

                                                <NumberFormat
                                                    disabled={!!product.disbaled_service}
                                                    fullWidth
                                                    customInput={TextField}
                                                    type="text"
                                                    allowEmptyFormatting="true"
                                                    inputProps={{
                                                        inputMode: 'numeric'
                                                    }}
                                                    {...getFieldProps(`items.${index}.tax`)}
                                                />
                                                <FormHelperText>
                                                    Total Tax:{' '}
                                                    {values.items[index].tax *
                                                        values.items[index].quantity}
                                                </FormHelperText>
                                                <FormHelperText error={true}>
                                                    {errors?.items?.length > 0 &&
                                                        errors.items[index]?.tax}
                                                </FormHelperText>
                                            </Grid>

                                            <Grid item xs={12} sm={2} md={2}>

                                                <InputLabel>Total Rs. </InputLabel>

                                                <TextField
                                                    value={fNumber(
                                                        (values.items[index].total = Math.ceil(
                                                            +values.items[index].quantity *
                                                            (+values.items[index].price +
                                                                +values.items[index].tax -
                                                                +values.items[index].discount)
                                                        ))
                                                    )}
                                                    disabled={true}
                                                />
                                            </Grid>
                                        </Grid>
                                    </ContentStyle>
                                ))
                            )}
                        </>
                    </FieldArray>

                    <FieldArray name="services">
                        <>
                            {Children.toArray(
                                values.services.map((serv, index) => (
                                    <ContentStyle style={{
                                        paddingTop: '0px',
                                        paddingBottom: '0px',
                                    }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={4} md={4}>

                                                <InputLabel>Services</InputLabel>

                                                <Autocomplete
                                                    disableClearable={true}
                                                    options={servName}
                                                    value={serv.service}
                                                    onChange={(event, newValue) => {
                                                        const items = values.services;
                                                        items[index].service = newValue;

                                                        let p = services.find(
                                                            (a) => a.name == newValue
                                                        )
                                                        console.log(p);

                                                        items[index].price = p.price;
                                                        items[index].tax = p.tax;

                                                        setFieldValue('services', items);
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            fullWidth
                                                            {...getFieldProps(`services.${index}.service`)}
                                                        />
                                                    )}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={2} md={2}>

                                                <InputLabel>Price</InputLabel>

                                                <NumberFormat
                                                    name={`services.${index}.price`}
                                                    fullWidth
                                                    customInput={TextField}
                                                    type="text"
                                                    allowEmptyFormatting="true"
                                                    inputProps={{
                                                        inputMode: 'numeric'
                                                    }}
                                                    disabled={true}
                                                    {...getFieldProps(`services.${index}.price`)}
                                                />

                                                <FormHelperText error={true}>
                                                    {errors?.services?.length > 0 &&
                                                        errors.services[index]?.price}
                                                </FormHelperText>
                                            </Grid>

                                            <Grid item xs={12} sm={1} md={1}>

                                                <InputLabel>Quantity</InputLabel>

                                                <NumberFormat
                                                    fullWidth
                                                    customInput={TextField}
                                                    type="text"
                                                    allowEmptyFormatting="true"
                                                    inputProps={{
                                                        inputMode: 'numeric'
                                                    }}
                                                    {...getFieldProps(`services.${index}.quantity`)}
                                                />

                                                <FormHelperText error={true}>
                                                    {errors?.services?.length > 0 &&
                                                        errors.services[index]?.quantity}
                                                </FormHelperText>
                                            </Grid>

                                            <Grid item xs={12} sm={2} md={2}>
                                                <InputLabel>Discount</InputLabel>
                                                <NumberFormat
                                                    fullWidth
                                                    customInput={TextField}
                                                    type="text"
                                                    allowEmptyFormatting="true"
                                                    inputProps={{
                                                        inputMode: 'numeric'
                                                    }}
                                                    {...getFieldProps(`services.${index}.discount`)}
                                                />
                                                <FormHelperText error={true}>
                                                    {errors?.services?.length > 0 &&
                                                        errors.services[index]?.discount}
                                                </FormHelperText>
                                            </Grid>

                                            <Grid item xs={12} sm={1} md={1}>
                                                <InputLabel>Tax</InputLabel>
                                                <NumberFormat
                                                    fullWidth
                                                    customInput={TextField}
                                                    type="text"
                                                    allowEmptyFormatting="true"
                                                    inputProps={{
                                                        inputMode: 'numeric'
                                                    }}
                                                    {...getFieldProps(`services.${index}.tax`)}
                                                    disabled={true}
                                                />
                                                <FormHelperText error={true}>
                                                    {errors?.services?.length > 0 &&
                                                        errors.services[index]?.tax}
                                                </FormHelperText>
                                            </Grid>


                                            <Grid item xs={12} sm={2} md={2}>

                                                <InputLabel>Total Rs. </InputLabel>

                                                <TextField
                                                    value={fNumber(
                                                        (values.services[index].total = Math.ceil(
                                                            +values.services[index].quantity *
                                                            (+values.services[index].price +
                                                                +values.services[index].tax -
                                                                +values.services[index].discount)
                                                        ))
                                                    )}
                                                    disabled={true}
                                                />
                                            </Grid>
                                        </Grid>
                                    </ContentStyle>
                                ))
                            )}
                        </>
                    </FieldArray>
                    {/* <ContentStyle>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={6}>
              
                                <InputLabel label="totalDiscount">
                                    Total Discount {fNumber(Math.floor(getTotalDiscount()))}
                                </InputLabel>
                        
                            <TextField
                                fullWidth
                                {...getFieldProps('tDiscount')}
                                inputProps={{
                                    inputMode: 'numeric'
                                }}
                                error={Boolean(touched.tDiscount && errors.tDiscount)}
                                helperText={touched.tDiscount && errors.tDiscount}
                            />
                        </Grid>
                    </Grid>
                </ContentStyle> */}
                    <ContentStyle>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={6}>

                                <InputLabel label="grandTotal">
                                    Total Amount {fNumber(Math.ceil(getGrandTotal()))}
                                </InputLabel>

                            </Grid>
                        </Grid>
                    </ContentStyle>

                    {/* <ContentStyle>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={6}>
                        
                                <InputLabel label="paidStatus">Status</InputLabel>
                            
                            <Select
                                fullWidth
                                {...getFieldProps('paidStatus')}
                                error={Boolean(touched.paidStatus && errors.paidStatus)}
                            >
                                <MenuItem key="Paid" value="Paid">
                                    Paid
                                </MenuItem>
                                <MenuItem key="Due" value="Due">
                                    Due
                                </MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            
                                <InputLabel>Amount Paid</InputLabel>
             
                            <TextField
                                fullWidth
                                disabled={values.paidStatus == 'Paid'}
                                autoComplete="paid"
                                {...getFieldProps('paid')}
                                inputProps={{
                                    inputMode: 'numeric'
                                }}
                                error={Boolean(touched.paid && errors.paid)}
                                helperText={touched.paid && errors.paid}
                            />
                        </Grid>
                    </Grid>
                </ContentStyle> */}

                    <Dialog
                        buttonText={'Close'}
                        buttonText2={'eBill'}
                        buttonText3={'Bill'}
                        openDialog={openDia}
                        handleButton={handleClose}
                        handleButton2={handlePrintE_Bill}
                        handleButton3={handlePrintBill}
                    >
                        {`Bill is added`}
                    </Dialog>

                    <LoadingFormButton disabled={!!serverError} loading={isSubmitting}>
                        {'Add'}
                    </LoadingFormButton>
                    <ServerError open={!!serverError}>{serverError}</ServerError>
                </Form>
            </FormikProvider>
        </>
    );
};
