/*
  Imports
*/
import { Container } from '@material-ui/core';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import stocksService from 'src/services/StocksServiceClass';
import CenterLoading from 'src/components/misc/CenterLoading';
import Page from '../components/Page';
import SingleStockDetails from './body/SingleStockDetails';
import StockSoldDetails from './tables/StockSoldDetails';
import ListPageTitle from 'src/components/misc/ListPageTitle';
import ReturnStock from './tables/ReturnStock';
import { ReturnIcon } from 'src/config/icons';
import FloatingAdd from 'src/components/misc/Buttons/FloatingButton';

/*
  Main Working
*/
export default () => {
    /*
    States, Params, Navigation, Query, Variables.
  */
    const navigate = useNavigate();

    const [pageLoading, setPageLoading] = useState(true);

    const [loading, setLoading] = useState(true);

    const [returnTableLoading, setReturnTableLoading] = useState(true);

    const [data, setData] = useState([]);

    const [returnData, setReturnData] = useState([]);

    const { id } = useParams();

    const title = `Stock Details`;

    /*
      Handlers, Functions
    */
    const getData = () => {
        setLoading(true);
        stocksService
            .getOne(id)
            .then((res) => {
                console.log('Stock', res);
                setData(res);
            })
            .catch((error) => {
                if (error.response) if (error.response.status === 401) navigate('/401');
                navigate('/404');
            })
            .finally(() => {
                setLoading(false);
                setPageLoading(false);
            });
    };

    const getReturnStockData = () => {
        setReturnTableLoading(true);
        stocksService
            .getReturnStock(id)
            .then((res) => {
                console.log('Return Stock', res);
                setReturnData(res);
            })
            .catch((error) => {
                if (error.response) if (error.response.status === 401) navigate('/401');
                navigate('/404');
            })
            .finally(() => {
                setReturnTableLoading(false);
            });
    };

    /*
      Use Effect Hooks.
    */
    useEffect(getData, []);

    useEffect(getReturnStockData, []);

    const handleAdd = () => {
        navigate('./return');
    };

    const handleDeleteReturnStock = (ID) => {
        stocksService
            .removeReturnStock(ID)
            .then(() => {
                getReturnStockData();
            })
            .catch((err) => console.log(err));
    };

    /*
    Use Effect Hooks.
  */
    /*
    Main Design
  */
    return (
        <Page title={title}>
            <Container>
                {pageLoading ? (
                    <CenterLoading />
                ) : (
                    <>
                        <ListPageTitle>{title}</ListPageTitle>
                        <SingleStockDetails data={data} />
                        <br />
                        <br />
                        <ListPageTitle>Stock Sold Details</ListPageTitle>
                        <StockSoldDetails data={data.sold_details || []} loading={loading} />
                        <br />
                        <br />
                        <ListPageTitle>Stock Return Details</ListPageTitle>
                        <ReturnStock data={returnData} loading={returnTableLoading} handleDelete={handleDeleteReturnStock} />
                        <FloatingAdd
                            tooltip="add return stock"
                            onClick={handleAdd}
                            bottomSpacing={(theme) => theme.spacing(2)}
                            icon={ReturnIcon}
                        />
                    </>
                )}
            </Container>
        </Page>
    );
};
