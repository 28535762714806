export const RouteLandingPage = '/';
export const RouteForgetPass = '/forget';
export const RouteResetPass = '/reset';
export const RouteLogin = '/login';
export const RouteDashboard = '/dashboard';
export const RouteClosedProjects = '/cprojects';
export const RoutePayments = '/payments';
export const RouteBills = '/bills';
export const RouteExpense = '/expense';
export const RouteTransfer = '/Transfer';
export const RouteUsers = '/users';
export const RouteRSO = '/rso';
export const RouteCustomers = '/customers';
export const RoutePolicy = '/policy';
export const RouteLogs = '/logs';
export const RouteStocks = '/stocks';
export const RouteProducts = '/products';
export const RouteServices = '/services';
export const RouteGeneralJournal = '/generaljournal';
export const RouteAccounts = '/accounts';
export const RouteProfitLoss = '/profitloss';
export const Route401 = '/401';
