/*
    Imports
*/
import { Container } from '@material-ui/core';
import { useEffect, useState } from 'react';
import Page from 'src/components/Page';
import otherService from 'src/services/OtherService';
import userService from 'src/services/UserService';
import Form from './forms/SettingsForm';

/*
    Main Working
*/
export default () => {
    const [loggedInUser, setLoggedInUser] = useState(userService.getLoggedInUser());


    return (
        <Page title='Settings'>
            <Container maxWidth="xl">
                {loggedInUser &&
                    <Form loggedInUser={loggedInUser} />
                }
            </Container>
        </Page>
    );
};

