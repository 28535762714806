import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// material
import { Box, Drawer, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
// components
import { MHidden } from '../../components/@material-extend';
import Logo from '../../components/Logo';
import NavSection from '../../components/NavSection';
import Scrollbar from '../../components/Scrollbar';

import userService from 'src/services/UserService';
import { SETTINGS } from '../../config/settings';
import { sidebarConfig } from '../../config/SidebarConfig';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('lg')]: {
        flexShrink: 0,
        width: DRAWER_WIDTH
    }
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
    isOpenSidebar: PropTypes.bool,
    onCloseSidebar: PropTypes.func
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
    const { pathname } = useLocation();
    const [user, setUser] = useState();
    const loggedInUser = userService.getLoggedInUser();

    const getData = () => {
        setUser(userService.getLoggedInUser());
        console.log('Dashboard user', userService.getLoggedInUser());
    };

    useEffect(getData, []);

    useEffect(() => {
        if (isOpenSidebar) {
            onCloseSidebar();
        }
    }, [pathname]);

    const rank = () => {
        return loggedInUser.rank >= SETTINGS.RANKS.manager;
    };

    const renderContent = (
        <Scrollbar
            sx={{
                height: '100%',
                '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' }
            }}
        >
            <Box sx={{ px: 2.5, py: 6, pb: 10 }}>
                <Logo />
            </Box>

            <NavSection
                navConfig={sidebarConfig.top.filter(
                    (value) => !value.rank || loggedInUser?.rank >= value.rank
                )}
            />

            <Box sx={{ flexGrow: 1 }} />

            <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
                <NavSection navConfig={sidebarConfig.bottom} />
            </Box>
        </Scrollbar>
    );

    return (
        <RootStyle>
            <MHidden width="lgUp">
                <Drawer
                    open={isOpenSidebar}
                    onClose={onCloseSidebar}
                    PaperProps={{
                        sx: { width: DRAWER_WIDTH }
                    }}
                >
                    {renderContent}
                </Drawer>
            </MHidden>

            <MHidden width="lgDown">
                <Drawer
                    open
                    variant="persistent"
                    PaperProps={{
                        sx: {
                            width: DRAWER_WIDTH,
                            bgcolor: 'background.default'
                        }
                    }}
                >
                    {renderContent}
                </Drawer>
            </MHidden>
        </RootStyle>
    );
}
