/*
  Imports
*/
import { Grid, Typography } from '@material-ui/core';
import { fNumber } from 'src/utils/formatNumber';
import { getDateString } from 'src/utils/dateTime';
import Label from 'src/components/misc/Label';

/*
  Main Working
*/
export default ({ data }) => {
    /*
    States, Params, Navigation, Query, Variables.
  */

    const {
        number,
        name,
        email,
        address,
        total,
        return_total,
        receivable,
        received,
        last_bill_on,
        last_return_on,
        paid,
        balance,
    } = data;

    /*
    Main Design
  */
    return (
        <>
            <Grid container spacing={0} style={{ paddingBottom: 10, paddingTop: 20 }}>
                <Grid item xs={12} sm={2} md={2}>
                    <Typography fontWeight="bold">Last Bill On: </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <Typography>{getDateString(last_bill_on)}</Typography>
                </Grid>
                <Grid item xs={12} sm={2} md={2}>
                    <Typography fontWeight="bold">Last Return On: </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <Typography>{getDateString(last_return_on)}</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={0} style={{ paddingBottom: 10, paddingTop: 10 }}>
                <Grid item xs={12} sm={2} md={2}>
                    <Typography fontWeight="bold">Name: </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <Typography>{name}</Typography>
                </Grid>
                <Grid item xs={12} sm={2} md={2}>
                    <Typography fontWeight="bold">Number: </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <Typography>{number}</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={0} style={{ paddingBottom: 10, paddingTop: 10 }}>
                <Grid item xs={12} sm={2} md={2}>
                    <Typography fontWeight="bold">E-mail: </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <Typography>{email}</Typography>
                </Grid>
                <Grid item xs={12} sm={2} md={2}>
                    <Typography fontWeight="bold">Address: </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <Typography>{address}</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={0} style={{ paddingBottom: 10, paddingTop: 10 }}>
                <Grid item xs={12} sm={2} md={2}>
                    <Typography fontWeight="bold">Total: </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <Typography>Rs. {fNumber(total)}</Typography>
                </Grid>
                <Grid item xs={12} sm={2} md={2}>
                    <Typography fontWeight="bold">Return Total: </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <Typography>Rs. {fNumber(return_total)}</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={0} style={{ paddingBottom: 10, paddingTop: 10 }}>
                <Grid item xs={12} sm={2} md={2}>
                    <Typography fontWeight="bold">Received: </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <Typography>Rs. {fNumber(received)}</Typography>
                </Grid>
                <Grid item xs={12} sm={2} md={2}>
                    <Typography fontWeight="bold">Receivable: </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <Typography>Rs. {fNumber(receivable)}</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={0} style={{ paddingBottom: 10, paddingTop: 10 }}>
                <Grid item xs={12} sm={2} md={2}>
                    <Typography fontWeight="bold">Paid: </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <Typography>Rs. {fNumber(paid)}</Typography>
                </Grid>
                <Grid item xs={12} sm={2} md={2}>
                    <Typography fontWeight="bold">Balance: </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <Label
                        variant="ghost"
                        color={(balance > 0 && 'success') || (balance < 0 && 'error') || 'info'}
                    >
                        Rs. {fNumber(Math.abs(balance))}
                    </Label>
                </Grid>
            </Grid>
        </>
    );
};
