import { Icon } from '@iconify/react';
import {
    BillsIcon,
    DashboardIcon,
    ExpensesIcon,
    GeneralJournalIcon,
    MembersIcon,
    PaymentsIcon,
    ProductsIcon,
    RSOIcon,
    StockIcon,
    UsersIcon,
    WorkHistoryIcon,
    AccountsIcon,
    TransferIcon,
    ProfitLossIcon,
    ServicesIcon,
} from 'src/config/icons';
import { SETTINGS } from './settings';

import {
    RouteBills,
    RouteCustomers,
    RouteDashboard,
    RouteExpense,
    RouteGeneralJournal,
    RouteLogs,
    RoutePayments,
    RouteProducts,
    RouteRSO,
    RouteStocks,
    RouteUsers,
    RouteAccounts,
    RouteTransfer,
    RouteProfitLoss,
    RouteServices,
} from 'src/config/routes';

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

export const sidebarConfig = {
    top: [
        {
            title: 'dashboard',
            path: RouteDashboard,
            icon: getIcon(DashboardIcon),
        },
        {
            title: 'members',
            path: RouteUsers,
            icon: getIcon(UsersIcon),
        },
        {
            title: 'customers',
            path: RouteCustomers,
            icon: getIcon(MembersIcon),
            rank: SETTINGS.RANKS.manager,
        },
        // {
        //     title: 'RSO',
        //     path: RouteRSO,
        //     icon: getIcon(RSOIcon),
        //     rank: SETTINGS.RANKS.manager,
        // },
        {
            title: 'products',
            path: RouteProducts,
            icon: getIcon(ProductsIcon),
        },
        {
            title: 'services',
            path: RouteServices,
            icon: getIcon(ServicesIcon),
        },
        {
            title: 'stocks',
            path: RouteStocks,
            icon: getIcon(StockIcon),
        },
        {
            title: 'expenses',
            path: RouteExpense,
            icon: getIcon(ExpensesIcon),
        },
        {
            title: 'transfers',
            path: RouteTransfer,
            icon: getIcon(TransferIcon),
            rank: SETTINGS.RANKS.manager,
        },
        {
            title: 'bills',
            path: RouteBills,
            icon: getIcon(BillsIcon),
        },
        {
            title: 'payments',
            path: RoutePayments,
            icon: getIcon(PaymentsIcon),
            rank: SETTINGS.RANKS.manager,
        },
        {
            title: 'general journal',
            path: RouteGeneralJournal,
            icon: getIcon(GeneralJournalIcon),
            rank: SETTINGS.RANKS.manager,
        },
        {
            title: 'accounts',
            path: RouteAccounts,
            icon: getIcon(AccountsIcon),
            rank: SETTINGS.RANKS.manager,
        },
        {
            title: 'profit loss',
            path: RouteProfitLoss,
            icon: getIcon(ProfitLossIcon),
            rank: SETTINGS.RANKS.manager,
        },
    ],

    bottom: [
        {
            title: 'logs',
            path: RouteLogs,
            icon: getIcon(WorkHistoryIcon),
        },
    ],
};
