import GenericService from './GenericService';
import qs from 'qs';

class ServiceClass extends GenericService {
    constructor() {
        super();
    }

    getByRSO = (ID, pagination, date) =>
        this.get(`/payments/rso/${ID}?${qs.stringify(pagination)}&${qs.stringify(date)}`);

    getPayments = (pagination, date) =>
        this.get(`/payments?${qs.stringify(pagination)}&${qs.stringify(date)}`);

    add = (data, ID) => this.post('/payments', data);

    update = (data, ID) => this.patch(`/payments/${ID}`, data);

    getOne = (ID) => this.get(`/payments/${ID}`);

    remove = (ID) => this.delete(`/payments/${ID}`);

    // customer payments

    addC = (data, number, ID) => this.post(`/customers/${number}/payments`, data);

    getOneC = (number, ID) => this.get(`/customers/${number}/payments/${ID}`);

    updateC = (data, number, ID) => this.patch(`/customers/${number}/payments/${ID}`, data);
}

const paymentsService = new ServiceClass();

export default paymentsService;
