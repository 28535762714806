/*
    Imports
*/
import { Container } from '@material-ui/core';
import ListPageTitle from 'src/components/misc/ListPageTitle';
import Page from 'src/components/Page';
import AddProductForm from './forms/AddProductForm';

/*
    Main Working
*/
export default ({ editing }) => {

    /*
      Main Design
    */
    return (
        <Page title={editing ? `Edit Product` : `Add Product`} >
            <Container maxWidth="xl">
                <ListPageTitle>
                    {editing ? `Editing Product` : "Adding Product"}
                </ListPageTitle>
                <AddProductForm editing={editing} />
            </Container>
        </Page >
    );
};
