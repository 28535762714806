/*
    Imports
*/
import { Container } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CenterLoading from 'src/components/misc/CenterLoading';
import ListPageTitle from 'src/components/misc/ListPageTitle';
import Page from 'src/components/Page';
import expenseService from 'src/services/ExpenseServiceClass';
import productsService from 'src/services/ProductsServiceClass';
import rsoService from 'src/services/RsoServiceClass';
import userService from 'src/services/UserService';
import AddPaymentForm from './forms/AddPaymentForm';

/*
    Main Working
*/
export default ({ editing }) => {
    const navigate = useNavigate();

    const [rsos, setRsos] = useState();
    const [products, setProducts] = useState();
    const [account, setAccount] = useState();

    const getProducts = () => {
        productsService
            .getAll()
            .then((data) => {
                setProducts(data);
            })
            .catch((error) => {
                if (error.response) if (error.response.status === 401) navigate('/401');
                console.log(error);
            });
    };

    const getAccounts = () => {
        expenseService
            .getAccounts()
            .then((data) => {
                setAccount(data.account);
            })
            .catch((error) => {
                if (error.response) if (error.response.status === 401) navigate('/401');
                console.log(error);
            });
    };

    const getUsers = () => {
        userService
            .getAll()
            .then((data) => {
                setRsos(data);
            })
            .catch((error) => {
                if (error.response) if (error.response.status === 401) navigate('/401');
                console.log(error);
            });
    };

    useEffect(() => {
        getUsers();
        getAccounts();
        getProducts();
    }, []);
    const loading = !rsos || !products || !account;

    console.log(loading);

    /*
      Main Design
    */
    return (
        <Page title={editing ? `Edit Payment` : `Add Payment`}>
            <Container maxWidth="xl">
                <ListPageTitle>{editing ? `Editing Payment` : 'Adding Payment'}</ListPageTitle>
                {loading ? (
                    <CenterLoading />
                ) : (
                    <AddPaymentForm
                        editing={editing}
                        rsos={rsos}
                        account={account}
                        products={products}
                    />
                )}
            </Container>
        </Page>
    );
};
