import GenericService from './GenericService';
import qs from 'qs';

class ServiceClass extends GenericService {
    constructor() {
        super();
    }

    add = (data, ID) => this.post('/slips', data);

    update = (data, ID) => this.patch(`/slips/${ID}`, data);

    getOne = (ID) => this.get(`/slips/${ID}`);

    getSlips = (pagination, date) =>
        this.get(`/slips?${qs.stringify(pagination)}&${qs.stringify(date)}`);

    remove = (ID) => this.delete(`/slips/${ID}`);
}

const slipsService = new ServiceClass();

export default slipsService;
