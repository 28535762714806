/*
    Imports
*/
import { Container } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CenterLoading from 'src/components/misc/CenterLoading';
import ListPageTitle from 'src/components/misc/ListPageTitle';
import Page from 'src/components/Page';
import expenseService from 'src/services/ExpenseServiceClass';
// import productService from 'src/services/ProductServiceClass';
import stockService from 'src/services/StockServiceClass';
// import projectService from 'src/services/ProductServiceClass';
import userService from 'src/services/UserService';
import ReturnStockForm from './forms/ReturnStockForm';

/*
    Main Working
*/
export default ({ editing }) => {

  const navigate = useNavigate();

  const stockID = useParams().stockID;

  const [stock, setStock] = useState();



  const getData = () => {
    stockService.getOne(stockID)
      .then((data) => {
        setStock(data);
      })
      .catch((error) => {
        if (error.response) if (error.response.status === 401) navigate('/401');

      });
  };

  useEffect(() => {
    getData()
  }, []);

  const loading = !stock;

  /*
    Main Design
  */
  return (
    <Page title={editing ? `Edit Stock` : `Return Stock`} >
      <Container maxWidth="xl">
        <ListPageTitle>
          {editing ? `Editing Stock` : "Returning Stock"}
        </ListPageTitle>
        {loading ?
          <CenterLoading />
          :
          <ReturnStockForm stock={stock} editing={editing} />
        }
      </Container>
    </Page >
  );
};
