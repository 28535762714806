import checkCircle from '@iconify/icons-ant-design/check-circle-filled';
import closeCircle from '@iconify/icons-ant-design/close-circle-filled';
import exclamationCircle from '@iconify/icons-ant-design/exclamation-circle-filled';
import lockOutlined from '@iconify/icons-ant-design/lock-outlined';
import unlockOutlined from '@iconify/icons-ant-design/unlock-outlined';
import walletIcon from '@iconify/icons-entypo/wallet';
import successFilled from '@iconify/icons-ep/success-filled';
import editFill from '@iconify/icons-eva/edit-fill';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import searchFill from '@iconify/icons-eva/search-fill';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import rupeeSign from '@iconify/icons-fa6-solid/rupee-sign';
import crossCircle from '@iconify/icons-gridicons/cross-circle';
import outlineAccountCircle from '@iconify/icons-ic/outline-account-circle';
import outlineUploadFile from '@iconify/icons-ic/outline-upload-file';
import roundFileMove from '@iconify/icons-ic/round-drive-file-move';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
import sharpUploadFile from '@iconify/icons-ic/sharp-upload-file';
import accountEye from '@iconify/icons-mdi/account-eye';
import atIcon from '@iconify/icons-mdi/at';
import CloudDownloadIcon from '@iconify/icons-mdi/download';
import password1Icon from '@iconify/icons-wpf/password1';

import briefcaseIcon from '@iconify/icons-mdi/briefcase';
import briefcaseCheck from '@iconify/icons-mdi/briefcase-check';

import moneyDismiss24Filled from '@iconify/icons-material-symbols/payments-rounded';
import money from '@iconify/icons-fluent/money-hand-24-filled';
import moneyBag from '@iconify/icons-healthicons/money-bag';

import policyIcon from '@iconify/icons-carbon/policy';
import workHistory from '@iconify/icons-material-symbols/work-history';
import shoppingCart from '@iconify/icons-fa-solid/shopping-cart';
import boxIcon from '@iconify/icons-entypo/box';
import userAccount from '@iconify/icons-bxs/user-account';
import peopleTeam32Filled from '@iconify/icons-fluent/people-team-32-filled';
import addCircle16Filled from '@iconify/icons-fluent/add-circle-16-filled';
import billIcon from '@iconify/icons-icon-park-solid/bill';
import moneyBillSlash from '@iconify/icons-uil/money-bill-slash';
import receipt20Filled from '@iconify/icons-fluent/receipt-20-filled';
import coinsIcon from '@iconify/icons-wpf/coins';
import print16Filled from '@iconify/icons-fluent/print-16-filled';
import truckReturn from '@iconify/icons-tabler/truck-return';
import journalLtr from '@iconify/icons-ooui/journal-ltr';
import copyArrowRight24Filled from '@iconify/icons-fluent/copy-arrow-right-24-filled';
import moneyCheckDollar from '@iconify/icons-fa6-solid/money-check-dollar';
import moneyBillTransfer from '@iconify/icons-fa6-solid/money-bill-transfer';
import chartTimelineVariantShimmer from '@iconify/icons-mdi/chart-timeline-variant-shimmer';
import accountService from '@iconify/icons-mdi/account-service';

export const AddIcon = addCircle16Filled;
export const ViewIcon = accountEye;
export const ViewIcon2 = eyeFill;
export const EditIcon = editFill;
export const LockIcon = lockOutlined;
export const UnlockIcon = unlockOutlined;

export const UnpaidIcon = crossCircle;
export const DownloadIcon = CloudDownloadIcon;
export const UploadIcon = outlineUploadFile;
export const UploadIcon2 = sharpUploadFile;

export const SearchIcon = searchFill;
export const TrashIcon = trash2Fill;
export const FilterIcon = roundFilterList;
export const MoveIcon = roundFileMove;

export const SuccessDialogIcon = checkCircle;
export const ErrorDialogIcon = closeCircle;
export const WarningDialogIcon = exclamationCircle;
export const CNICIcon = outlineAccountCircle;
export const EmailIcon = atIcon;
export const ShowPasswordIcon = eyeOffFill;
export const HidePasswordIcon = eyeFill;
export const PasswordIcon = password1Icon;

export const DashboardIcon = pieChart2Fill;
export const bankIcon = walletIcon;

export const RupeeIcon = rupeeSign;
export const PaidIcon = successFilled;
export const ProjectsIcon = briefcaseIcon;
export const ProjectsClosedIcon = briefcaseCheck;
export const PaymentsIcon = money;
export const SalaryIcon = moneyBag;
export const ExpensesIcon = moneyDismiss24Filled;
export const TransferIcon = moneyBillTransfer;
export const PolicyIcon = policyIcon;
export const UsersIcon = peopleFill;

export const WorkHistoryIcon = workHistory;
export const ProductsIcon = shoppingCart;
export const StockIcon = boxIcon;
export const MembersIcon = userAccount;
export const RSOIcon = peopleTeam32Filled;
export const BillsIcon = billIcon;
export const SlipsIcon = receipt20Filled;
export const CommissionsIcon = coinsIcon;
export const PrintBillIcon = print16Filled;
export const ReturnIcon = truckReturn;
export const GeneralJournalIcon = journalLtr;
export const ReturnBillIcon = moneyBillSlash;
export const AccountsIcon = moneyCheckDollar;
export const ProfitLossIcon = chartTimelineVariantShimmer;

export const CopyIcon = copyArrowRight24Filled;

export const ServicesIcon = accountService;
