/*
  Imports
*/
import { Container } from '@material-ui/core';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import customersService from 'src/services/CustomersServiceClass';
import Page from '../components/Page';
import SingleCustomerDetails from './body/SingleCustomerDetails';
import CustomerProducts from './tables/CustomerProducts';
import ListPageTitle from 'src/components/misc/ListPageTitle';
import CustomerBillsList from './tables/CustomerBillsList';
import CustomerPaymentsList from './tables/CustomerPaymentsList';
import { SETTINGS } from 'src/config/settings';
import DatePickerWithSearch from '../components/misc/table/DatePickerWithSearch';
import CenterLoading from 'src/components/misc/CenterLoading';
import FloatingAdd from 'src/components/misc/Buttons/FloatingButton';
import { PaymentsIcon } from 'src/config/icons';
import billService from 'src/services/BillServiceClass';
/*
  Main Working
*/
export default () => {
    /*
    States, Params, Navigation, Query, Variables.
  */
    const navigate = useNavigate();

    const [pageLoading, setPageLoading] = useState(true);

    const [loading, setLoading] = useState(true);

    const [billTableLoading, setBillTableLoading] = useState(true);

    const [paymentTableLoading, setPaymentTableLoading] = useState(true);

    const [data, setData] = useState([]);

    const [bill, setBill] = useState([]);

    const [payment, setPayment] = useState([]);

    const [billTablePagination, setBillTablePagination] = useState({
        search: '',
        perPage: SETTINGS.defaultPerPage,
        page: 0,
        order: 'desc',
        sort_by: 'id',
    });

    const [pagination, setPagination] = useState({
        search: '',
        perPage: SETTINGS.defaultPerPage,
        page: 0,
        order: 'desc',
        sort_by: 'id',
    });

    const [date, setDate] = useState({
        startDate: null,
        endDate: null,
    });

    const number = useParams().id;

    const title = `Customer Details`;

    /*
      Handlers, Functions
    */

    const getData = () => {
        setLoading(true);
        customersService
            .getSingle(number, date)
            .then((res) => {
                console.log('Get Single', res);
                setData(res);
            })
            .catch((error) => {
                if (error.response) if (error.response.status === 401) navigate('/401');
                navigate('/404');
            })
            .finally(() => {
                setLoading(false);
                setPageLoading(false);
            });
    };

    const getBill = () => {
        setBillTableLoading(true);
        customersService
            .getByBill(number, billTablePagination, date)
            .then((res) => {
                setBill(res);
            })
            .catch((error) => {
                if (error.response) if (error.response.status === 401) navigate('/401');
                navigate('/404');
                console.log('Error Response', error.response);
                console.log('Error ', error);
            })
            .finally(() => {
                setBillTableLoading(false);
            });
    };

    const getPayment = () => {
        setPaymentTableLoading(true);
        customersService
            .getByCustomer(number, pagination, date)
            .then((res) => {
                setPayment(res);
            })
            .catch((error) => {
                if (error.response) if (error.response.status === 401) navigate('/401');
                navigate('/404');
            })
            .finally(() => {
                setPaymentTableLoading(false);
            });
    };

    /*
      Use Effect Hooks.
    */
    useEffect(getData, [date]);

    useEffect(getBill, [billTablePagination, date]);

    useEffect(getPayment, [pagination, date]);

    const handleAdd = () => {
        navigate('./add');
    };

    const handleDeletePayment = (payment) => {
        customersService
            .remove(payment.customer, payment.id)
            .then(() => {
                getPayment();
            })
            .catch((err) => console.log(err));
    };

    const handleDeleteBill = (bill) => {
        billService
            .remove(bill)
            .then(() => {
                getBill();
            })
            .catch((err) => console.log(err));
    }

    /*
    Use Effect Hooks.
  */
    /*
    Main Design
  */
    return (
        <Page title={title}>
            <Container>
                {pageLoading ? (
                    <CenterLoading />
                ) : (
                    <>
                        <DatePickerWithSearch setDate={setDate} />
                        <ListPageTitle>{title}</ListPageTitle>
                        <SingleCustomerDetails data={data} />
                        <br />
                        <br />
                        <ListPageTitle>Customer Products</ListPageTitle>
                        <CustomerProducts data={data.product_details || []} loading={loading} />
                        <br />
                        <br />
                        <ListPageTitle>Customer Bills</ListPageTitle>
                        <CustomerBillsList
                            data={bill}
                            pagination={billTablePagination}
                            setPagination={setBillTablePagination}
                            loading={billTableLoading}
                            handleDelete={handleDeleteBill}
                        />
                        <br />
                        <br />
                        <ListPageTitle>Customer Payments</ListPageTitle>
                        <CustomerPaymentsList
                            data={payment}
                            pagination={pagination}
                            setPagination={setPagination}
                            loading={paymentTableLoading}
                            handleDelete={handleDeletePayment}
                        />
                        <FloatingAdd
                            tooltip="add customer payment"
                            onClick={handleAdd}
                            bottomSpacing={(theme) => theme.spacing(2)}
                            icon={PaymentsIcon}
                        />
                    </>
                )}
            </Container>
        </Page>
    );
};
