/*
    Imports
*/
import CenterLoading from 'src/components/misc/CenterLoading';
import { useParams, useNavigate } from 'react-router-dom';
import Page from 'src/components/Page';
import { useEffect, useState } from 'react';
import { fNumber } from 'src/utils/formatNumber';
import Logo from '../components/Logo';
import { SETTINGS } from 'src/config/settings';
import { Container } from '@material-ui/core';
import rsoService from 'src/services/RsoServiceClass';
import { printDiv } from 'src/utils/misc';
import billService from 'src/services/BillServiceClass';

/*
    Main Working
*/
export default ({ rso }) => {
    const billID = useParams().billID;
    const navigate = useNavigate();

    const [details, setDetails] = useState();

    const getData = () => {

        let f = rso ? rsoService.getRsoBill : billService.getBill;

        f(billID)
            .then((res) => {
                if (res?.services?.length < 1) res.services = null;
                setDetails(res);
                printDiv("receipt");
            })
            .catch((error) => {
                if (error.response) if (error.response.status === 401) navigate('/401');
                navigate('/404');
            })
            .finally(() => { });
    };

    const loading = !details;

    useEffect(getData, []);

    /*
          Main Design
        */

    return (
        <Page title="Print Bill">
            {loading ? (
                <Container style={{ height: '100vh' }}>
                    <CenterLoading />
                </Container>
            ) : (
                <>
                    <div id='receipt' style={{
                        widows: '100%',
                        padding: '0 6mm 0 0',
                    }}>
                        {/* <ListPageTitle>
                    {"Printing Bill"}
                </ListPageTitle> */}
                        {/* <AddProductForm editing={editing} /> */}
                        <style rel="stylesheet" media="print" />
                        <style>{`
                    body {
                        font-family: 'Roboto', sans-serif;
                        padding: 0;
                        color: black;
                    }
                    .receipt {
                        width: 100%;
                        padding: 0 6mm 0 0;
                    }
                    .orderNo {
                        width: 100%;
                        text-align: right;
                        padding-bottom: 1mm;
                        font-size: 8pt;
                        font-weight: bold;
                    }
                    
                    .orderNo:empty {
                        display: none;
                    }
              
                    #date {
                        margin: 5pt 0px;
                        text-align: left;
                        font-size: 8pt;
                    }

                   
                                         
                    .status {
                        text-align: center;
                        font-size: 10pt;
                        font-weight: bold;
                        margin:10px
                    }
                     
                    .statusBody {
                        text-align: center;
                        font-size: 6pt;
                    }
                    
                    table{
                        font-size: 8pt;
                        width: 100%;
                    }  
                    th{
                        text-align: right;
                        padding-top: 1px
                    }     
                    td{
                        padding-top: 1px
                    }
                                        
                    .right th{
                        text-align: right;
                        width:85%
                    }
                    .paddingS th{
                        font-weight: normal;
                    }
                    @media print {
                        .pagebreak {
                            clear: both;
                            page-break-after: always;
                        }
                    }
                    @page{
                        margin:0px;
                        padding:0px;
                        padding-left: 3mm;
                    }
                  
                `}</style>

                        <div className="receipt" >
                            <div className="orderNo">
                                Bill by: <span>{details?.billed_by}</span>
                            </div>
                            <div className="headerSubTitle">
                                <Logo />
                            </div>

                            <div id="date">Date: {details?.date.slice(0, 10)}</div>
                            <div id="date">
                                <div>Name: {details?.name}</div>
                                <div>{rso ? 'RSO' : 'Customer'}: {details?.rso ? details?.rso : details.customer}</div>
                            </div>
                            <hr />
                            <table>
                                <thead>
                                    <tr>
                                        <th>Sr. </th>
                                        <th>Product</th>
                                        <th>Price</th>
                                        <th>Quantity</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(details?.services ?? details?.items).map((a, index) => {
                                        console.log(a, index);
                                        return (
                                            <tr className="paddingS">
                                                <th>{index + 1}</th>
                                                <th>{a.product}</th>
                                                <th>{a.oprice > 1 ? fNumber(+a.oprice) : a.oprice}</th>
                                                <th>{a.quantity}</th>
                                                <th>{a.total > 1 ? fNumber(+a.total) : a.total}</th>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                            <hr />
                            <table>
                                <tbody>
                                    <tr className="right">
                                        <th>Total: </th>
                                        <th>{details?.total > 1 ? fNumber(+details?.total) : details?.total}</th>
                                    </tr>
                                    <tr className="right">
                                        <th>Discount: </th>
                                        <th>
                                            {details?.discount > 1 ? fNumber(+details?.discount) : details?.discount}
                                        </th>
                                    </tr>
                                    <tr className="right">
                                        <th>Tax: </th>
                                        <th>{details?.tax > 1 ? fNumber(+details?.tax) : details?.tax}</th>
                                    </tr>
                                </tbody>
                            </table>
                            <hr />
                            <table>
                                <tbody>
                                    <tr className="right">
                                        <th>Grand Total: </th>
                                        <th>{details?.gtotal > 1 ? fNumber(+details?.gtotal) : details?.gtotal}</th>
                                    </tr>
                                </tbody>
                            </table>
                            <hr />

                            <div className="statusBody">
                                Software Developed and Provided by East Devs ({SETTINGS.eastdevsLink.name}) Contact: (
                                {SETTINGS.contacts.arose} / {SETTINGS.contacts.qasim})
                            </div>
                        </div>
                    </div>
                    <div className="pagebreak"> </div>
                </>
            )}
        </Page>
    );
};
