/*
  Imports
*/
import { Container } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

/*
  Imports:
    Our Imports
    Components and Settings
*/
import AreaCharts from 'src/components/charts/AreaCharts';
import TextLogoCard from 'src/components/misc/cards/TextLogoCard';
import CenterLoading from 'src/components/misc/CenterLoading';
import ListPageTitle from 'src/components/misc/ListPageTitle';
import { PaidIcon, RupeeIcon, WarningDialogIcon } from 'src/config/icons';
import { SETTINGS } from 'src/config/settings';
import dashboardService from 'src/services/DashboardServiceClass';
import rsoService from 'src/services/RsoServiceClass';
import stakeService from 'src/services/StakeServiceClass';
import statsService from 'src/services/StatsServiceClass';
import userService from 'src/services/UserService';
import palette from 'src/theme/palette';
import { getMonthName } from 'src/utils/dateTime';
import Page from '../components/Page';
import UserCards from './body/UserCards';
import PaymentSummary from './tables/PaymentSummary';

/*
  Main Working
*/
export default () => {
    /*
    States, Params, Navigation, Query, Variables.
  */

    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const pageName = 'Dashboard';

    /*
    Handlers, Functions
  */
    const [data, setData] = useState();
    const [mData, setMData] = useState();

    const [mbData, setMbData] = useState();

    const [nBodData, setnBodData] = useState();

    const [inBank, setInBank] = useState(null);
    const [inCash, setInCash] = useState(null);

    const user = userService.getLoggedInUser();

    const getData = async () => {
        setLoading(true);
        console.log(user);

        if (user.rank >= SETTINGS.RANKS.manager) {
            dashboardService
                .account()
                .then((res) => {
                    console.log('RESPONSE:', res);
                    setInBank(+res.amount);
                })
                .catch((err) => {
                    console.log('Error getting amount in bank', err, err?.response);
                });

            dashboardService
                .cash()
                .then((res) => {
                    console.log('RESPONSE:', res);
                    setInCash(+res.amount);
                })
                .catch((err) => {
                    console.log('Error getting amount in bank', err, err?.response);
                });

            dashboardService
                .monthlyStats()
                .then((data) => {
                    console.log('Data from API', data);
                    setMData(data);
                })
                .catch((err) => {
                    if (err.response?.status === 401) navigate('/logout');
                    console.log('Error from API', err, err?.response);
                    //TODO: improve error handling here
                })
                .finally(() => {
                    setLoading(false);
                });

            dashboardService
                .paymentSummary()
                .then((data) => {
                    console.log('Data from API', data);
                    setMbData(data);
                })
                .catch((err) => {
                    if (err.response?.status === 401) navigate('/logout');
                    console.log('Error from API', err, err?.response);
                    //TODO: improve error handling here
                })
                .finally(() => {
                    setLoading(false);
                });
        }

        if (user.rank == SETTINGS.RANKS.rso)
            rsoService
                .getOne(user.id)
                .then((data) => {
                    console.log('Data from API', data, data.left == 0);
                    setnBodData(data.left);
                })
                .catch((err) => {
                    if (err.response?.status === 401) navigate('/logout');
                    console.log('Error from API', err, err?.response);
                    //TODO: improve error handling here
                })
                .finally(() => {
                    setLoading(false);
                });

        if (user.rank > SETTINGS.RANKS.rso && user.rank < SETTINGS.RANKS.manager) setLoading(false);
    };

    /*
    Use Effect Hooks.
  */
    useEffect(getData, []);

    const mLabels = [];
    const earned = [];
    const spent = [];
    const profit = [];
    if (mData) {
        console.log(mData);
        for (let i = mData.length - 1; i >= 0; i--) {
            const m = mData[i];
            mLabels.push(`${getMonthName(m.month)} ${m.year}`);
            earned.push(m.sale - m.cost + m.commission);
            spent.push(m.expense);
            profit.push(m.profit);
        }
    }

    const chartData = [
        {
            name: 'Spendings',
            type: 'bar',
            data: spent,
            color: palette.error.main,
        },
        {
            name: 'Earnings',
            type: 'area',
            data: earned,
            color: palette.success.main,
        },
    ];

    const chartProfitData = [
        {
            name: 'Profit',
            type: 'area',
            data: profit,
            color: palette.success.main,
        },
    ];

    /*
    Main Design.
  */
    return (
        <Page title={pageName}>
            <Container>
                <ListPageTitle>{pageName}</ListPageTitle>
                {loading ? (
                    <CenterLoading />
                ) : (
                    <>
                        {inBank != null && (
                            <>
                                <TextLogoCard
                                    header="Company Account"
                                    icon={RupeeIcon}
                                    color={'#fff'}
                                    backgroundColor={
                                        inBank < 0 ? palette.error.dark : palette.success.darker
                                    }
                                    title={inBank < 0 ? 'Advance amount' : 'Total amount'}
                                    amount={Math.abs(inBank)}
                                />
                                <br />
                            </>
                        )}
                        {inCash != null && (
                            <>
                                <TextLogoCard
                                    header="Cash"
                                    icon={RupeeIcon}
                                    color={'#fff'}
                                    backgroundColor={
                                        inCash < 0 ? palette.error.dark : palette.success.darker
                                    }
                                    title={inCash < 0 ? 'Advance amount' : 'Total amount'}
                                    amount={Math.abs(inCash)}
                                />
                                <br />
                            </>
                        )}
                        <br />
                        {/* {data && <PieDonutChart symbol='%' title="Current Stake" labels={labels} data={stakes} />}
            <br /> */}
                        {mData && (
                            <AreaCharts
                                title={'Monthly Spending/Earning Details'}
                                labels={mLabels}
                                data={chartData}
                                type={['solid', 'gradient']}
                            />
                        )}
                        <br />
                        {mData && (
                            <AreaCharts
                                title={'Monthly Profit Details'}
                                labels={mLabels}
                                data={chartProfitData}
                                type={['solid', 'gradient']}
                            />
                        )}
                        <br />
                        {mbData && <PaymentSummary data={mbData} />}
                        <br />
                        {user.rank == SETTINGS.RANKS.rso && (
                            <TextLogoCard
                                icon={RupeeIcon}
                                color={'#fff'}
                                backgroundColor={
                                    nBodData != 0
                                        ? nBodData < 0
                                            ? palette.success.darker
                                            : palette.error.dark
                                        : palette.info.dark
                                }
                                title={
                                    nBodData != 0
                                        ? nBodData < 0
                                            ? 'Amount to recieve'
                                            : 'Amount left to pay'
                                        : 'Your account is clear'
                                }
                                amount={Math.abs(+nBodData)}
                            />
                        )}
                        <br />
                        {user.rank > SETTINGS.RANKS.rso && user.rank < SETTINGS.RANKS.manager && (
                            <>
                                <TextLogoCard
                                    icon={WarningDialogIcon}
                                    color={'#fff'}
                                    backgroundColor={palette.info.main}
                                    title={'Comming Soon'}
                                    noAmount={true}
                                />
                            </>
                        )}

                        <br />
                    </>
                )}
            </Container>
        </Page>
    );
};
