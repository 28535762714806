/*
  Imports
*/
import { Container } from '@material-ui/core';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DatePickerWithSearch from '../components/misc/table/DatePickerWithSearch';
import CenterLoading from 'src/components/misc/CenterLoading';
import userService from 'src/services/UserService';
import rsoService from 'src/services/RsoServiceClass';
import salaryService from 'src/services/SalaryServiceClass';
import productsService from 'src/services/ProductsServiceClass';
import paymentsService from 'src/services/PaymentsServiceClass';
import Page from '../components/Page';
import UserProfile from './profile/UserProfile';
import { SETTINGS } from 'src/config/settings';
import HiddenDetails from 'src/components/HiddenDetails';
import SingleRSODetails from 'src/pages/body/SingleRSODetails';
import PaymentsList from './tables/PaymentsList';
import RSOProducts from './tables/RSOProducts';
import SingleRSOBills from './tables/SingleRSOBills';
import ListPageTitle from 'src/components/misc/ListPageTitle';
import FloatingEdit from 'src/components/misc/Buttons/FloatingButton';
import FloatingAdd from 'src/components/misc/Buttons/FloatingButton';
import { BillsIcon, SalaryIcon, EditIcon } from 'src/config/icons';
import expenseService from 'src/services/ExpenseServiceClass';
import transferService from 'src/services/TransferServiceClass';
import TransfersList from './tables/TransfersList';

/*
  Main Working
*/
export default () => {
    /*
    States, Params, Navigation, Query, Variables.
  */
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [paymentTableLoading, setPaymentTableLoading] = useState(true);
    const [productTableLoading, setProductTableLoading] = useState(true);
    const [csrProductTableLoading, setCsrProductTableLoading] = useState(true);
    const [billTableLoading, setBillTableLoading] = useState(true);
    const [csrBillTableLoading, setCsrBillTableLoading] = useState(true);
    const [salaryTableLoading, setSalaryTableLoading] = useState(true);
    const [expenseTableLoading, setExpenseTableLoading] = useState(true);
    const [transferTableLoading, setTransferTableLoading] = useState(true);

    const [user, setUser] = useState({ name: '' });
    const [payment, setPayment] = useState([]);
    const [bill, setBill] = useState([]);
    const [product, setProduct] = useState([]);
    const [csrProduct, setCsrProduct] = useState([]);
    const [csrBill, setCsrBill] = useState([]);
    const [data, setData] = useState([]);
    const [salary, setSalary] = useState([]);
    const [expense, setExpense] = useState([]);
    const [transfer, setTransfer] = useState([]);

    const loggedInUser = userService.getLoggedInUser();

    const [date, setDate] = useState({
        startDate: null,
        endDate: null,
    });

    const [pagination, setPagination] = useState({
        search: '',
        perPage: SETTINGS.defaultPerPage,
        page: 0,
        order: 'desc',
        sort_by: 'id',
    });

    const [billTablePagination, setBillTablePagination] = useState({
        search: '',
        perPage: SETTINGS.defaultPerPage,
        page: 0,
        order: 'desc',
        sort_by: 'id',
    });

    const [csrBillTablePagination, setCsrBillTablePagination] = useState({
        search: '',
        perPage: SETTINGS.defaultPerPage,
        page: 0,
        order: 'desc',
        sort_by: 'id',
    });

    const [salaryTablePagination, setSalaryTablePagination] = useState({
        search: '',
        perPage: SETTINGS.defaultPerPage,
        page: 0,
        order: 'desc',
        sort_by: 'id',
    });

    const [expenseTablePagination, setExpenseTablePagination] = useState({
        search: '',
        perPage: SETTINGS.defaultPerPage,
        page: 0,
        order: 'desc',
        sort_by: 'id',
    });

    const [transferTablePagination, setTransferTablePagination] = useState({
        search: '',
        perPage: SETTINGS.defaultPerPage,
        page: 0,
        order: 'desc',
        sort_by: 'id',
    });

    const userID = useParams().id;

    const { name, rank } = user;

    const isManagerOrHigher =
        loggedInUser.rank >= SETTINGS.RANKS.manager && loggedInUser.rank >= rank;

    /*
    Handlers, Functions
  */
    const getData = () => {
        userService
            .getUser(userID ?? loggedInUser.username, date)
            .then((u) => {
                setUser(u);

                getRSOPayments(u);
                getRSOProducts(u);
                getCSRProucts(u);
                getRSOBill(u);
                getCSRBill(u);

                getSalary(u);
                getExpense(u);
                getTransfers(u);
            })
            .catch((_err) => {
                console.log('Eror', _err, _err.response);
                if (_err.response) if (_err.response.status === 401) navigate('/401');
                navigate('/404');
            })
            .finally(() => setLoading(false));
    };

    const getRSOPayments = (user) => {
        setPaymentTableLoading(true);
        paymentsService
            .getByRSO(user.id, pagination, date)
            .then((res) => {
                console.log('Get RSO Payments', res);
                setPayment(res);
            })
            .catch((error) => {
                if (error.response) if (error.response.status === 401) navigate('/401');
                navigate('/404');
            })
            .finally(() => {
                setPaymentTableLoading(false);
            });
    };

    const getSalary = (user) => {
        setSalaryTableLoading(true);
        salaryService
            .getSalary(user.id, salaryTablePagination, date)
            .then((res) => {
                console.log('Get Salary', res);
                setSalary(res);
            })
            .catch((error) => {
                if (error.response) if (error.response.status === 401) navigate('/401');
                navigate('/404');
            })
            .finally(() => {
                setSalaryTableLoading(false);
            });
    };

    const getRSOProducts = (user) => {
        setProductTableLoading(true);
        productsService
            .getRSOProducts(user.id, date)
            .then((res) => {
                console.log('Get RSO Products', res);
                setProduct(res);
            })
            .catch((error) => {
                if (error.response) if (error.response.status === 401) navigate('/401');
                navigate('/404');
            })
            .finally(() => {
                setProductTableLoading(false);
            });
    };

    const getCSRProucts = (user) => {
        setCsrProductTableLoading(true);
        productsService
            .getCSRProducts(user.id, date)
            .then((res) => {
                console.log('Get CSR Products', res);
                setCsrProduct(res);
            })
            .catch((error) => {
                if (error.response) if (error.response.status === 401) navigate('/401');
                navigate('/404');
            })
            .finally(() => {
                setCsrProductTableLoading(false);
            });
    };

    const getRSOBill = (user) => {
        setBillTableLoading(true);
        rsoService
            .getRSOSingleBill(user.id, billTablePagination, date)
            .then((res) => {
                console.log('Get RSO Bill', res);
                setBill(res);
            })
            .catch((error) => {
                if (error.response) if (error.response.status === 401) navigate('/401');
                navigate('/404');
                console.log('Error Bill', error);
                console.log('Error Bill Response', error.response);
            })
            .finally(() => {
                setBillTableLoading(false);
            });
    };

    const getCSRBill = (user) => {
        setCsrBillTableLoading(true);
        rsoService
            .getCSRSingleBill(user.id, billTablePagination, date)
            .then((res) => {
                console.log('Get CSR Bill', res);
                setCsrBill(res);
            })
            .catch((error) => {
                if (error.response) if (error.response.status === 401) navigate('/401');
                navigate('/404');
                console.log('Error Bill', error);
                console.log('Error Bill Response', error.response);
            })
            .finally(() => {
                setCsrBillTableLoading(false);
            });
    };

    const getExpense = (user) => {
        setExpenseTableLoading(true);
        expenseService
            .getExpenseByMember(user.id, expenseTablePagination, date)
            .then((res) => {
                console.log('Get Expense', res);
                setExpense(res);
            })
            .catch((error) => {
                if (error.response) if (error.response.status === 401) navigate('/401');
                //navigate('/404');
                console.log('Error in expense', error.response);
            })
            .finally(() => {
                setExpenseTableLoading(false);
            });
    };

    const getTransfers = (user) => {
        setTransferTableLoading(true);
        transferService
            .getByMember(user.id, expenseTablePagination, date)
            .then((res) => {
                console.log('Get Transfer', res);
                setTransfer(res);
            })
            .catch((error) => {
                if (error.response) if (error.response.status === 401) navigate('/401');
                //navigate('/404');
                console.log('Error in expense', error.response);
            })
            .finally(() => {
                setTransferTableLoading(false);
            });
    };
    /*
    Use Effect Hooks.
  */

    useEffect(getData, []);

    useEffect(() => {
        if (loading) return;
        getRSOPayments(user);
    }, [pagination]);

    useEffect(() => {
        if (loading) return;
        getSalary(user);
    }, [salaryTablePagination]);

    useEffect(() => {
        if (loading) return;
        getRSOBill(user);
    }, [billTablePagination]);

    useEffect(() => {
        if (loading) return;
        getCSRBill(user);
    }, [csrBillTablePagination]);

    useEffect(() => {
        if (loading) return;
        getExpense(user);
    }, [expenseTablePagination]);

    useEffect(() => {
        if (loading) return;
        getTransfers(user);
    }, [transferTablePagination]);

    useEffect(() => {
        if (loading) return;
        getData(user);
    }, [date]);

    const handleEdit = () => {
        navigate(`./edit`);
    };

    const handleAdd = () => {
        navigate(`/rso/bills/generate?rso=${userID}`);
    };

    const handleSalary = () => {
        navigate(`./add-salary`);
    };

    const handleDeletePayment = (ID) => {
        paymentsService
            .remove(ID)
            .then(() => {
                getRSOPayments(user);
            })
            .catch((err) => console.log(err));
    };

    const handleDeleteBill = (ID) => {
        rsoService
            .remove(ID)
            .then(() => {
                getRSOBill(user);
            })
            .catch((err) => console.log(err));
    };

    const handleDeleteExpense = (ID) => {
        expenseService
            .remove(ID)
            .then(() => {
                getExpense(user);
            })
            .catch((err) => console.log(err));
    };

    const handleDeleteTransfer = (ID) => {
        transferService
            .remove(ID)
            .then(() => {
                getTransfers(user);
            })
            .catch((err) => console.log(err));
    };

    /*
    Main Design
  */
    return (
        <Page title={!userID ? 'Your Profile' : `Viewing ${userID}`}>
            {loading ? (
                <CenterLoading />
            ) : (
                <Container maxWidth="xl">
                    <UserProfile user={user} />
                    {(isManagerOrHigher || (userID == user.username)) && (
                        <>
                            <HiddenDetails user={user} />
                            <br />
                            <br />
                            <ListPageTitle>Additional Details</ListPageTitle>
                            <DatePickerWithSearch setDate={setDate} />

                            <SingleRSODetails data={user} />
                            {product?.length > 0 &&
                                <Fragment>
                                    <br />
                                    <br />
                                    <ListPageTitle>RSO Products</ListPageTitle>
                                    <RSOProducts data={product} loading={productTableLoading} user_id={user.id} />
                                </Fragment>
                            }
                            {csrProduct?.length > 0 &&
                                <Fragment>
                                    <br />
                                    <br />
                                    <ListPageTitle>CSR Products</ListPageTitle>
                                    <RSOProducts data={csrProduct} loading={csrProductTableLoading} user_id={user.id} csr={true} />
                                </Fragment>
                            }
                            {bill?.length > 0 &&
                                <Fragment>
                                    <br />
                                    <br />
                                    <ListPageTitle>RSO Bills</ListPageTitle>
                                    <SingleRSOBills
                                        data={bill}
                                        loading={billTableLoading}
                                        pagination={billTablePagination}
                                        setPagination={setBillTablePagination}
                                        handleDelete={handleDeleteBill}
                                    />
                                </Fragment>
                            }
                            {csrBill?.length > 0 &&
                                <Fragment>
                                    <br />
                                    <br />
                                    <ListPageTitle>CSR Bills</ListPageTitle>
                                    <SingleRSOBills
                                        data={csrBill}
                                        loading={csrBillTableLoading}
                                        pagination={csrBillTablePagination}
                                        setPagination={setCsrBillTablePagination}
                                        handleDelete={handleDeleteBill}
                                    />
                                </Fragment>
                            }
                            {payment?.length > 0 &&
                                <Fragment>
                                    <br />
                                    <br />
                                    <ListPageTitle>Payments</ListPageTitle>
                                    <PaymentsList
                                        data={payment}
                                        pagination={pagination}
                                        setPagination={setPagination}
                                        loading={paymentTableLoading}
                                        handleDelete={handleDeletePayment}
                                        route={`/payments`}
                                    />
                                </Fragment>
                            }

                            <br />
                            <br />

                            {rank < SETTINGS.RANKS.owner && salary?.length > 0 && (
                                <Fragment>
                                    <br />
                                    <br />
                                    <ListPageTitle>Salaries</ListPageTitle>
                                    <PaymentsList
                                        data={salary}
                                        pagination={salaryTablePagination}
                                        setPagination={setSalaryTablePagination}
                                        loading={salaryTableLoading}
                                        route={`/salary`}
                                    />
                                </Fragment>
                            )}
                        </>
                    )}
                    {expense?.length > 0 && (
                        <Fragment>
                            <br />
                            <br />
                            <ListPageTitle>Expenses</ListPageTitle>
                            <PaymentsList
                                data={expense}
                                pagination={expenseTablePagination}
                                setPagination={setExpenseTablePagination}
                                loading={expenseTableLoading}
                                handleDelete={handleDeleteExpense}
                                route={`/expense`}
                            />
                        </Fragment>
                    )}
                    {transfer?.length > 0 && (
                        <Fragment>
                            <br />
                            <br />
                            <ListPageTitle>Transfers</ListPageTitle>
                            <TransfersList
                                data={transfer}
                                pagination={transferTablePagination}
                                setPagination={setTransferTablePagination}
                                loading={transferTableLoading}
                                handleDelete={handleDeleteTransfer}
                                route={`/transfer`}
                            />
                        </Fragment>
                    )}

                    {isManagerOrHigher && (
                        <>
                            {rank < SETTINGS.RANKS.owner && (
                                <FloatingAdd
                                    tooltip="add salary"
                                    onClick={handleSalary}
                                    bottomSpacing={(theme) => theme.spacing(18)}
                                    icon={SalaryIcon}
                                />
                            )}

                            <FloatingAdd
                                tooltip="add bills"
                                onClick={handleAdd}
                                bottomSpacing={(theme) => theme.spacing(10)}
                                icon={BillsIcon}
                            />

                            <FloatingEdit
                                tooltip="edit details"
                                onClick={handleEdit}
                                bottomSpacing={(theme) => theme.spacing(2)}
                                icon={EditIcon}
                            />
                        </>
                    )}
                </Container>
            )}
        </Page>
    );
};
