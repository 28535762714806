/*
    Imports
*/
import { Container } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CenterLoading from 'src/components/misc/CenterLoading';
import ListPageTitle from 'src/components/misc/ListPageTitle';
import Page from 'src/components/Page';
import salaryService from 'src/services/SalaryServiceClass';
import userService from 'src/services/UserService';
import AddSalaryForm from './forms/AddSalaryForm';

/*
    Main Working
*/
export default ({ editing }) => {
    const navigate = useNavigate();

    const [account, setAccount] = useState();
    const [user, setUser] = useState();

    const userID = useParams().userID;

    console.log(userID)

    const getAccounts = () => {
        salaryService
            .getAccounts()
            .then((data) => {
                setAccount(data.from_account);
            })
            .catch((error) => {
                if (error.response) if (error.response.status === 401) navigate('/401');
                console.log(error);
            });
    };

    const getUser = () => {
        userService
            .getUser(userID)
            .then((data) => {
                setUser(data);
            })
            .catch((error) => {
                if (error.response) if (error.response.status === 401) navigate('/401');
                console.log(error);
            });
    };

    useEffect(() => {
        getAccounts();
        if (userID) getUser();
    }, []);

    const loading = !account || userID ? !user : user;

    /*
        Main Design
      */
    return (
        <Page title={editing ? 'Edit Salary' : `Add Salary`}>
            <Container maxWidth="xl">
                <ListPageTitle>{editing ? 'Editing Salary' : 'Adding Salary'}</ListPageTitle>
                {loading ? <CenterLoading /> : <AddSalaryForm account={account} user={user} editing={editing} />}
            </Container>
        </Page>
    );
};
