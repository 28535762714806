/*
  Imports
*/
import { Container } from '@material-ui/core';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import productsService from 'src/services/ProductsServiceClass';
import CenterLoading from 'src/components/misc/CenterLoading';
import Page from '../components/Page';
import SingleProductDetails from './body/SingleProductDetails';
import StocksDetails from './tables/StocksDetails';
import stocksService from 'src/services/StocksServiceClass';
import ListPageTitle from 'src/components/misc/ListPageTitle';
import { SETTINGS } from 'src/config/settings';
import FloatingAdd from 'src/components/misc/Buttons/FloatingButton';
import { StockIcon } from 'src/config/icons';

/*
  Main Working
*/
export default () => {
    /*
    States, Params, Navigation, Query, Variables.
  */
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);

    const [data, setData] = useState([]);

    const [stock, setStock] = useState([]);

    const { id } = useParams();

    const [pagination, setPagination] = useState({
        search: '',
        perPage: SETTINGS.defaultPerPage,
        page: 0,
        order: 'asc',
        sort_by: 'id'
    });

    const title = `Product Details`;

    /*
      Handlers, Functions
    */
    const getData = () => {
        setLoading(true);
        productsService
            .getOne(id)
            .then((res) => {
                console.log('Product', res);
                setData(res);
            })
            .catch((error) => {
                if (error.response) if (error.response.status === 401) navigate('/401');
                navigate('/404');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getStock = () => {
        setLoading(true);
        console.log('Error', pagination);
        stocksService
            .getByProduct(id, pagination)
            .then((res) => {
                setStock(res);
            })
            .catch((error) => {
                if (error.response) if (error.response.status === 401) navigate('/401');
                navigate('/404');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    /*
      Use Effect Hooks.
    */
    useEffect(getStock, [pagination]);

    useEffect(getData, []);

    const handleAdd = () => {
        navigate('/stocks/add');
    };

    /*
    Use Effect Hooks.
  */
    /*
    Main Design
  */
    return (
        <Page title={title}>
            <Container>
                {loading ? (
                    <CenterLoading />
                ) : (
                    <>
                        <ListPageTitle>{title}</ListPageTitle>
                        <SingleProductDetails data={data} />
                        <br />
                        <br />
                        <ListPageTitle>Product Stock Details</ListPageTitle>
                        <StocksDetails
                            data={stock}
                            pagination={pagination}
                            setPagination={setPagination}
                        />
                        <FloatingAdd
                            tooltip="add stock"
                            onClick={handleAdd}
                            bottomSpacing={(theme) => theme.spacing(2)}
                            icon={StockIcon}
                        />
                    </>
                )}
            </Container>
        </Page>
    );
};
