/*
  Imports
*/
import { Grid, Typography } from '@material-ui/core';
import { fNumber } from 'src/utils/formatNumber';
import Label from 'src/components/misc/Label';

/*
  Main Working
*/
export default ({ payment, cpayment, slip, commission }) => {
    /*
    States, Params, Navigation, Query, Variables.
  */

    /*
    Main Design
  */

    const balance = slip.total + commission.total - payment.total + cpayment;

    return (
        <>
            <Grid container spacing={0} style={{ paddingBottom: 10, paddingTop: 20 }}>
                <Grid item xs={12} sm={2} md={2}>
                    <Typography fontWeight="bold">Payments Total: </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <Typography>Rs. {fNumber(payment.total)}</Typography>
                </Grid>
                <Grid item xs={12} sm={2} md={2}>
                    <Typography fontWeight="bold">Slips Total: </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <Typography>Rs. {fNumber(slip.total)}</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={0} style={{ paddingBottom: 10, paddingTop: 10 }}>
                <Grid item xs={12} sm={2} md={2}>
                    <Typography fontWeight="bold">Commissions Total: </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <Typography>Rs. {fNumber(commission.total)}</Typography>
                </Grid>
                <Grid item xs={12} sm={2} md={2}>
                    <Typography fontWeight="bold">Balance: </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <Label
                        variant="ghost"
                        color={(balance > 0 && 'success') || (balance < 0 && 'error') || 'info'}
                    >
                        Rs. {fNumber(Math.abs(balance))}
                    </Label>
                </Grid>
            </Grid>
        </>
    );
};
