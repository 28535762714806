/*
  Imports
*/
import { Card, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import palette from 'src/theme/palette';
import { UserListHead } from '../../components/misc/table';
import Scrollbar from '../../components/Scrollbar';
import { fNumber } from 'src/utils/formatNumber';

/*
  Global Variables, Functions
*/

/*
  Main Working
*/
export default ({ data }) => {
    /*
    States, Params, Navigation, Query, Variables.
  */

    const TableHeader = [
        { id: 'id', label: 'ID', align: 'left' },
        { id: 'account', label: 'Account', align: 'left' },
        { id: 'credit', label: 'Credit', align: 'left' },
        { id: 'debit', label: 'Debit', align: 'left' },
    ];

    /*
    Handlers, Functions
  */

    /*
    Main Design.
  */
    return (
        <>
            <Card>
                <Scrollbar>
                    <TableContainer sx={{ minWidth: 800 }}>
                        <Table>
                            <UserListHead
                                headLabel={TableHeader}
                                rowCount={data.length}
                                hideCheckBoxes={true}
                            />
                            <TableBody>
                                {data.map((row) => {
                                    const { id, account, credit, debit } = row;

                                    return (
                                        <TableRow
                                            hover
                                            key={id}
                                            tabIndex={-1}
                                            role="checkbox"
                                            style={{
                                                backgroundColor: palette.background.hover,
                                                textDecoration: 'none',
                                            }}
                                        >
                                            <TableCell padding="checkbox"></TableCell>
                                            <TableCell align="left"> {id}</TableCell>
                                            <TableCell align="left"> {account}</TableCell>
                                            <TableCell align="left">
                                                {!credit ? '' : 'Rs. ' + fNumber(credit)}
                                            </TableCell>
                                            <TableCell align="left">
                                                {!debit ? '' : 'Rs. ' + fNumber(debit)}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Scrollbar>
            </Card>
            <br />
            <br />
        </>
    );
};
