/*
  Imports
*/
import { Container } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { BillsIcon, ReturnBillIcon, UsersIcon } from 'src/config/icons';
import { useEffect, useState } from 'react';
import FloatingAdd from 'src/components/misc/Buttons/FloatingButton';
import CenterLoading from 'src/components/misc/CenterLoading';
import ListPageTitle from 'src/components/misc/ListPageTitle';
import userService from 'src/services/UserService';
import Page from '../components/Page';
import UsersList from './tables/MembersList';
import { SETTINGS } from 'src/config/settings';

/*
  Main Working
*/
export default () => {
    /*
    States, Params, Navigation, Query, Variables.
  */
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [retired, setRetired] = useState([]);

    const title = 'Members';
    const loggedInUser = userService.getLoggedInUser();

    /*
      Handlers, Functions
    */
    const getData = () => {
        setLoading(true);
        userService
            .getAll()
            .then((res) => {
                setData(res);
            })
            .catch((error) => {
                if (error.response) if (error.response.status === 401) navigate('/401');
                navigate('/404');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getRetired = () => {
        setRetired([]);
        userService
            .getRetired()
            .then((res) => {
                setRetired(res);
            })
            .catch((error) => {
                if (error.response) if (error.response.status === 401) navigate('/401');
                navigate('/404');
            })
    }

    /*
      Use Effect Hooks.
    */
    useEffect(getData, []);
    useEffect(getRetired, []);

    /*
    Handlers, Functions
  */
    const handleAdd = () => {
        navigate('./add');
    };

    const handleBillAdd = () => {
        navigate('/rso/bills/generate');
    };

    const handleReturn = () => {
        navigate('/rso/bills/return');
    };

    /*
    Main Design
  */
    return (
        <Page title={title}>
            <Container>
                <ListPageTitle>{title}</ListPageTitle>
                {loading ? <CenterLoading /> : <UsersList data={data} />}
                <br />
                <br />
                <ListPageTitle>Retired Members</ListPageTitle>
                <UsersList data={retired} />
                {loggedInUser.rank >= SETTINGS.RANKS.manager && (
                    <FloatingAdd
                        tooltip="add new member"
                        onClick={handleAdd}
                        bottomSpacing={(theme) => theme.spacing(2)}
                        icon={UsersIcon}
                    />
                )}

                <FloatingAdd
                    tooltip="add member bill"
                    onClick={handleBillAdd}
                    bottomSpacing={(theme) => theme.spacing(10)}
                    icon={BillsIcon}
                />

                {loggedInUser.rank >= SETTINGS.RANKS.manager && (
                    <FloatingAdd
                        tooltip="return bill"
                        onClick={handleReturn}
                        bottomSpacing={(theme) => theme.spacing(18)}
                        icon={ReturnBillIcon}
                    />
                )}
            </Container>
        </Page>
    );
};
