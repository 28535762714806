/*
  Imports
*/
import {
    Card,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
} from '@material-ui/core';
import { filter } from 'lodash';
import { useState } from 'react';
import { SETTINGS } from 'src/config/settings';
import palette from 'src/theme/palette';
import { getComparator, stabilize } from 'src/utils/table';
import SearchNotFound from '../../components/misc/alerts/SearchNotFound';
import { UserListHead, UserListToolbar } from '../../components/misc/table';
import Scrollbar from '../../components/Scrollbar';
import { fNumber } from 'src/utils/formatNumber';
import CenterLoading from 'src/components/misc/CenterLoading';
import Label from 'src/components/misc/Label';
import { Typography } from '@mui/material';
import { Fragment } from 'react';

/*
  Main Working
*/
export default ({ data, loading }) => {
    /*
    States, Params, Navigation, Query, Variables.
  */

    const TableHeader = [
        { id: 'profit', label: 'Profit', align: 'left' },
        { id: 'amount', label: 'amount', align: 'left' },
        { id: 'loss', label: 'Loss', align: 'left' },
        { id: 'amount', label: 'amount', align: 'left' },
    ];

    /*
    Main Design.
  */

    return (
        <>
            {loading ? (
                <CenterLoading />
            ) : (
                <>
                    <Card>
                        <Scrollbar>
                            <TableContainer sx={{ minWidth: 800 }}>
                                <Table>
                                    <UserListHead
                                        headLabel={TableHeader}
                                        rowCount={data.length}
                                        hideCheckBoxes={true}
                                    />
                                    <TableBody>
                                        {data.map((row, index) => {
                                            const { profit, loss } = row;

                                            return (
                                                <TableRow
                                                    hover
                                                    key={index + 'profitlosstable'}
                                                    tabIndex={-1}
                                                    role="checkbox"
                                                    style={{
                                                        backgroundColor: palette.background.hover,
                                                        textDecoration: 'none',
                                                    }}
                                                >
                                                    <TableCell padding="checkbox"></TableCell>
                                                    {!!profit ? (
                                                        <Fragment>
                                                            <TableCell align="left">
                                                                {profit.account == 'Total' ? (
                                                                    <Typography
                                                                        variant="subtitle2"
                                                                        noWrap
                                                                    >
                                                                        {profit.account}
                                                                    </Typography>
                                                                ) : (
                                                                    profit.account
                                                                )}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <Label
                                                                    variant="ghost"
                                                                    color={
                                                                        (profit.profit > 0 &&
                                                                            'success') ||
                                                                        'error'
                                                                    }
                                                                >
                                                                    Rs.{' '}
                                                                    {fNumber(
                                                                        Math.abs(profit.profit),
                                                                    )}
                                                                </Label>
                                                            </TableCell>
                                                        </Fragment>
                                                    ) : (
                                                        <Fragment>
                                                            <TableCell align="left">-</TableCell>
                                                            <TableCell align="left">-</TableCell>
                                                        </Fragment>
                                                    )}
                                                    {!!loss ? (
                                                        <Fragment>
                                                            <TableCell align="left">
                                                                {loss.account == 'Total' ? (
                                                                    <Typography
                                                                        variant="subtitle2"
                                                                        noWrap
                                                                    >
                                                                        {loss.account}
                                                                    </Typography>
                                                                ) : (
                                                                    loss.account
                                                                )}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <Label
                                                                    variant="ghost"
                                                                    color={
                                                                        (loss.profit > 0 &&
                                                                            'success') ||
                                                                        'error'
                                                                    }
                                                                >
                                                                    Rs.{' '}
                                                                    {fNumber(Math.abs(loss.profit))}
                                                                </Label>
                                                            </TableCell>
                                                        </Fragment>
                                                    ) : (
                                                        <Fragment>
                                                            <TableCell align="left">-</TableCell>
                                                            <TableCell align="left">-</TableCell>
                                                        </Fragment>
                                                    )}
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Scrollbar>
                    </Card>
                </>
            )}
        </>
    );
};
