import GenericService from './GenericService';
import qs from 'qs';

class ServiceClass extends GenericService {
    constructor() {
        super();
    }

    add = (data) => this.post('/bills', data);

    getBill = (ID) => this.get(`bills/${ID}`);

    getAll = (pagination, date) =>
        this.get(`/bills?${qs.stringify(pagination)}&${qs.stringify(date)}`);

    addRso = (data) => this.post('/bills/rso', data);

    returnBill = (data) => this.post('/bills/return', data);

    remove = (ID) => this.delete(`/bills/${ID}`);
}

const billService = new ServiceClass();

export default billService;
