/*
  Imports
*/
import {
    Card,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    Tooltip,
} from '@material-ui/core';
import { useState } from 'react';
import { SETTINGS } from 'src/config/settings';
import palette from 'src/theme/palette';
import SearchNotFound from '../../components/misc/alerts/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../components/misc/table';
import { fNumber } from 'src/utils/formatNumber';
import { getDateString } from 'src/utils/dateTime';
import Scrollbar from '../../components/Scrollbar';
import Label from 'src/components/misc/Label';
import CenterLoading from 'src/components/misc/CenterLoading';
import { EditIcon, TrashIcon } from 'src/config/icons';
import userService from 'src/services/UserService';
import { useNavigate } from 'react-router-dom';

/*
  Global Variables, Functions
*/

/*
  Main Working
*/
export default ({ data, pagination, setPagination, loading, route, handleDelete }) => {
    /*
    States, Params, Navigation, Query, Variables.
  */

    const loggedInUser = userService.getLoggedInUser();

    const TableHeader = [
        { id: 'id', label: 'ID', align: 'left' },
        { id: 'date', label: 'Date', align: 'left' },
        { id: 'amount', label: 'Amount', align: 'left' },
        { id: 'from_member_name', label: 'From', align: 'left' },
        { id: 'from_account', label: 'VIA', align: 'left' },
        { id: 'member_name', label: 'To', align: 'left' },
        { id: 'account', label: 'Account', align: 'left' },
    ];

    const navigate = useNavigate();
    const [selected, setSelected] = useState([]);
    const [search, setSearch] = useState(pagination.search);
    const emptyRows = pagination.page > 0 ? data.length : 0;
    const isUserNotFound = data.length === 0;

    /*
    Handlers, Functions
  */

    const handleRequestSort = (_event, property) => {
        const isDesc = pagination.sort_by == property && pagination.order == 'desc';
        setPagination({ ...pagination, sort_by: property, order: isDesc ? 'asc' : 'desc' });
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = data.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (_event, newPage) => {
        setPagination({ ...pagination, page: newPage });
    };

    const handleChangePerPage = (event) => {
        setPagination({ ...pagination, perPage: parseInt(event.target.value, 10), page: 0 });
    };

    const handleSearchInput = (event) => {
        setSearch(event.target.value);
    };

    const handleSearch = (event) => {
        if (event.keyCode == 13 && search != pagination.search) {
            setPagination({ ...pagination, search, page: 0 });
        }
    };

    const handleEdit = (ID) => {
        navigate(`${route}/${ID}/edit/`);
    };

    const handleMoreMenuCell = (event) => {
        event.preventDefault();
    };

    const MORE_MENU = [
        { text: 'Edit', icon: EditIcon, event: handleEdit, id: 0 },
        { text: 'Delete', icon: TrashIcon, event: handleDelete, id: 0 },
    ];

    /*
    Main Design.
  */
    return (
        <>
            {loading ? (
                <CenterLoading />
            ) : (
                <>
                    <Card>
                        <UserListToolbar
                            numSelected={selected.length}
                            filterName={search}
                            onFilterName={handleSearchInput}
                            handleSearch={handleSearch}
                        />
                        <Scrollbar>
                            <TableContainer sx={{ minWidth: 800 }}>
                                <Table>
                                    <UserListHead
                                        order={pagination.order}
                                        orderBy={pagination.sort_by}
                                        headLabel={TableHeader}
                                        rowCount={data.length}
                                        numSelected={selected.length}
                                        onRequestSort={handleRequestSort}
                                        onSelectAllClick={handleSelectAllClick}
                                        hideCheckBoxes={true}
                                    />
                                    <TableBody>
                                        {data.map((row) => {
                                            const {
                                                id,
                                                date,
                                                from_account,
                                                amount,
                                                member_name,
                                                from_member_name,
                                                account,
                                                details,
                                            } = row;
                                            const isItemSelected = selected.indexOf(id) !== -1;

                                            return (
                                                <Tooltip title={details}>
                                                    <TableRow
                                                        hover
                                                        key={id}
                                                        tabIndex={-1}
                                                        role="checkbox"
                                                        selected={isItemSelected}
                                                        aria-checked={isItemSelected}
                                                        style={{
                                                            backgroundColor:
                                                                palette.background.hover,
                                                            textDecoration: 'none',
                                                        }}
                                                    >
                                                        <TableCell padding="checkbox"></TableCell>
                                                        <TableCell align="left"> {id}</TableCell>
                                                        <TableCell align="left">
                                                            {getDateString(date)}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {' '}
                                                            Rs. {fNumber(amount)}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {' '}
                                                            {from_member_name}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {' '}
                                                            {from_account}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {' '}
                                                            {member_name}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {' '}
                                                            {account}
                                                        </TableCell>
                                                        {loggedInUser.rank >=
                                                            SETTINGS.RANKS.manager && (
                                                            <TableCell
                                                                align="right"
                                                                onClick={handleMoreMenuCell}
                                                            >
                                                                <UserMoreMenu
                                                                    ID={[id]}
                                                                    Options={MORE_MENU}
                                                                />
                                                            </TableCell>
                                                        )}
                                                    </TableRow>
                                                </Tooltip>
                                            );
                                        })}
                                        {emptyRows > 0 && (
                                            <TableRow style={{ emptyRows }}>
                                                <TableCell colSpan={TableHeader.length + 1} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                    {isUserNotFound && (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell
                                                    align="center"
                                                    colSpan={TableHeader.length + 1}
                                                    sx={{ py: 3 }}
                                                >
                                                    <SearchNotFound searchQuery={search} />
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )}
                                </Table>
                            </TableContainer>
                        </Scrollbar>

                        <TablePagination
                            rowsPerPageOptions={SETTINGS.rowsPerPageList}
                            component="div"
                            count={
                                data.length >= pagination.perPage
                                    ? -1
                                    : pagination.perPage * pagination.page + data.length
                            }
                            rowsPerPage={pagination.perPage}
                            page={pagination.page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangePerPage}
                        />
                    </Card>
                </>
            )}
        </>
    );
};
