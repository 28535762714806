/*
  Imports
*/
import { Container } from '@material-ui/core';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import accountingService from 'src/services/AccountingServiceClass';
import Page from '../components/Page';
import ListPageTitle from 'src/components/misc/ListPageTitle';
import DatePickerWithSearch from '../components/misc/table/DatePickerWithSearch';
import AccountsList from './tables/AccountsList';
import ProfitLostList from './tables/ProfitLostList';
import ProfitLossSummary from './body/ProfitLossSummary';
/*
  Main Working
*/
export default () => {
  /*
  States, Params, Navigation, Query, Variables.
*/
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [data, setData] = useState([{
    profit: { account: 'Total', profit: 0 },
    loss: { account: 'Total', profit: 0 },
  }]);

  const [productData, setProductData] = useState([{
    profit: { account: 'Total', profit: 0 },
    loss: { account: 'Total', profit: 0 },
  }]);

  const [date, setDate] = useState({
    startDate: null,
    endDate: null,
  });

  const title = `Profit & Loss`;

  /*
    Handlers, Functions
  */
  const getData = () => {
    setLoading(true);
    accountingService
      .profitLoss(date)
      .then((data) => {
        console.log('Data', data);
        const rows = [];
        const length = Math.max(data.profits.length, data.losses.length);

        const total = { profit: 0, loss: 0 };
        for (let i = 0; i < length; i++) {
          const row = {};
          if (data.profits.length > i) {
            row.profit = data.profits[i];
            total.profit += data.profits[i].profit;
          }
          if (data.losses.length > i) {
            row.loss = data.losses[i];
            total.loss += data.losses[i].profit;
          }

          rows.push(row);
        }

        const productLength = Math.max(data.products.profits.length, data.products.losses.length);

        const productRows = [];
        for (let i = 0; i < productLength; i++) {
          const row = {};
          if (data.products.profits.length > i) {
            const product = data.products.profits[i];
            product.account = product.product;
            row.profit = product;
          }
          if (data.products.losses.length > i) {
            const product = data.products.losses[i];
            product.account = product.product;
            row.loss = product;
          }

          productRows.push(row);
        }

        rows.push({
          profit: { account: 'Total', profit: total.profit },
          loss: { account: 'Total', profit: total.loss },
        });

        setProductData(productRows);
        setData(rows);
      })
      .catch((error) => {
        console.log('Error', error);
        // if (error.response) if (error.response.status === 401) navigate('/401');
        // navigate('/404');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /*
    Use Effect Hooks.
  */
  useEffect(getData, [date]);

  /*
  Use Effect Hooks.
*/
  /*
  Main Design
*/
  return (
    <Page title={title}>
      <Container>
        <ListPageTitle>{title}</ListPageTitle>

        <DatePickerWithSearch setDate={setDate} />
        <ProfitLossSummary
          profit={data[data.length - 1].profit.profit}
          loss={data[data.length - 1].loss.profit}
        />
        <br />
        <br />
        <ProfitLostList data={data} loading={loading} />
        <br />
        <br />
        <ProfitLostList data={productData} loading={loading} />
      </Container>
    </Page>
  );
};
