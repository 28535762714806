// material
import { TextField, Button, Grid } from '@material-ui/core';
import { useState } from 'react';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import DatePicker from '@material-ui/lab/DatePicker';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';

// ----------------------------------------------------------------------

export default ({ setDate, startDate = null }) => {
  const [dateSearch, setDateSearch] = useState({
    startDate: startDate,
    endDate: null
  });

  const handleStartDate = (event) => {
    setDateSearch({ ...dateSearch, startDate: event });
  };

  const handleEndDate = (event) => {
    setDateSearch({ ...dateSearch, endDate: event });
  };

  const onClick = () => {
    setDate(dateSearch);
  };

  return (
    <Grid container spacing={4} style={{ paddingTop: 10, paddingBottom: 50 }}>
      <Grid item xs={12} sm={12} md={3} style={{ paddingBottom: 10, paddingTop: 10 }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            inputFormat="dd/MM/yyyy"
            label="Start Date"
            value={dateSearch.startDate}
            onChange={handleStartDate}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} sm={12} md={4} style={{ paddingBottom: 10, paddingTop: 10 }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            inputFormat="dd/MM/yyyy"
            label="End Date"
            value={dateSearch.endDate}
            onChange={handleEndDate}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} sm={12} md={4} style={{ paddingBottom: 10, paddingTop: 10 }}>
        <Button size="large" variant="contained" onClick={onClick}>
          Search
        </Button>
      </Grid>
    </Grid>
  );
};
