import { Navigate, Outlet, useRoutes } from 'react-router-dom';

import DashboardLayout from './layouts/dashboard/DashboardLayout';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';

import { RouteDashboard, RouteForgetPass, RouteLandingPage, RouteResetPass } from './config/routes';

import ForgetPassword from './pages/ForgetPassword';
import Login from './pages/Login';
import Logout from './pages/Logout';
import PageError from './pages/PageError';
import Profile from './pages/Profile';
import ResetPassword from './pages/ResetPassword';
import Settings from './pages/Settings';

import Dashboard from './pages/Dashboard';

import Customers from './pages/Customers';
import RSO from './pages/RSO';
import Logs from './pages/Logs';
import Products from './pages/Products';
import Stocks from './pages/Stocks';
import SingleLogs from './pages/SingleLogs';
import SingleStock from './pages/SingleStock';
import SingleProduct from './pages/SingleProduct';
import SingleCustomer from './pages/SingleCustomer';
import SingleRsoProduct from './pages/RsoProduct';

import Expense from './pages/Expenses';
import Users from './pages/Members';
import AddMember from './pages/AddMember';
import AddStock from './pages/AddStock';
import ReturnStock from './pages/ReturnStock';
import AddProduct from './pages/AddProduct';
import GenerateBill from './pages/GenerateBill';
import PrintBill from './pages/PrintBill';
import PrintThermalBill from './pages/PrintThermalBill';
import GenerateRsoBill from './pages/GenerateRsoBill';
import ReturnBill from './pages/ReturnBill';
import ReturnRsoBill from './pages/ReturnRsoBill';
import PrintRsoBill from './pages/PrintRsoBill';
import AddPayment from './pages/AddPayment';
import AddCustomerPayment from './pages/AddCustomerPayment';
import AddExpense from './pages/AddExpense';
import AddSlip from './pages/AddSlip';
import Payments from './pages/Payments';
import PrintPayment from './pages/PrintPayment';
import AddCommission from './pages/AddCommission';
import AddSalary from './pages/AddSalary';
import Bills from './pages/Bills';
import GeneralJournal from './pages/GeneralJournal';
import Accounts from './pages/Accounts';
import { SETTINGS } from './config/settings';
import AddTransfer from './pages/AddTransfer';
import Transfers from './pages/Transfers';
import ProfitLoss from './pages/ProfitLoss';
import Services from './pages/Services';
import AddService from './pages/AddService';
import SingleService from './pages/SingleService';

export default function Router() {
    return useRoutes([
        {
            path: '/login',
            element: <LogoOnlyLayout loginPage />,
            children: [{ path: '', element: <Login teacher /> }],
        },

        /*
      Manager+ Routes
    */

        {
            path: 'users',
            element: <DashboardLayout rank={SETTINGS.RANKS.manager} />,
            children: [
                { path: 'add', element: <AddMember /> },
                { path: ':userID/edit', element: <AddMember editing={true} /> },
                { path: ':userID/add-salary', element: <AddSalary /> },
            ],
        },
        {
            path: 'salary',
            element: <DashboardLayout rank={SETTINGS.RANKS.manager} />,
            children: [{ path: ':salaryID/edit', element: <AddSalary editing={true} /> }],
        },
        {
            path: 'customers',
            element: <DashboardLayout rank={SETTINGS.RANKS.manager} />,
            children: [
                { path: '', element: <Customers /> },
                { path: 'return', element: <ReturnBill /> },
                { path: 'add', element: <AddCustomerPayment /> },
                {
                    path: ':id',
                    children: [
                        { path: '', element: <SingleCustomer /> },
                        { path: 'add', element: <AddCustomerPayment /> },
                    ],
                },
                {
                    path: 'payments',
                    children: [
                        { path: 'add', element: <AddCustomerPayment /> },
                        { path: ':paymentID/edit', element: <AddCustomerPayment editing={true} /> },
                        { path: ':paymentID/print', element: <PrintPayment /> }
                    ],
                },
            ],
        },
        {
            path: 'rso',
            element: <DashboardLayout rank={SETTINGS.RANKS.manager} />,
            children: [
                { path: '', element: <RSO /> },
                {
                    path: 'bills',
                    children: [
                        { path: 'generate', element: <GenerateRsoBill /> },
                        { path: 'return', element: <ReturnRsoBill /> },
                    ],
                },
            ],
        },
        {
            path: 'products',
            element: <DashboardLayout rank={SETTINGS.RANKS.manager} />,
            children: [
                { path: 'add', element: <AddProduct /> },
                { path: ':productID/edit', element: <AddProduct editing={true} /> },
            ],
        },
        {
            path: 'stocks',
            element: <DashboardLayout rank={SETTINGS.RANKS.manager} />,
            children: [
                { path: 'add', element: <AddStock /> },
                { path: ':stockID/return', element: <ReturnStock /> },
                { path: ':stockID/edit', element: <ReturnStock editing={true} /> },
            ],
        },
        {
            path: 'payments',
            element: <DashboardLayout rank={SETTINGS.RANKS.manager} />,
            children: [
                { path: '', element: <Payments /> },
                { path: 'add', element: <AddPayment /> },
            ],
        },
        {
            path: 'services',
            element: <DashboardLayout rank={SETTINGS.RANKS.manager} />,
            children: [
                { path: 'add', element: <AddService /> },
                { path: ':id/edit', element: <AddService editing={true} /> },
            ],
        },
        {
            path: 'slips',
            element: <DashboardLayout rank={SETTINGS.RANKS.manager} />,
            children: [{ path: 'add', element: <AddSlip /> }],
        },
        {
            path: 'commissions',
            element: <DashboardLayout rank={SETTINGS.RANKS.manager} />,
            children: [{ path: 'add', element: <AddCommission /> }],
        },
        {
            path: 'expense',
            element: <DashboardLayout rank={SETTINGS.RANKS.manager} />,
            children: [{ path: 'add', element: <AddExpense /> }],
        },
        {
            path: 'transfer',
            element: <DashboardLayout rank={SETTINGS.RANKS.manager} />,
            children: [
                { path: '', element: <Transfers /> },
                { path: 'add', element: <AddTransfer /> },
            ],
        },
        {
            path: 'generaljournal',
            element: <DashboardLayout rank={SETTINGS.RANKS.manager} />,
            children: [{ path: '', element: <GeneralJournal /> }],
        },
        {
            path: 'accounts',
            element: <DashboardLayout rank={SETTINGS.RANKS.manager} />,
            children: [{ path: '', element: <Accounts /> }],
        },
        {
            path: 'profitloss',
            element: <DashboardLayout rank={SETTINGS.RANKS.manager} />,
            children: [{ path: '', element: <ProfitLoss /> }],
        },

        /*
            Owner+ Routes
        */

        {
            path: 'expense',
            element: <DashboardLayout rank={SETTINGS.RANKS.owner} />,
            children: [{ path: ':expenseID/edit', element: <AddExpense editing={true} /> }],
        },
        {
            path: 'transfer',
            element: <DashboardLayout rank={SETTINGS.RANKS.owner} />,
            children: [{ path: ':transferID/edit', element: <AddTransfer editing={true} /> }],
        },
        {
            path: 'commissions',
            element: <DashboardLayout rank={SETTINGS.RANKS.owner} />,
            children: [
                { path: ':commissionID/edit', element: <AddCommission editing={true} /> },
            ],
        },
        {
            path: 'slips',
            element: <DashboardLayout rank={SETTINGS.RANKS.owner} />,
            children: [
                { path: ':slipID/edit', element: <AddSlip editing={true} /> },
            ],
        },
        {
            path: 'payments',
            element: <DashboardLayout rank={SETTINGS.RANKS.owner} />,
            children: [
                { path: ':paymentID/edit', element: <AddPayment editing={true} /> },
            ],
        },

        {
            path: 'bills/:billID/print',
            element: <PrintBill />,
        },
        {
            path: 'bills/rso/:billID/print',
            element: <PrintRsoBill />,
        },
        {
            path: 'bills/:billID/print-thermal',
            element: <PrintThermalBill />,
        },
        {
            path: 'bills/rso/:billID/print-thermal',
            element: <PrintThermalBill rso={true} />,
        },
        {
            path: 'payments/:paymentID/print',
            element: <PrintPayment payment />,
        },
        {
            path: 'slips/:paymentID/print',
            element: <PrintPayment slip />,
        },
        {
            path: 'commissions/:paymentID/print',
            element: <PrintPayment commission />,
        },
        {
            path: 'expense/:paymentID/print',
            element: <PrintPayment expense />,
        },

        {
            path: '/',
            element: <DashboardLayout />,
            children: [
                { path: '', element: <Navigate to={RouteDashboard} /> },
                { path: 'dashboard', element: <Dashboard /> },
                { path: 'profile', element: <Profile /> },
                { path: 'settings', element: <Settings /> },

                {
                    path: 'logs',
                    element: <Outlet />,
                    children: [
                        { path: '', element: <Logs /> },
                        { path: ':id', element: <SingleLogs /> },
                    ],
                },
                {
                    path: 'products',
                    element: <Outlet />,
                    children: [
                        { path: '', element: <Products /> },
                        { path: ':id', element: <SingleProduct /> },
                    ],
                },
                {
                    path: 'services',
                    element: <Outlet />,
                    children: [
                        { path: '', element: <Services /> },
                        { path: ':id', element: <SingleService /> },
                    ],
                },
                {
                    path: 'stocks',
                    element: <Outlet />,
                    children: [
                        { path: '', element: <Stocks /> },
                        { path: ':id', element: <SingleStock /> },
                    ],
                },
                {
                    path: 'expense',
                    element: <Outlet />,
                    children: [{ path: '', element: <Expense /> }],
                },
                {
                    path: 'bills',
                    element: <Outlet />,
                    children: [
                        { path: '', element: <Bills /> },
                        { path: 'generate', element: <GenerateBill /> },
                    ],
                },
                {
                    path: 'users',
                    element: <Outlet />,
                    children: [
                        { path: '', element: <Users />, },
                        {
                            path: ':id', element: <Outlet />,
                            children: [
                                { path: '', element: <Profile /> },
                                { path: ':user/:product', element: <SingleRsoProduct /> },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            path: RouteLandingPage,
            element: <Navigate to={RouteDashboard} replace />,
        },
        {
            path: RouteLandingPage,
            element: <LogoOnlyLayout />,
            children: [
                { path: 'logout', element: <Logout /> },
                { path: '404', element: <PageError e404 /> },
                { path: '401', element: <PageError /> },
                // { path: '*', element: <Navigate to="/404" /> },
                { path: RouteForgetPass, element: <ForgetPassword /> },
                { path: RouteResetPass, element: <ResetPassword /> },
            ],
        },

        //{ path: '*', element: <Navigate to="/404" replace /> }
    ]);
}
