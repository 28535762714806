/*
    Imports
*/
import { Form, FormikProvider, useFormik } from 'formik';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

/*
    Imports:
        Material UI
*/
import { Grid, InputLabel, TextField, Typography, Select, MenuItem, Box } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { ThemeProvider } from '@material-ui/core/styles';
import Autocomplete from '@mui/material/Autocomplete';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import DatePicker from '@material-ui/lab/DatePicker';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';

/*
    Imports:
        Our Imports
        Components and Settings
        Services
*/
import Dialog from 'src/components/misc/alerts/Dialog';
import ServerError from 'src/components/misc/alerts/ServerError';
import LoadingFormButton from 'src/components/misc/Buttons/LoadingFormButton';
import { AddExpenseSlipSchema, AddPaymentSchema } from 'src/config/form-schemas';
import { ContentStyle, FormTheme } from '../../theme/form-pages';
import paymentsService from 'src/services/PaymentsServiceClass';
import CenterLoading from 'src/components/misc/CenterLoading';

/*
    Main Working
*/
export default ({ editing, customers }) => {
    /*
        States, Params, Navigation, Query, Variables.
    */

    const paymentID = useParams().paymentID;
    const customerNo = useParams().id;

    const currentCustomer = customerNo
        ? customers.find((c) => c.number == customerNo)
        : customers[0];

    const [serverError, setServerError] = useState('');
    const [openDia, setOpenDia] = useState(false);
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState(currentCustomer);
    const [inputValue, setInputValue] = useState(currentCustomer.username);

    const navigate = useNavigate();

    /*
        Form Setup
    */
    const formik = useFormik({
        initialValues: {
            details: '',
            amount: 0,
            date: new Date(),
            customer: currentCustomer,
        },
        validationSchema: AddExpenseSlipSchema,
        onSubmit: (_values, { setFieldError }) => {
            addData();
        },
    });

    const {
        values,
        errors,
        touched,
        isSubmitting,
        handleSubmit,
        getFieldProps,
        setFieldValue,
        setSubmitting,
        setFieldError,
    } = formik;

    /*
        Handlers
    */
    const addData = () => {
        values.customer = values.customer.number;
        console.log(values);
        setSubmitting(true);
        let FuncToCall = paymentsService.addC;
        if (editing) FuncToCall = paymentsService.updateC;
        FuncToCall({ ...values }, editing ? customerNo : values.number, paymentID)
            .then(() => {
                setOpenDia(true);
            })
            .catch((err) => {
                console.log('Error', err, err.response);
                if (err.response) {
                    for (const key in values) {
                        if (Object.hasOwnProperty.call(values, key)) {
                            if (err.response.data.message.includes(key))
                                setFieldError(key, 'Invalid or Already Added');
                        }
                    }
                }

                console.log('Error', err, err.response);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const handleClose = () => {
        setOpenDia(false);
        navigate('../');
    };

    const handleEditing = () => {
        if (editing) {
            setLoading(true)
            paymentsService
                .getOneC(customerNo, paymentID)
                .then((data) => {
                    setFieldValue('amount', data.amount);
                    setFieldValue('details', data.details);
                    setFieldValue(
                        'customer',
                        customers.find((a) => a.number == customerNo),
                    );
                    setValue(customers.find((a) => a.number == customerNo));
                    setInputValue(customers.find((a) => a.number == customerNo).number);
                })
                .catch((_err) => console.log(_err.response))
                .finally(() => setLoading(false))
        }
    };

    /*
        Use Effect Hooks.
    */

    useEffect(handleEditing, []);

    /*
        Main Design
    */
    return (
        <>
            {loading &&
                <CenterLoading></CenterLoading>
            }
            {!loading &&
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        {/* <Typography variant="h6" gutterBottom>
                    Product Details
                </Typography> */}
                        <ContentStyle>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6} md={6}>
                                    <ThemeProvider theme={FormTheme}>
                                        <InputLabel label="date">Date</InputLabel>
                                    </ThemeProvider>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            value={values.date}
                                            onChange={(newValue) => {
                                                setFieldValue('date', newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    fullWidth
                                                    {...params}
                                                    {...getFieldProps('date')}
                                                    error={Boolean(touched.date && errors.date)}
                                                    helperText={touched.date && errors.date}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>
                        </ContentStyle>
                        <ContentStyle>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6} md={6}>
                                    <ThemeProvider theme={FormTheme}>
                                        <InputLabel>Customer</InputLabel>
                                    </ThemeProvider>
                                    <Autocomplete
                                        disableClearable={true}
                                        options={customers}
                                        defaultValue={customers[0]}
                                        value={value}
                                        onChange={(event, newValue) => {
                                            setValue(newValue);
                                            console.log(newValue);
                                            values.customer = newValue;
                                        }}
                                        inputValue={inputValue}
                                        onInputChange={(event, newInputValue) => {
                                            setInputValue(newInputValue);
                                        }}
                                        disabled={editing}
                                        getOptionLabel={(option) => option.name}
                                        renderOption={(props, option) => (
                                            <Box
                                                component="li"
                                                sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                                {...props}
                                            >
                                                {option.name} + {option.number}
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                {...getFieldProps('customer')}
                                                disabled={editing}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </ContentStyle>

                        <ContentStyle>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6} md={6}>
                                    <ThemeProvider theme={FormTheme}>
                                        <InputLabel>Amount</InputLabel>
                                    </ThemeProvider>
                                    <TextField
                                        fullWidth
                                        customInput={TextField}
                                        autoComplete="number"
                                        type="text"
                                        {...getFieldProps('amount')}
                                        inputProps={{
                                            inputMode: 'numeric',
                                        }}
                                        error={Boolean(touched.amount && errors.amount)}
                                        helperText={touched.amount && errors.amount}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <ThemeProvider theme={FormTheme}>
                                        <InputLabel>Details</InputLabel>
                                    </ThemeProvider>
                                    <TextField
                                        fullWidth
                                        {...getFieldProps('details')}
                                        error={Boolean(touched.details && errors.details)}
                                        helperText={touched.details && errors.details}
                                    />
                                </Grid>
                            </Grid>
                        </ContentStyle>

                        <Dialog buttonText={'Close'} openDialog={openDia} handleButton={handleClose}>
                            {editing ? `Payment is updated` : `Payment is added`}
                        </Dialog>

                        <LoadingFormButton loading={isSubmitting}>
                            {editing ? 'Edit' : 'Add'}
                        </LoadingFormButton>
                        <ServerError open={serverError}>{serverError}</ServerError>
                    </Form>
                </FormikProvider>
            }
        </>
    );
};
