import GenericService from './GenericService';

class ServiceClass extends GenericService {
    constructor() {
        super();
    }

    account = () => this.get('/dashboard/account');

    cash = () => this.get('/dashboard/cash');

    paymentSummary = () => this.get('/dashboard/payments');

    monthlyStats = () => this.get(`/dashboard/monthly`);
}

const dashboardService = new ServiceClass();

export default dashboardService;
