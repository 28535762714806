import GenericService from './GenericService';
import qs from 'qs';

class ServiceClass extends GenericService {
  constructor() {
    super();
  }

  getAll = (pagination) => this.get(`/logging?${qs.stringify(pagination)}`);

  getOne = (ID) => this.get(`/logging/${ID}`);
}

const logsService = new ServiceClass();

export default logsService;
