import GenericService from './GenericService';
import qs from 'qs';

class ServiceClass extends GenericService {
    constructor() {
        super();
    }

    getAll = () => this.get(`/rso`);
    getOne = (ID) => this.get(`/rso/${ID}`);
    getRsoProducts = (ID) => this.get(`/products/rso/${ID}`);
    returnRsoBill = (data) => this.post(`/bills/rso/return`, data);
    getRsoBill = (ID) => this.get(`bills/rso/bill/${ID}`);

    getRSOBills = (pagination) => this.get(`/bills/rso?${qs.stringify(pagination)}`);

    getRSOSingleBill = (ID, pagination, date) =>
        this.get(`/bills/rso/${ID}?${qs.stringify(pagination)}&${qs.stringify(date)}`);

    getCSRSingleBill = (ID, pagination, date) =>
        this.get(`/bills/csr/${ID}?${qs.stringify(pagination)}&${qs.stringify(date)}`);

    remove = (ID) => this.delete(`/bills/rso/${ID}`);
}

const rsoService = new ServiceClass();

export default rsoService;
