/*
  Imports
*/
import { Grid, Typography } from '@material-ui/core';
import userService from 'src/services/UserService';
import { SETTINGS } from 'src/config/settings';
import { fNumber } from 'src/utils/formatNumber';

/*
  Main Working
*/
export default ({ data }) => {
    /*
    States, Params, Navigation, Query, Variables.
  */

    const loggedInUser = userService.getLoggedInUser();

    const { name, price, tax, sold, sold_for } = data;

    /*
    Main Design
  */
    return (
        <>
            <Grid container spacing={0} style={{ paddingBottom: 10, paddingTop: 20 }}>
                <Grid item xs={12} sm={2} md={2}>
                    <Typography fontWeight="bold">Name: </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <Typography>{name}</Typography>
                </Grid>

                <Grid item xs={12} sm={2} md={2}>
                    <Typography fontWeight="bold">Price: </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <Typography>{price > 1 ? `Rs. ${fNumber(price)}` : `Rs. ${price}`}</Typography>
                </Grid>

                <Grid item xs={12} sm={2} md={2}>
                    <Typography fontWeight="bold">Tax: </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <Typography>{tax > 1 ? `Rs. ${fNumber(tax)}` : `Rs. ${tax}`}</Typography>
                </Grid>

                <Grid item xs={12} sm={2} md={2}>
                    <Typography fontWeight="bold">Sold: </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <Typography>
                        {sold}{' '}
                        {loggedInUser.rank >= SETTINGS.RANKS.manager &&
                            `(Rs. ${fNumber(sold_for)})`}
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
};
