/*
    Imports
*/
import { Form, FormikProvider, useFormik } from 'formik';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

/*
    Imports:
        Material UI
*/
import { Grid, InputLabel, TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { ThemeProvider } from '@material-ui/core/styles';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import DatePicker from '@material-ui/lab/DatePicker';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';

/*
    Imports:
        Our Imports
        Components and Settings
        Services
*/
import Dialog from 'src/components/misc/alerts/Dialog';
import ServerError from 'src/components/misc/alerts/ServerError';
import LoadingFormButton from 'src/components/misc/Buttons/LoadingFormButton';
import { AddExpenseSlipSchema, AddPaymentSchema } from 'src/config/form-schemas';
import { RoutePayments, RouteProducts } from 'src/config/routes';
import { ContentStyle, FormTheme } from '../../theme/form-pages';
import slipsService from 'src/services/SlipsServiceClass';
import CenterLoading from 'src/components/misc/CenterLoading';

/*
    Main Working
*/
export default ({ editing }) => {
    /*
        States, Params, Navigation, Query, Variables.
    */
    const [serverError, setServerError] = useState('');
    const [openDia, setOpenDia] = useState(false);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const slipID = useParams().slipID;

    /*
        Form Setup
    */
    const formik = useFormik({
        initialValues: {
            details: '',
            amount: 0,
            date: new Date(),
        },
        validationSchema: AddExpenseSlipSchema,
        onSubmit: (_values, { setFieldError }) => {
            addData();
        },
    });

    const {
        values,
        errors,
        touched,
        isSubmitting,
        handleSubmit,
        getFieldProps,
        setFieldValue,
        setSubmitting,
        resetForm,
        initialValues,
        setFieldError,
    } = formik;

    /*
        Handlers
    */
    const addData = () => {
        console.log(values);
        setSubmitting(true);
        let FuncToCall = slipsService.add;
        if (editing) FuncToCall = slipsService.update;
        FuncToCall({ ...values, amount: +values.amount }, slipID)
            .then(() => {
                setOpenDia(true);
            })
            .catch((err) => {
                console.log('Error', err, err.response);
                if (err.response) {
                    for (const key in values) {
                        if (Object.hasOwnProperty.call(values, key)) {
                            if (err.response.data.message.includes(key))
                                setFieldError(key, 'Invalid or Already Added');
                        }
                    }
                }

                console.log('Error', err, err.response);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const handleClose = () => {
        setOpenDia(false);
        navigate(RoutePayments);
    };

    const handleEditing = () => {
        if (editing) {
            setLoading(true);
            slipsService
                .getOne(slipID)
                .then((data) => {
                    setFieldValue('amount', data.amount);
                    setFieldValue('details', data.details);
                    setFieldValue('date', data.date);
                })
                .catch((_err) => console.log(_err.response))
                .finally(() => setLoading(false));
        }
    };

    /*
        Use Effect Hooks.
    */

    useEffect(handleEditing, []);

    /*
        Main Design
    */
    return (
        <>
            {loading && <CenterLoading></CenterLoading>}
            {!loading && (
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <ContentStyle>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6} md={6}>
                                    <ThemeProvider theme={FormTheme}>
                                        <InputLabel label="date">Date</InputLabel>
                                    </ThemeProvider>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            value={values.date}
                                            onChange={(newValue) => {
                                                setFieldValue('date', newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    fullWidth
                                                    {...params}
                                                    {...getFieldProps('date')}
                                                    error={Boolean(touched.date && errors.date)}
                                                    helperText={touched.date && errors.date}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>
                        </ContentStyle>{' '}
                        <ContentStyle>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6} md={6}>
                                    <ThemeProvider theme={FormTheme}>
                                        <InputLabel>Amount</InputLabel>
                                    </ThemeProvider>
                                    <NumberFormat
                                        fullWidth
                                        customInput={TextField}
                                        autoComplete="number"
                                        type="text"
                                        {...getFieldProps('amount')}
                                        inputProps={{
                                            inputMode: 'numeric',
                                        }}
                                        error={Boolean(touched.amount && errors.amount)}
                                        helperText={touched.amount && errors.amount}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <ThemeProvider theme={FormTheme}>
                                        <InputLabel>Details</InputLabel>
                                    </ThemeProvider>
                                    <TextField
                                        fullWidth
                                        {...getFieldProps('details')}
                                        error={Boolean(touched.details && errors.details)}
                                        helperText={touched.details && errors.details}
                                    />
                                </Grid>
                            </Grid>
                        </ContentStyle>
                        <Dialog
                            buttonText={'Close'}
                            openDialog={openDia}
                            handleButton={handleClose}
                        >
                            {editing ? `Slip is updated` : `Slip is added`}
                        </Dialog>
                        <LoadingFormButton loading={isSubmitting}>
                            {editing ? 'Edit' : 'Add'}
                        </LoadingFormButton>
                        <ServerError open={serverError}>{serverError}</ServerError>
                    </Form>
                </FormikProvider>
            )}
        </>
    );
};
