/*
  Imports
*/
import { Container } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FloatingAdd from 'src/components/misc/Buttons/FloatingButton';
import CenterLoading from 'src/components/misc/CenterLoading';
import ListPageTitle from 'src/components/misc/ListPageTitle';
import { TransferIcon } from 'src/config/icons';
import { SETTINGS } from 'src/config/settings';
import transferService from 'src/services/TransferServiceClass';
import userService from 'src/services/UserService';
import DatePickerWithSearch from '../components/misc/table/DatePickerWithSearch';
import Page from '../components/Page';
import Transfer from './tables/TransfersList';
/*
  Main Working
*/
export default () => {
    /*
    States, Params, Navigation, Query, Variables.
  */
    const navigate = useNavigate();

    const loggedInUser = userService.getLoggedInUser();

    const [loading, setLoading] = useState(true);

    const [pageLoading, setPageLoading] = useState(true);

    const [data, setData] = useState([]);

    const [pagination, setPagination] = useState({
        search: '',
        perPage: SETTINGS.defaultPerPage,
        page: 0,
        order: 'asc',
        sort_by: 'id',
    });

    const [date, setDate] = useState({
        startDate: null,
        endDate: null,
    });

    const title = 'Transfers';

    /*
      Handlers, Functions
    */
    const getData = () => {
        setLoading(true);
        transferService
            .getAll(pagination, date)
            .then((res) => {
                console.log('Transfer List', res);
                setData(res);
            })
            .catch((error) => {
                if (error.response) if (error.response.status === 401) navigate('/401');
                navigate('/404');
            })
            .finally(() => {
                setLoading(false);
                setPageLoading(false);
            });
    };

    /*
          Use Effect Hooks.
        */

    useEffect(getData, [pagination, date]);

    const handleAdd = () => {
        navigate('./add');
    };

    const handleDelete = (ID) => {
        transferService
            .remove(ID)
            .then(() => {
                getData();
            })
            .catch((err) => console.log(err));
    };

    /*
      Use Effect Hooks.
    */

    /*
    Handlers, Functions
  */

    /*
    Main Design
  */
    return (
        <Page title={title}>
            <Container>
                {pageLoading ? (
                    <CenterLoading />
                ) : (
                    <>
                        <DatePickerWithSearch setDate={setDate} startDate={new Date().setDate(1)} />
                        <ListPageTitle>{title}</ListPageTitle>
                        <Transfer
                            data={data}
                            pagination={pagination}
                            setPagination={setPagination}
                            loading={loading}
                            route={'/transfer'}
                            handleDelete={handleDelete}
                        />
                        {loggedInUser.rank >= SETTINGS.RANKS.manager && (
                            <FloatingAdd
                                tooltip="add transfer"
                                onClick={handleAdd}
                                bottomSpacing={(theme) => theme.spacing(2)}
                                icon={TransferIcon}
                            />
                        )}
                    </>
                )}
            </Container>
        </Page>
    );
};
