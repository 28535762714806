/*
    Imports
*/
import { FieldArray, Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useNavigate } from 'react-router-dom';

/*
    Imports:
        Material UI
*/
import { Box, FormHelperText, Grid, InputLabel, TextField, Typography } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import Autocomplete from '@mui/material/Autocomplete';

/*
    Imports:
        Our Imports
        Components and Settings
        Services
*/
import Dialog from 'src/components/misc/alerts/Dialog';
import ServerError from 'src/components/misc/alerts/ServerError';
import LoadingFormButton from 'src/components/misc/Buttons/LoadingFormButton';
import { ReturnRsoBillSchema } from 'src/config/form-schemas';
import { RoutePayments } from 'src/config/routes';
import rsoService from 'src/services/RsoServiceClass';
import userService from 'src/services/UserService';
import { ContentStyle, FormTheme } from '../../theme/form-pages';
import { fNumber } from 'src/utils/formatNumber';

/*
    Main Working
*/
export default ({ products, rsos }) => {
    /*
        States, Params, Navigation, Query, Variables.
    */

    const [serverError, setServerError] = useState('');
    const [openDia, setOpenDia] = useState(false);
    const [billID, setBillId] = useState(0);
    const [value, setValue] = useState(rsos[0]);
    const [inputValue, setInputValue] = useState(rsos[0]?.username);

    const navigate = useNavigate();

    /*
        Form Setup
    */

    const formik = useFormik({
        initialValues: {
            rso: rsos[0],
            grandTotal: 0,
            items: [
                {
                    product: products[0].name,
                    price: products[0].price,
                    quantity: 0,
                    left: 0,
                    oprice: 0,
                    total: 0,
                },
            ],
        },
        validationSchema: ReturnRsoBillSchema,
        onSubmit: (_values, { setFieldError }) => {
            addData();
        },
    });

    const {
        values,
        errors,
        touched,
        isSubmitting,
        handleSubmit,
        getFieldProps,
        setFieldValue,
        setSubmitting,
        resetForm,
        initialValues,
        setFieldError,
        handleBlur,
    } = formik;

    /*
        Handlers
    */
    const addData = () => {
        setSubmitting(true);

        let valuess = { ...values };
        delete valuess.name;
        delete valuess.number;
        delete valuess.email;
        delete valuess.address;
        delete valuess.grandTotal;
        valuess.rso = values.rso.id;
        const newitems = valuess.items.map((item) => {
            item.oprice = products.find((prod) => prod.name == item.product).price;
            item.product = products.find((prod) => prod.name == item.product).id;
            item.price = +item.price;
            item.quantity = +item.quantity;
        });

        valuess.items.forEach((o) => {
            delete o.left;
            delete o.total;
        });

        rsoService
            .returnRsoBill(valuess)
            .then((response) => {
                setBillId(response.id);
                setOpenDia(true);
            })
            .catch((err) => {
                console.log('Error', err, err.response);
                if (err.response) {
                    for (const key in values) {
                        if (Object.hasOwnProperty.call(values, key)) {
                            if (err.response.data.message.includes(key))
                                setFieldError(key, 'Invalid or Already Added');
                        }
                    }
                }
                console.log('Error', err, err.response);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const handleClose = () => {
        setOpenDia(false);
        navigate(RoutePayments);
    };
    const handlePrintE_Bill = () => {
        setOpenDia(false);
        navigate(`/bills/rso/${billID}/print`);
    };
    const handlePrintBill = () => {
        setOpenDia(false);
        navigate(`/bills/rso/${billID}/print-thermal`);
    };

    const showProductsList = (productsList) => {
        setServerError('');
        const u = [];
        productsList.map((a) => {
            let current_product = products.find((prod) => prod.name == a.product);
            let price = current_product.price;
            if (current_product.type == 'Percentage') {
                price = current_product.price / (1 + current_product.sale_p / 100);
            }
            if (a.quantity > 0)
                u.push({
                    product: a.product,
                    price:
                        price,
                    oprice:
                        a.total / a.quantity > 1
                            ? Math.floor(a.total / a.quantity)
                            : (a.total / a.quantity).toFixed(3),
                    quantity: 0,
                    left: a.quantity,
                    total: a.total,
                });
            setFieldValue('items', u);
        });
        if (productsList.length < 1) {
            setFieldValue('items', []);
            setServerError('No products are sold to ' + values.rso.username);
        }
    };

    const handleRsoChange = () => {
        setServerError('');
        console.log(values.rso);
        userService
            .getUser(values.rso?.username)
            .then((rsoData) => {
                fillData(rsoData);
            })
            .catch(() => {
                console.log('Rso Not Found');
            });

        rsoService
            .getRsoProducts(values.rso?.id)
            .then((productsData) => {
                console.log(productsData);
                showProductsList(productsData);
            })
            .catch((err) => {
                setServerError('No products are sold to ' + values.rso.username);
                console.log(err, 'Rso products not found');
            });
    };

    function fillData(rsoDetails) {
        setFieldValue('name', rsoDetails.name);
        setFieldValue('email', rsoDetails.email);
        setFieldValue('address', rsoDetails.address);
        console.log(rsoDetails);
    }

    const handleTotal = () => {
        values.grandTotal = values.items.reduce((total, item) => (total += item.total), 0);
    };

    useEffect(handleRsoChange, []);
    useEffect(handleRsoChange, [values.rso]);
    useEffect(handleTotal, [values]);

    /*
        Main Design
    */

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Typography variant="h6" gutterBottom>
                    RSO Details
                </Typography>

                <ContentStyle>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={6}>
                            <ThemeProvider theme={FormTheme}>
                                <InputLabel>RSO</InputLabel>
                            </ThemeProvider>
                            <Autocomplete
                                disableClearable={true}
                                options={rsos}
                                defaultValue={rsos[0]}
                                value={value}
                                onChange={(event, newValue) => {
                                    setValue(newValue);
                                    values.rso = newValue;
                                }}
                                inputValue={inputValue}
                                onInputChange={(event, newInputValue) => {
                                    setInputValue(newInputValue);
                                }}
                                getOptionLabel={(option) => option.username}
                                renderOption={(props, option) => (
                                    <Box
                                        component="li"
                                        sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                        {...props}
                                    >
                                        {option.name} + {option.username}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} {...getFieldProps('rso')} />
                                )}
                            />
                            <FormHelperText error>{errors ? errors.rso : ''}</FormHelperText>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                            <ThemeProvider theme={FormTheme}>
                                <InputLabel>Name</InputLabel>
                            </ThemeProvider>
                            <TextField
                                fullWidth
                                {...getFieldProps('name')}
                                disabled={true}
                                error={Boolean(touched.name && errors.name)}
                                helperText={touched.name && errors.name}
                            />
                        </Grid>
                    </Grid>
                </ContentStyle>

                <ContentStyle>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={6}>
                            <ThemeProvider theme={FormTheme}>
                                <InputLabel>Email</InputLabel>
                            </ThemeProvider>
                            <TextField
                                fullWidth
                                autoComplete="email"
                                {...getFieldProps('email')}
                                inputProps={{
                                    inputMode: 'email',
                                }}
                                disabled={true}
                                error={Boolean(touched.email && errors.email)}
                                helperText={touched.email && errors.email}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                            <ThemeProvider theme={FormTheme}>
                                <InputLabel label="Address">Address</InputLabel>
                            </ThemeProvider>
                            <TextField
                                fullWidth
                                autoComplete="address"
                                {...getFieldProps('address')}
                                disabled={true}
                                error={Boolean(touched.address && errors.address)}
                                helperText={touched.address && errors.address}
                            />
                        </Grid>
                    </Grid>
                </ContentStyle>
                <Typography variant="h6" gutterBottom>
                    Bill Details
                </Typography>
                <FieldArray name="items">
                    <>
                        {values.items.map((product, index) => (
                            <ContentStyle>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <ThemeProvider theme={FormTheme}>
                                            <InputLabel>Product</InputLabel>
                                        </ThemeProvider>
                                        <TextField
                                            disabled={true}
                                            name={`items.${index}.product`}
                                            fullWidth
                                            autoComplete="email"
                                            {...getFieldProps(`items.${index}.product`)}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={2} md={2}>
                                        <ThemeProvider theme={FormTheme}>
                                            <InputLabel>Price</InputLabel>
                                        </ThemeProvider>
                                        <NumberFormat
                                            name={`items.${index}.price`}
                                            fullWidth
                                            customInput={TextField}
                                            type="text"
                                            allowEmptyFormatting="true"
                                            inputProps={{
                                                inputMode: 'numeric',
                                            }}
                                            {...getFieldProps(`items.${index}.price`)}
                                        />
                                        <FormHelperText>
                                            Price sold: {values.items[index].oprice}
                                        </FormHelperText>
                                        <FormHelperText error={true}>
                                            {errors?.items?.length > 0 &&
                                                errors.items[index]?.price}
                                        </FormHelperText>
                                    </Grid>

                                    <Grid item xs={12} sm={2} md={2}>
                                        <ThemeProvider theme={FormTheme}>
                                            <InputLabel>Quantity</InputLabel>
                                        </ThemeProvider>
                                        <NumberFormat
                                            fullWidth
                                            customInput={TextField}
                                            type="text"
                                            allowEmptyFormatting="true"
                                            inputProps={{
                                                inputMode: 'numeric',
                                            }}
                                            {...getFieldProps(`items.${index}.quantity`)}
                                        />
                                        <FormHelperText>
                                            Total quantity: {fNumber(values.items[index].left)}
                                        </FormHelperText>
                                        <FormHelperText error={true}>
                                            {errors?.items?.length > 0 && errors.items[index]?.left}
                                        </FormHelperText>
                                    </Grid>

                                    <Grid item xs={12} sm={2} md={2}>
                                        <ThemeProvider theme={FormTheme}>
                                            <InputLabel>Total Rs.</InputLabel>
                                        </ThemeProvider>
                                        <TextField
                                            value={fNumber(
                                                (values.items[index].total = Math.round(
                                                    +values.items[index].quantity *
                                                    +values.items[index].price,
                                                )),
                                            )}
                                            disabled={true}
                                        />
                                    </Grid>
                                </Grid>
                            </ContentStyle>
                        ))}
                    </>
                </FieldArray>

                <ContentStyle>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={6}>
                            <ThemeProvider theme={FormTheme}>
                                <InputLabel label="grandTotal">
                                    Total Amount {fNumber(Math.round(values.grandTotal.toFixed(3)))}
                                </InputLabel>
                            </ThemeProvider>
                        </Grid>
                    </Grid>
                </ContentStyle>

                <Dialog
                    buttonText={'Close'}
                    buttonText2={'eBill'}
                    buttonText3={'Bill'}
                    openDialog={openDia}
                    handleButton={handleClose}
                    handleButton2={handlePrintE_Bill}
                    handleButton3={handlePrintBill}
                >
                    {`Bill is Returned`}
                </Dialog>

                <LoadingFormButton disabled={serverError} loading={isSubmitting}>
                    {'Return'}
                </LoadingFormButton>
                <ServerError open={serverError}>{serverError}</ServerError>
            </Form>
        </FormikProvider>
    );
};
