/*
  Imports
*/
import { Grid, Typography } from '@material-ui/core';
import ProfileAvatar from 'src/components/misc/ProfileAvatar';
import { SETTINGS } from 'src/config/settings';
import { getImage } from 'src/utils/misc';

/*
  Main Working
*/
export default ({ user }) => {
  /*
    States, Params, Navigation, Query, Variables.
  */
  const { image, name, username, email, number, address, cnic } = user;

  /*
    Main Design
  */
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ProfileAvatar Image={getImage(image) || SETTINGS.DefaultAvatar} />
        </Grid>
      </Grid>
      <Grid container spacing={0} style={{ paddingBottom: 20, paddingTop: 20 }}>
        <Grid item xs={12} sm={2} md={2}>
          <Typography fontWeight="bold">Name: </Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <Typography>{name}</Typography>
        </Grid>
        <Grid item xs={12} sm={2} md={2}>
          <Typography fontWeight="bold">ID: </Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <Typography>{username}</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={0} style={{ paddingBottom: 10, paddingTop: 10 }}>
        <Grid item xs={12} sm={2} md={2}>
          <Typography fontWeight="bold">Email: </Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <Typography>{email}</Typography>
        </Grid>
        <Grid item xs={12} sm={2} md={2}>
          <Typography fontWeight="bold">CNIC: </Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <Typography>{cnic}</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={0} style={{ paddingBottom: 10, paddingTop: 10 }}>
        <Grid item xs={12} sm={2} md={2}>
          <Typography fontWeight="bold">Cell #: </Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <Typography>{number}</Typography>
        </Grid>
        <Grid item xs={12} sm={2} md={2}>
          <Typography fontWeight="bold">Address: </Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <Typography>{address}</Typography>
        </Grid>
      </Grid>
    </>
  );
};
