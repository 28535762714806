/*
    Imports
*/
import { Container } from '@material-ui/core';
import { useParams, useNavigate } from 'react-router-dom';
import Page from 'src/components/Page';
import { useEffect, useState } from 'react';
import billService from 'src/services/BillServiceClass';
import { fNumber, toWords } from 'src/utils/formatNumber';
import Logo from '../components/Logo';
import CenterLoading from 'src/components/misc/CenterLoading';
import { SETTINGS } from 'src/config/settings';



/*
    Main Working
*/
export default () => {

    const billID = useParams().billID;
    const navigate = useNavigate();

    const [details, setDetails] = useState()

    const getData = () => {
        billService.getBill(billID)
            .then((res) => {
                if (res?.services?.length < 1) res.services = null;
                setDetails(res);
            })
            .catch((error) => {
                if (error.response) if (error.response.status === 401) navigate('/401');
                navigate('/404');
            })
            .finally(() => {
            });
    };

    const loading = !details;

    const B = (props) => <text style={{ fontWeight: 'bold' }}>{props.children}</text>

    useEffect(getData, []);

    /*
      Main Design
    */



    return (
        <Page title="Print Bill" >
            {loading ?
                <Container style={{ height: '100vh' }}>
                    <CenterLoading />
                </Container>
                :

                <Container maxWidth="xl">
                    {/* <ListPageTitle>
                    {"Printing Bill"}
                </ListPageTitle> */}
                    {/* <AddProductForm editing={editing} /> */}
                    <style>{`
                    table{
                        border:1px solid black;
                        width: 100%;
                        border-collapse: collapse;
                        margin-top: 30px;
                    }
                  
                    table th {
                        border:1px solid black;
                    }
                    table tr {
                        border:1px solid black;
                    }
                    table td {
                        border:1px solid black;
                    }
                    .details tr{
                        border:1px solid transparent;
                    }
                    .details td{
                        border:1px solid transparent;
                        width: 70%;
                    }  
                    .details th{
                        border:1px solid transparent;
                    } 
                    .padding{
                        padding: 10px
                    }
                    .paddingS td, th{
                        padding: 5px
                    }
                `}</style>

                    <div>
                        <div style={{ marginTop: 50 }}>
                            <Logo />
                        </div>
                        <table className='details'>
                            <tbody>
                                <tr >
                                    <td ><B>Bill ID:</B> {details?.id}</td>
                                    <td ><B>Billed By:</B> {details?.billed_by}</td>
                                </tr>
                                <tr>
                                    <td ><B>Date: </B> {details?.date.slice(0, 10)}</td>
                                    <td></td>
                                </tr>

                            </tbody>
                        </table>

                        <table className='details'>

                            <tbody>
                                <tr >
                                    <td ><B>Name: </B> {details?.name}</td>
                                    <td ><B>Number: </B> {details?.customer}</td>
                                </tr>
                                <tr >
                                    <td ><B>Email: </B> {details?.email}</td>
                                    <td ><B>Address:</B> {details?.address}</td>
                                </tr>

                            </tbody>
                        </table>

                        <table >
                            <tbody>

                                <tr className='paddingS'>
                                    <th>Sr. </th>
                                    <th >Product</th>
                                    <th >Price</th>
                                    <th >Quantity</th>
                                    <th >Discount</th>
                                    <th >Tax</th>
                                    <th >Total</th>
                                </tr>
                                {(details?.services ?? details?.items).map((a, index) => {
                                    console.log(a, index)
                                    return (
                                        <tr className='paddingS'>
                                            <td >{index + 1}</td>
                                            <td>{a.product}</td>
                                            <td>Rs. {a.price > 1 ? fNumber(+a.oprice) : a.oprice}</td>
                                            <td>{a.quantity}</td>
                                            <td>Rs. {a.discount > 1 ? fNumber(+a.discount) : a.discount}</td>
                                            <td>Rs. {a.tax > 1 ? fNumber(+a.tax) : a.tax}</td>
                                            <td>Rs. {a.total > 1 ? fNumber(+a.total) : a.total}</td>
                                        </tr>
                                    )
                                })
                                }
                                <tr style={{ textAlign: 'left' }}>
                                    <th colSpan={4}>Total</th>
                                    <th>Rs. {details?.discount > 1 ? fNumber(+details?.discount) : details?.discount}</th>
                                    <th>Rs. {details?.tax > 1 ? fNumber(+details?.tax) : details?.tax}</th>
                                    <th>Rs. {details?.gtotal > 1 ? fNumber(+details?.gtotal) : details?.gtotal}</th>
                                </tr>
                                <tr>
                                    <th colSpan={7} className='padding'>
                                        {details ? (toWords(+details?.gtotal)).toUpperCase() : ''}
                                    </th>
                                </tr>
                                {details?.return_bill > 0 &&
                                    < tr >
                                        <th colSpan={7} className='padding'>Return Bill</th>
                                    </tr>
                                }
                                <tr>
                                    <th colSpan={7} className='padding' style={{ color: details?.left > 0 ? 'red' : 'green' }}>
                                        {details?.left > 0 ?
                                            "DUE: Rs. " + (details?.left > 1 ? fNumber(+details?.left) : details?.left) :
                                            "PAID"}
                                    </th>
                                </tr>
                            </tbody>
                        </table>
                        <div style={{ marginTop: 50, textAlign: 'center' }}>
                            Software Developed and Provided by East Devs
                            <a style={{ textDecoration: 'none' }} href={SETTINGS.eastdevsLink.link} target='blank'> ({SETTINGS.eastdevsLink.name}) </a>
                            Contact: {SETTINGS.contacts.arose} / {SETTINGS.contacts.qasim}
                        </div>
                    </div>
                </Container>
            }
        </Page >
    );
};
