/*
    Imports
*/
import { Container } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CenterLoading from 'src/components/misc/CenterLoading';
import ListPageTitle from 'src/components/misc/ListPageTitle';
import Page from 'src/components/Page';
import transferService from 'src/services/TransferServiceClass';
import userService from 'src/services/UserService';
import AddTransferForm from './forms/AddTransferForm';

/*
    Main Working
*/
export default ({ editing }) => {
    const navigate = useNavigate();

    const [from, setFrom] = useState();
    const [account, setAccount] = useState();
    const [users, setUsers] = useState();

    const getAccounts = () => {
        transferService
            .getAccounts()
            .then((data) => {
                setFrom(data.from_account);
                setAccount(data.account);
            })
            .catch((error) => {
                if (error.response) if (error.response.status === 401) navigate('/401');
                console.log(error);
            });
    };

    const getUsers = () => {
        userService
            .getAll()
            .then((data) => {
                setUsers(data);
            })
            .catch((error) => {
                if (error.response) if (error.response.status === 401) navigate('/401');
                console.log(error);
            });
    };

    useEffect(() => {
        getAccounts();
        getUsers();
    }, []);

    const loading = !from || !account || !users;

    /*
      Main Design
    */
    return (
        <Page title={editing ? `Edit Transfer` : `Add Transfer`}>
            <Container maxWidth="xl">
                <ListPageTitle>{editing ? `Adding Transfer` : 'Adding Transfer'}</ListPageTitle>
                {loading ? (
                    <CenterLoading />
                ) : (
                    <AddTransferForm
                        account={account}
                        fromAccount={from}
                        users={users}
                        editing={editing}
                    />
                )}
            </Container>
        </Page>
    );
};
