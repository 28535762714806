/*
  Imports
*/
import { Grid, Typography } from '@material-ui/core';
import { fNumber } from 'src/utils/formatNumber';
import Label from 'src/components/misc/Label';

/*
  Main Working
*/
export default ({ data }) => {
    /*
    States, Params, Navigation, Query, Variables.
  */

    const { receivable, payable, left, expenses } = data;

    /*
    Main Design
  */
    return (
        <>
            <Grid container spacing={0} style={{ paddingBottom: 10, paddingTop: 20 }}>
                <Grid item xs={12} sm={2} md={2}>
                    <Typography fontWeight="bold">Receivable: </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <Typography>Rs. {fNumber(receivable)}</Typography>
                </Grid>
                <Grid item xs={12} sm={2} md={2}>
                    <Typography fontWeight="bold">Received: </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <Typography>Rs. {fNumber(payable)}</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={0} style={{ paddingBottom: 10, paddingTop: 10 }}>
                <Grid item xs={12} sm={2} md={2}>
                    <Typography fontWeight="bold">Expense: </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <Typography>Rs. {fNumber(expenses)}</Typography>
                </Grid>
                <Grid item xs={12} sm={2} md={2}>
                    <Typography fontWeight="bold">Left: </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <Label
                        variant="ghost"
                        color={(left > 0 && 'success') || (left < 0 && 'error') || 'info'}
                    >
                        Rs. {fNumber(Math.abs(left))}
                    </Label>
                </Grid>
            </Grid>
        </>
    );
};
