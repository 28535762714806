/*
  Imports
*/
import { Container } from '@material-ui/core';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import logsService from 'src/services/LogsServiceClass';
import CenterLoading from 'src/components/misc/CenterLoading';
import Page from '../components/Page';
import LogsDetails from './tables/LogsDetails';
import ListPageTitle from 'src/components/misc/ListPageTitle';
import { SETTINGS } from 'src/config/settings';

/*
  Main Working
*/
export default () => {
  /*
    States, Params, Navigation, Query, Variables.
  */
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [data, setData] = useState([]);

  const [pagination, setPagination] = useState({
    search: '',
    perPage: SETTINGS.defaultPerPage,
    page: 0,
    order: 'asc',
    sort_by: 'id'
  });

  const title = `Logs Details`;

  /*
      Handlers, Functions
    */
  const getData = () => {
    setLoading(true);
    console.log('Error', pagination);
    logsService
      .getAll(pagination)
      .then((res) => {
        setData(res);
      })
      .catch((error) => {
        if (error.response) if (error.response.status === 401) navigate('/401');
        navigate('/404');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /*
      Use Effect Hooks.
    */
  useEffect(getData, [pagination]);

  /*
    Use Effect Hooks.
  */
  /*
    Main Design
  */
  return (
    <Page title={title}>
      <Container>
        <ListPageTitle>{title}</ListPageTitle>
        {loading ? (
          <CenterLoading />
        ) : (
          <>
            <LogsDetails data={data} pagination={pagination} setPagination={setPagination} />
          </>
        )}
      </Container>
    </Page>
  );
};
