import GenericService from './GenericService';
import qs from 'qs';

class ServiceClass extends GenericService {
    constructor() {
        super();
    }

    getAll = (pagination, date) =>
        this.get(`/transfer?${qs.stringify(pagination)}&${qs.stringify(date)}`);

    getOne = (ID) => this.get(`/transfer/${ID}`);

    getAccounts = () => this.get('/transfer/accounts');

    add = (data) => this.post('/transfer', data);

    update = (data, ID) => this.patch(`/transfer/${ID}`, data);

    getByMember = (ID, pagination, date) =>
        this.get(`/transfer/member/${ID}?${qs.stringify(pagination)}&${qs.stringify(date)}`);

    remove = (ID) => this.delete(`/transfer/${ID}`);
}

const transferService = new ServiceClass();

export default transferService;
