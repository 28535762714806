import GenericService from './GenericService';
import qs from 'qs';

class ServiceClass extends GenericService {
    constructor() {
        super();
    }

    getAll = (pagination) => this.get(`/customers?${qs.stringify(pagination)}`);

    getSingle = (number, date) => this.get(`/customers/${number}?${qs.stringify(date)}`);

    getByBill = (number, pagination, date) =>
        this.get(`/customers/bills/${number}?${qs.stringify(pagination)}&${qs.stringify(date)}`);

    getByCustomer = (number, pagination, date) =>
        this.get(`/customers/${number}/payments?${qs.stringify(pagination)}&${qs.stringify(date)}`);

    getCustomer = (ID) => this.get(`/customers/${ID}`);

    getCustomers = () => this.get(`/customers`);

    remove = (number, ID) => this.delete(`/customers/${number}/payments/${ID}`);
}

const customersService = new ServiceClass();

export default customersService;
