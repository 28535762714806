/*
    Imports
*/
import { Container } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CenterLoading from 'src/components/misc/CenterLoading';
import ListPageTitle from 'src/components/misc/ListPageTitle';
import Page from 'src/components/Page';
import expenseService from 'src/services/ExpenseServiceClass';
import rsoService from 'src/services/RsoServiceClass';
import userService from 'src/services/UserService';
import AddExpenseForm from './forms/AddExpenseForm';
import AddPaymentForm from './forms/AddPaymentForm';

/*
    Main Working
*/
export default ({ editing }) => {
  const navigate = useNavigate();

  const [from, setFrom] = useState();
  const [account, setAccount] = useState();
  const [users, setUsers] = useState();

  const getAccounts = () => {
    expenseService
      .getAccounts()
      .then((data) => {
        setFrom(data.from_account);
        setAccount(data.account);
      })
      .catch((error) => {
        if (error.response) if (error.response.status === 401) navigate('/401');
        console.log(error);
      });
  };

  const getUsers = () => {
    userService
      .getAll()
      .then((data) => {
        setUsers(data);
      })
      .catch((error) => {
        if (error.response) if (error.response.status === 401) navigate('/401');
        console.log(error);
      });
  }

  useEffect(() => {
    getAccounts();
    getUsers();
  }, []);

  const loading = !from || !account || !users;

  /*
      Main Design
    */
  return (
    <Page title={editing ? `Edit Expense` : `Add Expense`}>
      <Container maxWidth="xl">
        <ListPageTitle>{editing ? `Adding Expense` : 'Adding Expense'}</ListPageTitle>
        {loading ? <CenterLoading /> : <AddExpenseForm account={account} fromAccount={from} users={users} editing={editing} />}
      </Container>
    </Page>
  );
};
