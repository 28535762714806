/*
	Imports
*/
import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

/*
	Imports:
		Material UI
*/
import { Grid, InputLabel, TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { ThemeProvider } from '@material-ui/core/styles';
import Autocomplete from '@mui/material/Autocomplete';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import DatePicker from '@material-ui/lab/DatePicker';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';

/*
	Imports:
		Our Imports
		Components and Settings
		Services
*/
import Dialog from 'src/components/misc/alerts/Dialog';
import ServerError from 'src/components/misc/alerts/ServerError';
import LoadingFormButton from 'src/components/misc/Buttons/LoadingFormButton';
import { AddStockSchema } from 'src/config/form-schemas';
import { RouteStocks } from 'src/config/routes';
import { ContentStyle, FormTheme } from '../../theme/form-pages';
import stockService from 'src/services/StockServiceClass';

/*
	Main Working
*/
export default ({ products }) => {

	/*
		States, Params, Navigation, Query, Variables.
	*/
	let prodName = products.map(a => a.name);
	const [serverError, setServerError] = useState('');
	const [openDia, setOpenDia] = useState(false);
	const [value, setValue] = useState(prodName[0]);
	const [inputValue, setInputValue] = useState('');
	const [disabled, setDisabled] = useState(products[0].type == "Percentage");

	const navigate = useNavigate();


	/*
		Form Setup
	*/
	const formik = useFormik({
		initialValues: {
			product: prodName[0],
			price: products[0].type == "Percentage" ? products[0].purchase_p : 0,
			quantity: 0,
			date: new Date()
		},
		validationSchema: AddStockSchema,
		onSubmit: (_values, { setFieldError }) => {
			addData();
		}
	});

	const { values, errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue, setSubmitting, resetForm, initialValues, setFieldError } = formik;

	/*
		Handlers
	*/
	const addData = () => {
		setSubmitting(true);
		values.product = products.find(obj => obj.name == values.product).id;
		stockService.add({ ...values })
			.then(() => {
				setOpenDia(true);
			})
			.catch((err) => {
				console.log("Error", err, err.response);
				if (err.response) {
					for (const key in values) {
						if (Object.hasOwnProperty.call(values, key)) {
							if (err.response.data.message.includes(key))
								setFieldError(key, 'Invalid');
						}
					}
				}

				console.log("Error", err, err.response);
			}).finally(() => {
				setSubmitting(false);
			});
	};

	const handleProductChange = () => {
		if (!isSubmitting) {
			const prod = products.find(obj => obj.name == values.product);
			console.log(prod)
			if (prod) {
				if (prod.type == "Percentage") {
					values.price = prod.purchase_p
					setDisabled(true)
				}
				else {
					values.price = 0
					setDisabled(false)
				}
			}
		}
	}

	const handleClose = () => {
		setOpenDia(false);
		navigate(RouteStocks);
	};

	/*
		Use Effect Hooks.
	*/

	useEffect(handleProductChange, [values.product]);


	/*
		Main Design
	*/
	return (
		<FormikProvider value={formik}>
			<Form autoComplete="off" noValidate onSubmit={handleSubmit}>
				<ContentStyle>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={6} md={6}>
							<ThemeProvider theme={FormTheme}>
								<InputLabel label="date">Date</InputLabel>
							</ThemeProvider>
							<LocalizationProvider dateAdapter={AdapterDateFns}>
								<DatePicker
									value={values.date}
									onChange={(newValue) => {
										setFieldValue('date', newValue);
									}}
									renderInput={(params) => <TextField
										fullWidth
										{...params}
										{...getFieldProps('date')}

										error={Boolean(touched.date && errors.date)}
										helperText={touched.date && errors.date}
									/>}
								/>
							</LocalizationProvider>
						</Grid>
					</Grid>
				</ContentStyle>
				<ContentStyle>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={6} md={6}>
							<ThemeProvider theme={FormTheme}>
								<InputLabel>Product</InputLabel>
							</ThemeProvider>
							<Autocomplete
								disableClearable={true}
								options={prodName}
								value={value}
								onChange={(event, newValue) => {
									setValue(newValue);
									values.product = newValue
								}}
								inputValue={inputValue}
								onInputChange={(event, newInputValue) => {
									setInputValue(newInputValue);
								}}
								renderInput={(params) =>
									<TextField
										{...params}
										fullWidth
										{...getFieldProps('product')}
										error={Boolean(touched.product && errors.product)}
										helperText={touched.product && errors.product}
									/>}
							/>
						</Grid>
					</Grid>
				</ContentStyle>

				<ContentStyle>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={6} md={6}>
							<ThemeProvider theme={FormTheme}>
								<InputLabel>Price</InputLabel>
							</ThemeProvider>
							<NumberFormat
								disabled={disabled}
								fullWidth
								customInput={TextField}
								{...getFieldProps('price')}
								error={Boolean(touched.price && errors.price)}
								helperText={touched.price && errors.price}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={6}>
							<ThemeProvider theme={FormTheme}>
								<InputLabel>Quantity</InputLabel>
							</ThemeProvider>
							<NumberFormat
								fullWidth
								customInput={TextField}
								{...getFieldProps('quantity')}
								error={Boolean(touched.quantity && errors.quantity)}
								helperText={touched.quantity && errors.quantity}
							/>
						</Grid>
					</Grid>
				</ContentStyle>

				<Dialog
					buttonText={"Close"}
					openDialog={openDia}
					handleButton={handleClose}
				>
					{`Stock is added`}
				</Dialog>

				<LoadingFormButton loading={isSubmitting}>
					{'Add'}
				</LoadingFormButton>
				<ServerError open={serverError}>
					{serverError}
				</ServerError>
			</Form>
		</FormikProvider >
	);
};

