/*
    Imports
*/
import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

/*
    Imports:
        Material UI
*/
import { Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import Autocomplete from '@mui/material/Autocomplete';

/*
    Imports:
        Our Imports
        Components and Settings
        Services
*/
import Dialog from 'src/components/misc/alerts/Dialog';
import ServerError from 'src/components/misc/alerts/ServerError';
import LoadingFormButton from 'src/components/misc/Buttons/LoadingFormButton';
import { AddTransferSlipSchema } from 'src/config/form-schemas';
import { RouteTransfer } from 'src/config/routes';
import transferService from 'src/services/TransferServiceClass';
import { ContentStyle, FormTheme } from '../../theme/form-pages';
import CenterLoading from 'src/components/misc/CenterLoading';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import DatePicker from '@material-ui/lab/DatePicker';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';

/*
    Main Working
*/
export default ({ account, fromAccount, users, editing }) => {
    /*
        States, Params, Navigation, Query, Variables.
    */
    const [serverError, setServerError] = useState('');
    const [openDia, setOpenDia] = useState(false);
    const [loading, setLoading] = useState(false);
    const [valueA, setValueA] = useState(account[0]);
    const [inputValueA, setInputValueA] = useState('');
    const [valueF, setValueF] = useState(fromAccount[0]);
    const [inputValueF, setInputValueF] = useState('');

    const navigate = useNavigate();

    const transferID = useParams().transferID;

    /*
        Form Setup
    */
    const formik = useFormik({
        initialValues: {
            details: '',
            amount: 0,
            account: '',
            from_account: '',
            date: new Date(),
            member: 0,
            from_member: 0,
        },
        validationSchema: AddTransferSlipSchema,
        onSubmit: (_values, { setFieldError }) => {
            addData();
        },
    });

    const {
        values,
        errors,
        touched,
        isSubmitting,
        handleSubmit,
        getFieldProps,
        setFieldValue,
        setSubmitting,
        resetForm,
        initialValues,
        setFieldError,
    } = formik;

    /*
        Handlers
    */
    const addData = () => {
        setSubmitting(true);
        if (values.member == 0) values.member = null;

        let FuncToCall = transferService.add;
        if (editing) FuncToCall = transferService.update;
        FuncToCall({ ...values }, transferID)
            .then(() => {
                setOpenDia(true);
            })
            .catch((err) => {
                console.log('Error', err, err.response);
                if (err.response) {
                    for (const key in values) {
                        if (Object.hasOwnProperty.call(values, key)) {
                            if (err.response.data.message.includes(key))
                                setFieldError(key, 'Invalid or Already Added');
                        }
                    }
                }

                console.log('Error', err, err.response);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const handleClose = () => {
        setOpenDia(false);
        navigate(RouteTransfer);
    };

    const handleEditing = () => {
        if (editing) {
            setLoading(true)
            transferService
                .getOne(transferID)
                .then((data) => {
                    console.log(data);
                    setFieldValue('amount', data.amount);
                    setFieldValue('details', data.details);
                    setFieldValue('account', data.account);
                    setValueA(data.account);
                    setFieldValue('from_account', data.from_account);
                    setValueF(data.from_account);
                    setFieldValue('member', data.member);
                    setFieldValue('from_member', data.from_member);
                    setFieldValue('date', data.date);
                })
                .catch((_err) => console.log(_err.response))
                .finally(() => setLoading(false))
        }
    };

    /*
        Use Effect Hooks.
    */

    useEffect(handleEditing, []);

    /*
        Main Design
    */
    return (
        <>
            {loading &&
                <CenterLoading></CenterLoading>
            }
            {!loading &&
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <ContentStyle>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6} md={6}>
                                    <ThemeProvider theme={FormTheme}>
                                        <InputLabel label="date">Date</InputLabel>
                                    </ThemeProvider>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            value={values.date}
                                            onChange={(newValue) => {
                                                setFieldValue('date', newValue);
                                            }}
                                            renderInput={(params) => <TextField
                                                fullWidth
                                                {...params}
                                                {...getFieldProps('date')}

                                                error={Boolean(touched.date && errors.date)}
                                                helperText={touched.date && errors.date}
                                            />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>
                        </ContentStyle>
                        <ContentStyle>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6} md={6}>
                                    <ThemeProvider theme={FormTheme}>
                                        <InputLabel>Amount</InputLabel>
                                    </ThemeProvider>
                                    <TextField
                                        fullWidth
                                        customInput={TextField}
                                        autoComplete="number"
                                        type="text"
                                        {...getFieldProps('amount')}
                                        inputProps={{
                                            inputMode: 'numeric',
                                        }}
                                        error={Boolean(touched.amount && errors.amount)}
                                        helperText={touched.amount && errors.amount}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <ThemeProvider theme={FormTheme}>
                                        <InputLabel>Details</InputLabel>
                                    </ThemeProvider>
                                    <TextField
                                        fullWidth
                                        {...getFieldProps('details')}
                                        error={Boolean(touched.details && errors.details)}
                                        helperText={touched.details && errors.details}
                                    />
                                </Grid>
                            </Grid>
                        </ContentStyle>
                        <ContentStyle>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6} md={6}>
                                    <ThemeProvider theme={FormTheme}>
                                        <InputLabel>Account</InputLabel>
                                    </ThemeProvider>
                                    <Autocomplete
                                        disableClearable={true}
                                        options={account}
                                        value={valueA}
                                        onChange={(event, newValue) => {
                                            setValueA(newValue);
                                        }}
                                        inputValue={inputValueA}
                                        onInputChange={(event, newInputValue) => {
                                            values.account = newInputValue;
                                            setInputValueA(newInputValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                {...getFieldProps('account')}
                                                error={Boolean(touched.account && errors.account)}
                                                helperText={touched.account && errors.account}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <ThemeProvider theme={FormTheme}>
                                        <InputLabel>From Account</InputLabel>
                                    </ThemeProvider>
                                    <Autocomplete
                                        disableClearable={true}
                                        options={fromAccount}
                                        value={valueF}
                                        onChange={(event, newValue) => {
                                            setValueF(newValue);
                                        }}
                                        inputValue={inputValueF}
                                        onInputChange={(event, newInputValue) => {
                                            values.from_account = newInputValue;
                                            setInputValueF(newInputValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                {...getFieldProps('from_account')}
                                                error={Boolean(touched.from_account && errors.from_account)}
                                                helperText={touched.from_account && errors.from_account}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </ContentStyle>

                        <ContentStyle>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6} md={6}>
                                    <ThemeProvider theme={FormTheme}>
                                        <InputLabel>User</InputLabel>
                                    </ThemeProvider>
                                    <Select fullWidth {...getFieldProps(`member`)}>
                                        <MenuItem key={0} value={0}>
                                            None
                                        </MenuItem>
                                        {users.map((row) => (
                                            <MenuItem key={row.id} value={row.id}>
                                                {row.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <ThemeProvider theme={FormTheme}>
                                        <InputLabel>From User</InputLabel>
                                    </ThemeProvider>
                                    <Select fullWidth {...getFieldProps(`from_member`)}>
                                        <MenuItem key={0} value={0}>
                                            None
                                        </MenuItem>
                                        {users.map((row) => (
                                            <MenuItem key={row.id} value={row.id}>
                                                {row.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                            </Grid>
                        </ContentStyle>

                        <Dialog buttonText={'Close'} openDialog={openDia} handleButton={handleClose}>
                            {editing ? `Transfer is updated` : `Transfer is added`}
                        </Dialog>

                        <LoadingFormButton loading={isSubmitting}>
                            {editing ? `Edit` : 'Add'}
                        </LoadingFormButton>
                        <ServerError open={serverError}>{serverError}</ServerError>
                    </Form>
                </FormikProvider>
            }
        </>
    );
};
