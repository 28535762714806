/*
    Imports
*/
import { Container } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CenterLoading from 'src/components/misc/CenterLoading';
import ListPageTitle from 'src/components/misc/ListPageTitle';
import Page from 'src/components/Page';
import customersService from 'src/services/CustomersServiceClass';
import AddCustomerPaymentForm from './forms/AddCustomerPaymentForm';

/*
    Main Working
*/
export default ({ editing }) => {
  const navigate = useNavigate();
  const [customers, setCustomers] = useState();

  const getCustomers = () => {
    customersService
      .getAll()
      .then((data) => {
        setCustomers(data);
      })
      .catch((error) => {
        if (error.response) if (error.response.status === 401) navigate('/401');
        console.log(error);
      });
  };

  useEffect(() => {
    getCustomers();
  }, []);
  const loading = !customers;

  console.log(loading, customers);

  /*
      Main Design
    */
  return (
    <Page title={editing ? `Edit Payment` : `Add Payment`}>
      <Container maxWidth="xl">
        <ListPageTitle>{editing ? `Editing Payment` : 'Adding Payment'}</ListPageTitle>
        {loading ? (
          <CenterLoading />
        ) : (
          <AddCustomerPaymentForm editing={editing} customers={customers} />
        )}
      </Container>
    </Page>
  );
};
