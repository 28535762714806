/*
    Imports
*/
import { Container } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CenterLoading from 'src/components/misc/CenterLoading';
import ListPageTitle from 'src/components/misc/ListPageTitle';
import Page from 'src/components/Page';
import productsService from 'src/services/ProductsServiceClass';
import rsoService from 'src/services/RsoServiceClass';
import userService from 'src/services/UserService';
import GenerateBillForm from './forms/GenerateBillForm';
import GenerateRsoBillForm from './forms/GenerateRsoBillForm';

/*
    Main Working
*/
export default () => {
    const navigate = useNavigate();

    const [products, setProducts] = useState();
    const [rsos, setRsos] = useState();

    const [searchParams, _setSearchParams] = useSearchParams();
    const rsoUsername = searchParams.get('rso');

    const getData = () => {
        productsService
            .getAll()
            .then((data) => {
                setProducts(data);
            })
            .catch((error) => {
                if (error.response) if (error.response.status === 401) navigate('/401');
                console.log(error);
            });
    };

    const getUsers = () => {
        userService
            .getAll()
            .then((data) => {
                setRsos(data);
            })
            .catch((error) => {
                if (error.response) if (error.response.status === 401) navigate('/401');
                console.log(error);
            });
    };

    const getRso = () => {
        userService
            .getUser(rsoUsername)
            .then((data) => {
                setRsos(data);
            })
            .catch((error) => {
                if (error.response) if (error.response.status === 401) navigate('/401');
                console.log(error);
            });
    };

    useEffect(() => {
        getData();
        if (rsoUsername) getRso();
        else getUsers();
    }, []);

    const loading = !products || !rsos;

    /*
      Main Design
    */
    return (
        <Page title={`Add Bill`}>
            <Container maxWidth="xl">
                <ListPageTitle>{'Adding Bill'}</ListPageTitle>
                {loading ? (
                    <CenterLoading />
                ) : (
                    <GenerateRsoBillForm
                        products={products}
                        rsos={rsos}
                        rsoUsername={rsoUsername}
                    />
                )}
            </Container>
        </Page>
    );
};
