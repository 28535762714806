/*
    Imports
*/
import { FieldArray, Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useNavigate } from 'react-router-dom';

/*
    Imports:
        Material UI
*/
import {
    Box,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import Autocomplete from '@mui/material/Autocomplete';

/*
    Imports:
        Our Imports
        Components and Settings
        Services
*/
import Dialog from 'src/components/misc/alerts/Dialog';
import ServerError from 'src/components/misc/alerts/ServerError';
import LoadingFormButton from 'src/components/misc/Buttons/LoadingFormButton';
import { ReturnBillSchema } from 'src/config/form-schemas';
import { RoutePayments } from 'src/config/routes';
import billService from 'src/services/BillServiceClass';
import customersService from 'src/services/CustomersServiceClass';
import { ContentStyle, FormTheme } from '../../theme/form-pages';
import { fNumber } from 'src/utils/formatNumber';

/*
    Main Working
*/
export default ({ products }) => {
    /*
        States, Params, Navigation, Query, Variables.
    */

    const [serverError, setServerError] = useState('');
    const [openDia, setOpenDia] = useState(false);
    const [billID, setBillId] = useState(0);
    const [value, setValue] = useState();
    // const [inputValue, setInputValue] = useState(customers[0].number);

    const navigate = useNavigate();

    /*
        Form Setup
    */

    const formik = useFormik({
        initialValues: {
            number: '',
            name: '',
            address: '',
            email: '',
            paidStatus: 'Paid',
            paid: 0,
            grandTotal: 0,
            items: [
                {
                    product: products[0].name,
                    price: products[0].price,
                    quantity: 0,
                    left: 0,
                    oprice: 0,
                    total: 0
                }
            ]
        },
        validationSchema: ReturnBillSchema,
        onSubmit: (_values, { setFieldError }) => {
            addData();
        }
    });

    const {
        values,
        errors,
        touched,
        isSubmitting,
        handleSubmit,
        getFieldProps,
        setFieldValue,
        setSubmitting,
        resetForm,
        initialValues,
        setFieldError,
        handleBlur
    } = formik;

    /*
        Handlers
    */
    const addData = () => {
        setSubmitting(true);
        const customer = {
            name: values.name,
            address: values.address,
            number: values.number.number.replace('-', ''),
            email: values.email
        };
        let valuess = { ...values };
        delete valuess.name;
        delete valuess.number;
        delete valuess.email;
        delete valuess.address;
        delete valuess.grandTotal;
        valuess = { ...valuess, ...{ customer } };

        const newitems = valuess.items.map((item) => {
            item.oprice = products.find((prod) => prod.name == item.product).price;
            item.product = products.find((prod) => prod.name == item.product).id;
            item.price = +item.price;
        });

        let { paidStatus, ...rest } = valuess;
        rest.items.forEach((o) => {
            delete o.left;
            delete o.total;
        });

        billService
            .returnBill(rest)
            .then((response) => {
                setBillId(response.id);
                setOpenDia(true);
            })
            .catch((err) => {
                console.log('Error', err, err.response);
                if (err.response) {
                    for (const key in values) {
                        if (Object.hasOwnProperty.call(values, key)) {
                            if (err.response.data.message.includes(key))
                                setFieldError(key, 'Invalid or Already Added');
                        }
                    }
                }
                console.log('Error', err, err.response);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const handleClose = () => {
        setOpenDia(false);
        navigate(RoutePayments);
    };
    const handlePrintE_Bill = () => {
        setOpenDia(false);
        navigate(`/bills/${billID}/print`);
    };
    const handlePrintBill = () => {
        setOpenDia(false);
        navigate(`/bills/${billID}/print-thermal`);
    };

    const showProductsList = (customer) => {
        const u = [];
        customer.product_details.map((a) => {
            if (a.quantity > 0)
                u.push({
                    product: a.product,
                    price:
                        a.total / a.quantity > 1
                            ? Math.floor(a.total / a.quantity)
                            : (a.total / a.quantity).toFixed(3),
                    oprice:
                        a.total / a.quantity > 1
                            ? Math.floor(a.total / a.quantity)
                            : (a.total / a.quantity).toFixed(3),
                    quantity: a.quantity,
                    left: a.quantity,
                    total: a.total
                });
            setFieldValue('items', u);
        });
        setFieldValue('grandTotal', customer.total);
        if (customer.product_details.length < 1) {
            setFieldValue('items', []);
            setServerError('No products are sold to this customer');
        }
    };

    const handleCustomerChange = () => {
        if (!values.number) return;
        setServerError('')
        if (values.number.replace(' ', '').length > 11) {
            customersService
                .getCustomer(values.number.replace('-', ''))
                .then((_customer) => {
                    fillData(_customer);
                    showProductsList(_customer);
                })
                .catch(() => {
                    setFieldValue('items', []);
                    console.log('Customer Not Found');
                    setServerError('Invalid Customer');
                });
        }
    };

    function fillData(customers) {
        setFieldValue('name', customers.name);
        setFieldValue('email', customers.email);
        setFieldValue('address', customers.address);
    }

    const handleTotal = () => {
        values.grandTotal = values.items.reduce((total, item) => (total += item.total), 0);
        values.paidStatus == 'Paid' ? (values.paid = values.grandTotal) : '';
    };

    useEffect(handleCustomerChange, []);
    useEffect(handleCustomerChange, [values.number]);
    useEffect(handleTotal, [values]);

    /*
        Main Design
    */

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Typography variant="h6" gutterBottom>
                    User Details
                </Typography>

                <ContentStyle>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={6}>
                            <ThemeProvider theme={FormTheme}>
                                <InputLabel label="Number">Customer Number</InputLabel>
                            </ThemeProvider>
                            <NumberFormat
                                fullWidth
                                customInput={TextField}
                                autoComplete="number"
                                type="text"
                                format="####-#######"
                                onBlur={handleBlur}
                                {...getFieldProps('number')}
                                inputProps={{
                                    inputMode: 'numeric'
                                }}
                                error={Boolean(touched.number && errors.number)}
                                helperText={touched.number && errors.number}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                            <ThemeProvider theme={FormTheme}>
                                <InputLabel>Name</InputLabel>
                            </ThemeProvider>
                            <TextField
                                fullWidth
                                {...getFieldProps('name')}
                                disabled={true}
                                error={Boolean(touched.name && errors.name)}
                                helperText={touched.name && errors.name}
                            />
                        </Grid>
                    </Grid>
                </ContentStyle>

                <ContentStyle>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={6}>
                            <ThemeProvider theme={FormTheme}>
                                <InputLabel>Email</InputLabel>
                            </ThemeProvider>
                            <TextField
                                fullWidth
                                autoComplete="email"
                                {...getFieldProps('email')}
                                inputProps={{
                                    inputMode: 'email'
                                }}
                                disabled={true}
                                error={Boolean(touched.email && errors.email)}
                                helperText={touched.email && errors.email}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                            <ThemeProvider theme={FormTheme}>
                                <InputLabel label="Address">Address</InputLabel>
                            </ThemeProvider>
                            <TextField
                                fullWidth
                                autoComplete="address"
                                {...getFieldProps('address')}
                                disabled={true}
                                error={Boolean(touched.address && errors.address)}
                                helperText={touched.address && errors.address}
                            />
                        </Grid>
                    </Grid>
                </ContentStyle>
                <Typography variant="h6" gutterBottom>
                    Bill Details
                </Typography>
                <FieldArray name="items">
                    <>
                        {values.items.map((product, index) => (
                            <ContentStyle>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={5} md={5}>
                                        <ThemeProvider theme={FormTheme}>
                                            <InputLabel>Product</InputLabel>
                                        </ThemeProvider>
                                        <TextField
                                            disabled={true}
                                            name={`items.${index}.product`}
                                            fullWidth
                                            autoComplete="email"
                                            {...getFieldProps(`items.${index}.product`)}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={2} md={2}>
                                        <ThemeProvider theme={FormTheme}>
                                            <InputLabel>Price</InputLabel>
                                        </ThemeProvider>
                                        <NumberFormat
                                            name={`items.${index}.price`}
                                            fullWidth
                                            customInput={TextField}
                                            type="text"
                                            allowEmptyFormatting="true"
                                            inputProps={{
                                                inputMode: 'numeric'
                                            }}
                                            {...getFieldProps(`items.${index}.price`)}
                                        />
                                        <FormHelperText>
                                            Price sold: {values.items[index].oprice}
                                        </FormHelperText>
                                        <FormHelperText error={true}>
                                            {errors?.items?.length > 0 &&
                                                errors.items[index]?.price}
                                        </FormHelperText>
                                    </Grid>

                                    <Grid item xs={12} sm={2} md={2}>
                                        <ThemeProvider theme={FormTheme}>
                                            <InputLabel>Quantity</InputLabel>
                                        </ThemeProvider>
                                        <NumberFormat
                                            fullWidth
                                            customInput={TextField}
                                            type="text"
                                            allowEmptyFormatting="true"
                                            inputProps={{
                                                inputMode: 'numeric'
                                            }}
                                            {...getFieldProps(`items.${index}.quantity`)}
                                        />
                                        <FormHelperText>
                                            Total quantity: {fNumber(values.items[index].left)}
                                        </FormHelperText>
                                        <FormHelperText error={true}>
                                            {errors?.items?.length > 0 &&
                                                errors.items[index]?.quantity}
                                        </FormHelperText>
                                    </Grid>

                                    <Grid item xs={12} sm={3} md={3}>
                                        <ThemeProvider theme={FormTheme}>
                                            <InputLabel>
                                                Total Rs.
                                            </InputLabel>
                                        </ThemeProvider>
                                        <TextField
                                            value={fNumber(
                                                (values.items[index].total = Math.round(
                                                    +values.items[index].quantity *
                                                    +values.items[index].price
                                                ))
                                            )}
                                            disabled={true}
                                        />
                                    </Grid>
                                </Grid>
                            </ContentStyle>
                        ))}
                    </>
                </FieldArray>

                <ContentStyle>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={6}>
                            <ThemeProvider theme={FormTheme}>
                                <InputLabel label="grandTotal">
                                    Total Amount {fNumber(Math.round(values.grandTotal.toFixed(3)))}
                                </InputLabel>
                            </ThemeProvider>
                        </Grid>
                    </Grid>
                </ContentStyle>

                <ContentStyle>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={6}>
                            <ThemeProvider theme={FormTheme}>
                                <InputLabel label="paidStatus">Status</InputLabel>
                            </ThemeProvider>
                            <Select
                                fullWidth
                                {...getFieldProps('paidStatus')}
                                error={Boolean(touched.paidStatus && errors.paidStatus)}
                            >
                                <MenuItem key="Paid" value="Paid">
                                    Paid
                                </MenuItem>
                                <MenuItem key="Due" value="Due">
                                    Due
                                </MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <ThemeProvider theme={FormTheme}>
                                <InputLabel>Amount Paid</InputLabel>
                            </ThemeProvider>
                            <TextField
                                fullWidth
                                disabled={values.paidStatus == 'Paid'}
                                autoComplete="paid"
                                {...getFieldProps('paid')}
                                inputProps={{
                                    inputMode: 'numeric'
                                }}
                                error={Boolean(touched.paid && errors.paid)}
                                helperText={touched.paid && errors.paid}
                            />
                        </Grid>
                    </Grid>
                </ContentStyle>

                <Dialog
                    buttonText={'Close'}
                    buttonText2={'eBill'}
                    buttonText3={'Bill'}
                    openDialog={openDia}
                    handleButton={handleClose}
                    handleButton2={handlePrintE_Bill}
                    handleButton3={handlePrintBill}
                >
                    {`Bill is returned`}
                </Dialog>

                <LoadingFormButton disabled={serverError} loading={isSubmitting}>
                    {'Return'}
                </LoadingFormButton>
                <ServerError open={serverError}>{serverError}</ServerError>
            </Form>
        </FormikProvider>
    );
};
