/*
    Imports
*/
import { Form, FormikProvider, useFormik } from 'formik';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

/*
    Imports:
        Material UI
*/
import { Grid, InputLabel, TextField, Typography, Select, MenuItem } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { ThemeProvider } from '@material-ui/core/styles';
/*
    Imports:
        Our Imports
        Components and Settings
        Services
*/
import Dialog from 'src/components/misc/alerts/Dialog';
import ServerError from 'src/components/misc/alerts/ServerError';
import LoadingFormButton from 'src/components/misc/Buttons/LoadingFormButton';
import { AddProductSchema } from 'src/config/form-schemas';
import { RouteProducts } from 'src/config/routes';
import { ContentStyle, FormTheme } from '../../theme/form-pages';
import productsService from 'src/services/ProductsServiceClass';
import CenterLoading from 'src/components/misc/CenterLoading';


/*
    Main Working
*/
export default ({ editing }) => {

    /*
        States, Params, Navigation, Query, Variables.
    */
    const [serverError, setServerError] = useState('');
    const [openDia, setOpenDia] = useState(false);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const productID = useParams().productID

    /*
        Form Setup
    */
    const formik = useFormik({
        initialValues: {
            type: 'Fixed',
            name: '',
            price: 0,
            sale_p: 0.00,
            purchase_p: 0.00,
        },
        validationSchema: AddProductSchema,
        onSubmit: (_values, { setFieldError }) => {
            addData();
        }
    });

    const { values, errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue, setSubmitting, resetForm, initialValues, setFieldError } = formik;

    /*
        Handlers
    */
    const addData = () => {
        setSubmitting(true);
        let FuncToCall = productsService.add;
        if (editing) FuncToCall = productsService.update;
        FuncToCall({ ...values }, productID)
            .then(() => {
                setOpenDia(true);
            })
            .catch((err) => {
                console.log("Error", err, err.response);
                if (err.response) {
                    for (const key in values) {
                        if (Object.hasOwnProperty.call(values, key)) {
                            if (err.response.data.message.includes(key))
                                setFieldError(key, 'Invalid or Already Added');
                        }
                    }
                }

                console.log("Error", err, err.response);
            }).finally(() => {
                setSubmitting(false);
            });
    };

    const handleClose = () => {
        setOpenDia(false);
        navigate(RouteProducts);
    };

    const handleEditing = () => {
        if (editing) {
            setLoading(true)
            productsService.getOne(productID)
                .then((data) => {
                    setFieldValue('name', data.name);
                    setFieldValue('price', data.price);
                    setFieldValue('type', data.type);
                    setFieldValue('sale_p', data.sale_p);
                    setFieldValue('purchase_p', data.purchase_p);
                })
                .catch((_err) => console.log(_err.response))
                .finally(() => setLoading(false))
        }
    }


    /*
        Use Effect Hooks.
    */

    useEffect(handleEditing, []);


    /*
        Main Design
    */
    return (
        <>
            {loading &&
                <CenterLoading></CenterLoading>
            }
            {!loading &&
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>

                        <Typography variant="h6" gutterBottom>
                            Product Details
                        </Typography>

                        <ContentStyle>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6} md={6}>
                                    <ThemeProvider theme={FormTheme}>
                                        <InputLabel>Name</InputLabel>
                                    </ThemeProvider>
                                    <TextField
                                        fullWidth
                                        autoComplete="name"
                                        {...getFieldProps('name')}
                                        error={Boolean(touched.name && errors.name)}
                                        helperText={touched.name && errors.name}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <ThemeProvider theme={FormTheme}>
                                        <InputLabel label="Address">Type</InputLabel>
                                    </ThemeProvider>
                                    <Select fullWidth
                                        disabled={editing}
                                        {...getFieldProps('type')}
                                        error={Boolean(touched.type && errors.type)}
                                        helperText={touched.type && errors.type}
                                    >

                                        <MenuItem key='Fixed' value='Fixed'>Fixed</MenuItem>
                                        <MenuItem key='Percentage' value='Percentage'>Percentage</MenuItem>

                                    </Select>
                                </Grid>
                            </Grid>
                        </ContentStyle>

                        <ContentStyle>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6} md={6}>
                                    <ThemeProvider theme={FormTheme}>
                                        <InputLabel>Price</InputLabel>
                                    </ThemeProvider>
                                    <NumberFormat
                                        fullWidth
                                        customInput={TextField}
                                        {...getFieldProps('price')}
                                        error={Boolean(touched.price && errors.price)}
                                        helperText={touched.price && errors.price}
                                    />
                                </Grid>
                            </Grid>
                        </ContentStyle>

                        <ContentStyle>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6} md={6}>
                                    <ThemeProvider theme={FormTheme}>
                                        <InputLabel>Sale
                                            {
                                                values.type == 'Fixed' ? `Price` : `Percentage`
                                            }
                                        </InputLabel>
                                    </ThemeProvider>
                                    <NumberFormat
                                        fullWidth
                                        customInput={TextField}
                                        {...getFieldProps('sale_p')}
                                        error={Boolean(touched.sale_p && errors.sale_p)}
                                        helperText={touched.sale_p && errors.sale_p}
                                    />
                                </Grid>
                                {
                                    values.type == 'Percentage' &&
                                    <Grid item xs={12} sm={6} md={6}>
                                        <ThemeProvider theme={FormTheme}>
                                            <InputLabel>Purchase Percentage</InputLabel>
                                        </ThemeProvider>
                                        <NumberFormat
                                            fullWidth
                                            customInput={TextField}
                                            {...getFieldProps('purchase_p')}
                                            error={Boolean(touched.purchase_p && errors.purchase_p)}
                                            helperText={touched.purchase_p && errors.purchase_p}
                                        />
                                    </Grid>
                                }
                            </Grid>
                        </ContentStyle>


                        <Dialog
                            buttonText={"Close"}
                            openDialog={openDia}
                            handleButton={handleClose}
                        >
                            {editing ? `Product is updated` : `Product is added`}
                        </Dialog>

                        <LoadingFormButton loading={isSubmitting}>
                            {editing ? 'Edit' : 'Add'}
                        </LoadingFormButton>
                        <ServerError open={serverError}>
                            {serverError}
                        </ServerError>
                    </Form>
                </FormikProvider >
            }
        </>
    );
};

