/*
  Imports
*/
import {
    Card,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from '@material-ui/core';
import { useState } from 'react';

import { Link } from 'react-router-dom';
import palette from 'src/theme/palette';
import { getDateString } from 'src/utils/dateTime';
import SearchNotFound from '../../components/misc/alerts/SearchNotFound';
import { UserListHead } from '../../components/misc/table';
import Scrollbar from '../../components/Scrollbar';

/*
  Main Working
*/
export default ({ data }) => {
    /*
    States, Params, Navigation, Query, Variables.
  */
    const TableHeader = [
        { id: 'date', label: 'Date', align: 'left' },
        { id: 'slip', label: 'Slips', align: 'left' },
        { id: 'payment', label: 'Payments', align: 'left' },
        { id: 'commission', label: 'Commissions', align: 'left' },
    ];

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('id');

    const isUserNotFound = data.length === 0;

    /*
    Handlers, Functions
  */
    const handleRequestSort = (_event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    /*
    Main Design.
  */
    return (
        <>
            <Card>
                <Scrollbar>
                    <TableContainer sx={{ minWidth: 800 }}>
                        <Table>
                            <UserListHead
                                order={order}
                                orderBy={orderBy}
                                headLabel={TableHeader}
                                rowCount={data.length}
                                onRequestSort={handleRequestSort}
                                hideCheckBoxes={true}
                            />
                            <TableBody>
                                {data.map((row) => {
                                    const { id, date, slip, payment, commission } = row;

                                    return (
                                        <TableRow
                                            key={id}
                                            tabIndex={-1}
                                            role="checkbox"
                                            style={{
                                                backgroundColor: palette.background.hover,
                                                textDecoration: 'none',
                                            }}
                                        >
                                            <TableCell padding="checkbox"></TableCell>
                                            <TableCell>
                                                <Typography noWrap>
                                                    {getDateString(date)}
                                                </Typography>
                                            </TableCell>

                                            <TableCell align="left">
                                                {slip && `Rs. ${slip}`}
                                            </TableCell>
                                            <TableCell align="left">
                                                {payment && `Rs. ${payment}`}
                                            </TableCell>
                                            <TableCell align="left">
                                                {commission && `Rs. ${commission}`}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                            {isUserNotFound && (
                                <TableBody>
                                    <TableRow>
                                        <TableCell
                                            align="center"
                                            colSpan={TableHeader.length + 1}
                                            sx={{ py: 3 }}
                                        >
                                            <SearchNotFound />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Scrollbar>
            </Card>
        </>
    );
};
