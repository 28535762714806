/*
  Imports
*/
import { Avatar, Grid, Stack, Typography } from '@material-ui/core';
import { SETTINGS } from 'src/config/settings';
import { getDateString, getDateTime } from 'src/utils/dateTime';
import { fNumber } from 'src/utils/formatNumber';
import { getImage } from 'src/utils/misc';

/*
  Main Working
*/
export default ({ data }) => {
    /*
    States, Params, Navigation, Query, Variables.
  */
    const { date, details } = data;
    /*
    Main Design
  */
    return (
        <>
            <Grid
                container
                spacing={1}
                style={{ paddingBottom: 20, paddingTop: 20, paddingLeft: 10 }}
            >
                <Grid item xs={12} sm={2} md={2}>
                    <Typography fontWeight="bold">Date: </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <Typography>{getDateString(date)}</Typography>
                </Grid>
                <Grid item xs={12} sm={2} md={2}>
                    <Typography fontWeight="bold">Details: </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <Typography>{details}</Typography>
                </Grid>
            </Grid>
        </>
    );
};
