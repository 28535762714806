import { SETTINGS } from "src/config/settings";

export function randomColor() {
	let hex = Math.floor(Math.random() * 0xFFFFFF);
	return "#" + hex.toString(16);
}

export function getImage(filename) {
	if (!filename)
		return null;
	return `${SETTINGS.BackendURL}public/${filename}`;
}

export function printDiv(elem) {
	var header_str = '<html><head><title>' + document.title + '</title></head><body>';
	var footer_str = '</body></html>';
	var new_str = document.getElementById(elem).innerHTML;
	var old_str = document.body.innerHTML;
	document.body.innerHTML = header_str + new_str + footer_str;
	window.print();
	document.body.innerHTML = old_str;
	return false;
}