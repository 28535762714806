/*
    Imports
*/
import { FieldArray, Form, FormikProvider, useFormik } from 'formik';
import { Children, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useNavigate } from 'react-router-dom';

/*
    Imports:
        Material UI
*/
import { Box, FormHelperText, Grid, InputLabel, TextField, Typography } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import Autocomplete from '@mui/material/Autocomplete';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import DatePicker from '@material-ui/lab/DatePicker';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';

/*
    Imports:
        Our Imports
        Components and Settings
        Services
*/
import Dialog from 'src/components/misc/alerts/Dialog';
import ServerError from 'src/components/misc/alerts/ServerError';
import LoadingFormButton from 'src/components/misc/Buttons/LoadingFormButton';
import { GenerateRsoBillSchema } from 'src/config/form-schemas';
import { RoutePayments } from 'src/config/routes';
import billService from 'src/services/BillServiceClass';
import { fNumber } from 'src/utils/formatNumber';
import { ContentStyle, FormTheme } from '../../theme/form-pages';
import { SETTINGS } from 'src/config/settings';

/*
    Main Working
*/
export default ({ products, rsos, rsoUsername }) => {
    /*
        States, Params, Navigation, Query, Variables.
    */
    let prodName = products.map((a) => a.name);

    const [serverError, setServerError] = useState('');
    const [openDia, setOpenDia] = useState(false);
    const [billID, setBillId] = useState(0);
    const [value, setValue] = useState(rsoUsername ? rsos : rsos[0]);
    const [inputValue, setInputValue] = useState(rsoUsername ? rsoUsername : rsos[0]?.username);
    const [userRank, setUserRank] = useState(value.rank == SETTINGS.RANKS.rso)

    const navigate = useNavigate();

    /*
        Form Setup
    */

    const formik = useFormik({
        initialValues: {
            rso: rsoUsername ? rsos : rsos[0] ? rsos[0] : null,
            tDiscount: '',
            date: new Date(),
            items: [
                {
                    product: products[0].name,
                    price: userRank ? products[0].sale_p : products[0].price,
                    oprice: userRank ? products[0].sale_p : products[0].price,
                    quantity: 0,
                    tax: 0,
                    left: products[0].left,
                    discount: 0,
                    total: 0,
                    amount: 0
                }
            ],
        },
        validationSchema: GenerateRsoBillSchema,
        onSubmit: (_values, { setFieldError }) => {
            addData();
        }
    });

    const {
        values,
        errors,
        touched,
        isSubmitting,
        handleSubmit,
        getFieldProps,
        setFieldValue,
        setSubmitting,
        setFieldError,
        resetForm
    } = formik;

    /*
        Handlers
    */
    const addData = () => {
        setSubmitting(true);
        console.log(value);
        values.rso = value.id;
        let valuess = { ...values };
        console.log(valuess)

        const newitems = valuess.items.map((item) => {
            let prod = products.find((prod) => prod.name == item.product)
            item.oprice = item.price
            item.price = item.oprice - item.discount;
            if (prod.type == "Percentage")
                item.price = ((item.price * item.amount) / item.quantity) - item.discount;

            return { ...item, product: prod.id };
        });

        valuess.items = newitems;



        let { tDiscount, ...rest } = valuess;
        rest.items.forEach((o) => {
            delete o.left;
            delete o.total;
            delete o.discount;
            delete o.amount;
        });

        rest.csr_stock = (value.rank == SETTINGS.RANKS.csr)

        console.log(rest);

        billService
            .addRso(rest)
            .then((response) => {
                setBillId(response.id);
                setOpenDia(true);
            })
            .catch((err) => {
                console.log('Error', err, err.response);
                if (err.response) {
                    for (const key in values) {
                        if (Object.hasOwnProperty.call(values, key)) {
                            if (err.response.data.message.includes(key))
                                setFieldError(key, 'Invalid or Already Added');
                        }
                    }
                }
                console.log('Error', err, err.response);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    };

    const handleClose = () => {
        setOpenDia(false);
        resetForm(true);
    };
    const handlePrintE_Bill = () => {
        openInNewTab(`/bills/rso/${billID}/print`);
    };
    const handlePrintBill = () => {
        openInNewTab(`/bills/rso/${billID}/print-thermal`);
    };

    const handleProductsChange = () => {
        const lastIndex = values.items.length - 1;
        const prodType = (products[0].type == "Fixed");
        console.log(prodType)

        if (values.items[lastIndex].quantity) {
            const u = [...values.items];
            u.push({
                product: products[0].name,
                price: userRank && prodType ? products[0].sale_p : products[0].price,
                oprice: userRank && prodType ? products[0].sale_p : products[0].price,
                quantity: 0,
                tax: 0,
                left: products[0].left,
                discount: 0,
                total: 0,
                amount: 0
            });
            setFieldValue('items', u);
        }

        setServerError('');
    };

    function getCurrentTotal() {
        return values.items.reduce((total, item) => (total += (userRank ? item.sale_p : item.price) * item.quantity), 0);
    }

    function getTotalDiscount() {
        return values.items.reduce((total, item) => (total += +item.quantity * +item.discount), 0);
    }

    function getGrandTotal() {
        return values.items.reduce((total, item) => (total += item.total), 0);
    }

    const handleDiscountDistribustion = () => {
        if (values.tDiscount.length > 0 && values.tDiscount != 0 && values.tDiscount != NaN) {
            const num = +values.tDiscount.replace('%', '');
            const total = getCurrentTotal();

            let percent = 0;

            if (values.tDiscount.includes('%')) percent = num / 100;
            else if (total != 0) percent = num / total;

            for (const item of values.items) {
                item.discount = 0;
                const discountPerItem = +item.price * percent;
                if (discountPerItem > 1) item.discount = Math.floor(discountPerItem);
                else item.discount = discountPerItem.toFixed(3);
            }
        } else values.tDiscount = 0;
    };

    const handleUserChange = () => {
        setUserRank(values.rso.rank == SETTINGS.RANKS.rso)

        if (values.items.length > 0)
            for (const index in values.items) {
                const items = handleProductChange(index, values.items[index].product)
                setFieldValue('items', items)
                console.log(values.items)
            }
    }

    const handleProductChange = (index, newValue) => {

        const rank = (values.rso.rank == SETTINGS.RANKS.rso)

        console.log("I AM CALLED", rank)

        const items = values.items;
        const item = items[index];
        const prod = products.find(
            (a) => a.name == newValue
        );

        item.product = newValue;
        item.price =
            (rank && prod.type == "Fixed" ?
                prod.sale_p :
                prod.price
            );

        item.oprice =
            (rank && prod.type == "Fixed" ?
                prod.sale_p :
                prod.price
            );

        item.left = prod.left;

        return items
    }
    /*
        Use Effect Hooks.
    */

    useEffect(handleProductsChange, [values.items]);

    useEffect(handleUserChange, [values.rso]);

    useEffect(handleDiscountDistribustion, [values.tDiscount, values.items]);

    // useEffect(handleProductChange, [values.items])

    /*
        Main Design
    */

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <ContentStyle>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={6}>
                            <ThemeProvider theme={FormTheme}>
                                <InputLabel label="date">Date</InputLabel>
                            </ThemeProvider>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    value={values.date}
                                    onChange={(newValue) => {
                                        setFieldValue('date', newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            {...params}
                                            {...getFieldProps('date')}
                                            error={Boolean(touched.date && errors.date)}
                                            helperText={touched.date && errors.date}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                </ContentStyle>
                <Typography variant="h6" gutterBottom>
                    Select Member
                </Typography>

                <ContentStyle>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={6}>
                            <Autocomplete
                                disableClearable={true}
                                options={rsos}
                                disabled={rsoUsername}
                                defaultValue={rsoUsername ? rsos : rsos[0]}
                                value={value}
                                onChange={(event, newValue) => {
                                    setValue(newValue);
                                    setFieldValue('rso', newValue)
                                }}
                                inputValue={inputValue}
                                onInputChange={(event, newInputValue) => {
                                    setInputValue(newInputValue);
                                }}
                                getOptionLabel={(option) => option.username}
                                renderOption={(props, option) => (
                                    <Box
                                        component="li"
                                        sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                        {...props}
                                    >
                                        {option.name} ({option.username}) + {option.number}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        disabled={!!rsoUsername}
                                        {...params}
                                        {...getFieldProps('rso')}
                                    />
                                )}
                            />
                            <FormHelperText error>{errors ? errors.rso : ''}</FormHelperText>
                        </Grid>
                    </Grid>
                </ContentStyle>
                <Typography variant="h6" gutterBottom>
                    Bill Details
                </Typography>
                <FieldArray name="items">
                    <>
                        {Children.toArray(
                            values.items.map((product, index) => (
                                <ContentStyle>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={4} md={4}>
                                            <ThemeProvider theme={FormTheme}>
                                                <InputLabel>Product</InputLabel>
                                            </ThemeProvider>
                                            <Autocomplete
                                                disableClearable={true}
                                                options={prodName}
                                                defaultValue={prodName[0]}
                                                onChange={(event, newValue) => {
                                                    const items = handleProductChange(index, newValue)
                                                    setFieldValue('items', items);

                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        fullWidth
                                                        {...getFieldProps(`items.${index}.product`)}
                                                    />
                                                )}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={2} md={2}>
                                            <ThemeProvider theme={FormTheme}>
                                                <InputLabel>Price</InputLabel>
                                            </ThemeProvider>
                                            <NumberFormat
                                                name={`items.${index}.price`}
                                                fullWidth
                                                customInput={TextField}
                                                type="text"
                                                allowEmptyFormatting="true"
                                                inputProps={{
                                                    inputMode: 'numeric'
                                                }}
                                                {...getFieldProps(`items.${index}.price`)}
                                            />
                                            <FormHelperText>
                                                Orignal price: {values.items[index].price > values.items[index].oprice ?
                                                    values.items[index].price :
                                                    values.items[index].oprice
                                                }
                                            </FormHelperText>
                                            <FormHelperText error={true}>
                                                {errors?.items?.length > 0 &&
                                                    errors.items[index]?.price}
                                            </FormHelperText>
                                        </Grid>
                                        {products.find((a) => a.name == values.items[index].product)
                                            ?.type == 'Fixed' && (
                                                <Grid item xs={12} sm={2} md={2}>
                                                    <ThemeProvider theme={FormTheme}>
                                                        <InputLabel>Quantity</InputLabel>
                                                    </ThemeProvider>
                                                    <NumberFormat
                                                        fullWidth
                                                        customInput={TextField}
                                                        type="text"
                                                        allowEmptyFormatting="true"
                                                        inputProps={{
                                                            inputMode: 'numeric'
                                                        }}
                                                        {...getFieldProps(`items.${index}.quantity`)}
                                                    />
                                                    <FormHelperText>
                                                        In Stock: {fNumber(values.items[index].left)}
                                                    </FormHelperText>
                                                    <FormHelperText error={true}>
                                                        {errors?.items?.length > 0 &&
                                                            errors.items[index]?.quantity}
                                                    </FormHelperText>
                                                </Grid>
                                            )}
                                        {products.find((a) => a.name == values.items[index].product)
                                            ?.type == 'Percentage' && (
                                                <Grid item xs={12} sm={2} md={2}>
                                                    <ThemeProvider theme={FormTheme}>
                                                        <InputLabel>Amount</InputLabel>
                                                    </ThemeProvider>
                                                    <NumberFormat
                                                        fullWidth
                                                        customInput={TextField}
                                                        type="text"
                                                        allowEmptyFormatting="true"
                                                        inputProps={{
                                                            inputMode: 'numeric'
                                                        }}
                                                        {...getFieldProps(`items.${index}.amount`)}
                                                    />
                                                    <FormHelperText
                                                        {...getFieldProps(`items.${index}.quantity`)}
                                                    >
                                                        Quanitiy:
                                                        {
                                                            (values.items[index].quantity =
                                                                values.items[index].amount *
                                                                (
                                                                    +values.items[index].price +
                                                                    products.find(
                                                                        (a) =>
                                                                            a.name ==
                                                                            values.items[index].product
                                                                    ).sale_p /
                                                                    100
                                                                ).toFixed(3))
                                                        }
                                                    </FormHelperText>
                                                    <FormHelperText>
                                                        In Stock: {fNumber(values.items[index].left)}
                                                    </FormHelperText>
                                                    <FormHelperText error={true}>
                                                        {errors?.items?.length > 0 &&
                                                            errors.items[index]?.quantity}
                                                    </FormHelperText>
                                                </Grid>
                                            )}
                                        <Grid item xs={12} sm={1} md={1}>
                                            <ThemeProvider theme={FormTheme}>
                                                <InputLabel>Discount</InputLabel>
                                            </ThemeProvider>
                                            <NumberFormat
                                                fullWidth
                                                customInput={TextField}
                                                type="text"
                                                allowEmptyFormatting="true"
                                                inputProps={{
                                                    inputMode: 'numeric'
                                                }}
                                                disabled={!!values.tDiscount}
                                                {...getFieldProps(`items.${index}.discount`)}
                                            />
                                            <FormHelperText>
                                                Total Discount:{' '}
                                                {Math.round(
                                                    (
                                                        values.items[index].discount *
                                                        values.items[index].quantity
                                                    ).toFixed(3)
                                                )}
                                            </FormHelperText>
                                            <FormHelperText error={true}>
                                                {errors?.items?.length > 0 &&
                                                    errors.items[index]?.discount}
                                            </FormHelperText>
                                        </Grid>

                                        <Grid item xs={12} sm={1} md={1}>
                                            <ThemeProvider theme={FormTheme}>
                                                <InputLabel>Tax</InputLabel>
                                            </ThemeProvider>
                                            <NumberFormat
                                                fullWidth
                                                customInput={TextField}
                                                type="text"
                                                allowEmptyFormatting="true"
                                                inputProps={{
                                                    inputMode: 'numeric'
                                                }}
                                                {...getFieldProps(`items.${index}.tax`)}
                                            />
                                            <FormHelperText>
                                                Total Tax:{' '}
                                                {values.items[index].tax *
                                                    values.items[index].quantity}
                                            </FormHelperText>
                                            <FormHelperText error={true}>
                                                {errors?.items?.length > 0 &&
                                                    errors.items[index]?.tax}
                                            </FormHelperText>
                                        </Grid>

                                        <Grid item xs={12} sm={2} md={2}>
                                            <ThemeProvider theme={FormTheme}>
                                                <InputLabel>Total Rs. </InputLabel>
                                            </ThemeProvider>
                                            <TextField
                                                disabled={true}
                                                value={
                                                    products.find((a) => a.name == values.items[index].product)
                                                        ?.type == 'Percentage' ?
                                                        fNumber(
                                                            (values.items[index].total =
                                                                Math.round(
                                                                    +values.items[index].amount *
                                                                    (+values.items[index].price +
                                                                        +values.items[index].tax -
                                                                        +values.items[index].discount)
                                                                )
                                                            )
                                                        ) :

                                                        fNumber(
                                                            (values.items[index].total =
                                                                Math.round(
                                                                    +values.items[index].quantity *
                                                                    (+values.items[index].price +
                                                                        +values.items[index].tax -
                                                                        +values.items[index].discount)
                                                                )
                                                            )
                                                        )
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </ContentStyle>
                            ))
                        )}
                    </>
                </FieldArray>
                <ContentStyle>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={6}>
                            <ThemeProvider theme={FormTheme}>
                                <InputLabel label="totalDiscount">
                                    Total Discount {fNumber(Math.round(getTotalDiscount()))}
                                </InputLabel>
                            </ThemeProvider>
                            <TextField
                                fullWidth
                                {...getFieldProps('tDiscount')}
                                inputProps={{
                                    inputMode: 'numeric'
                                }}
                                error={Boolean(touched.tDiscount && errors.tDiscount)}
                                helperText={touched.tDiscount && errors.tDiscount}
                            />
                        </Grid>
                    </Grid>
                </ContentStyle>
                <ContentStyle>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={6}>
                            <ThemeProvider theme={FormTheme}>
                                <InputLabel label="grandTotal">
                                    Total Amount {fNumber(Math.round(getGrandTotal()))}
                                </InputLabel>
                            </ThemeProvider>
                        </Grid>
                    </Grid>
                </ContentStyle>

                <Dialog
                    buttonText={'Close'}
                    buttonText2={'eBill'}
                    buttonText3={'Bill'}
                    openDialog={openDia}
                    handleButton={handleClose}
                    handleButton2={handlePrintE_Bill}
                    handleButton3={handlePrintBill}
                >
                    {`Bill is added`}
                </Dialog>

                <LoadingFormButton disabled={!!serverError} loading={isSubmitting}>
                    {'Add'}
                </LoadingFormButton>
                <ServerError open={!!serverError}>{serverError}</ServerError>
            </Form>
        </FormikProvider>
    );
};
