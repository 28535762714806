import GenericService from './GenericService';
import qs from 'qs';

class UserService extends GenericService {
    login = (ID, Password) =>
        new Promise((resolve, reject) => {
            this.post('auth', {
                username: ID,
                password: Password,
            })
                .then((data) => {
                    localStorage.setItem('user', JSON.stringify(data.user));
                    localStorage.setItem('token', data.token);
                    this.tokenUpdate();
                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });

    logout = () =>
        new Promise((resolve, reject) => {
            this.get('auth/logout')
                .then(() => {
                    localStorage.removeItem('user');
                    localStorage.removeItem('token');
                    this.tokenUpdate();
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                });
        });

    getAll = () => this.get(`users`);

    getRetired = () => this.get(`users/retired`);

    isLoggedIn = async () =>
        new Promise((resolve, reject) => {
            this.get('/')
                .then((res) => {
                    const { loggedIn } = res;
                    console.log('Is Logged In', loggedIn);
                    if (loggedIn) return resolve();
                    localStorage.removeItem('user');
                    reject('Not logged in');
                })
                .catch((err) => {
                    reject(err);
                });
        });

    getLoggedInUser() {
        const sUser = localStorage.getItem('user');
        const token = localStorage.getItem('token');

        if (!token) return null;
        if (sUser)
            return JSON.parse(sUser);
        else return null;
    }

    getUser = (ID, date) => this.get(`users/${ID}?${qs.stringify(date)}`);

    addUser = (data, file, userID, onUploadProgress) =>
        this.postForm(`users/register`, data, file, onUploadProgress);

    updateMe = (data, file, onUploadProgress) =>
        new Promise((resolve, reject) => {
            this.patchForm(`users`, data, file, onUploadProgress)
                .then((data) => {
                    localStorage.setItem('user', JSON.stringify(data.data));
                    resolve(data.data);
                })
                .catch((err) => {
                    console.log('Error', err);
                    reject(err);
                });
        });

    updateUser = (data, file, userID, onUploadProgress) =>
        this.patchForm(`users/${userID}`, data, file, onUploadProgress);

    forgetPassword = (data) => this.post('auth/forget', data);

    resetPassword = (data) => this.post('auth/reset', data);
}

export default UserService;
