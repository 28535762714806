/*
    Imports
*/
import { Container } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CenterLoading from 'src/components/misc/CenterLoading';
import ListPageTitle from 'src/components/misc/ListPageTitle';
import Page from 'src/components/Page';
import productsService from 'src/services/ProductsServiceClass';
import AddStockForm from './forms/AddStockForm';

/*
    Main Working
*/
export default () => {

  const navigate = useNavigate();

  const [products, setProducts] = useState();

  const getData = () => {
    productsService.getAll()
      .then((data) => {
        setProducts(data);
      })
      .catch((error) => {
        if (error.response) if (error.response.status === 401) navigate('/401');

      });

  };

  useEffect(getData, []);

  const loading = !products;

  /*
    Main Design
  */
  return (
    <Page title={`Add Stock`} >
      <Container maxWidth="xl">
        <ListPageTitle>
          {"Adding Stock"}
        </ListPageTitle>
        {loading ?
          <CenterLoading />
          :
          <AddStockForm products={products} />
        }

      </Container>
    </Page >
  );
};
