import GenericService from './GenericService';
import qs from 'qs';

class ServiceClass extends GenericService {
    constructor() {
        super();
    }

    getCommissions = (pagination, date) =>
        this.get(`/commissions?${qs.stringify(pagination)}&${qs.stringify(date)}`);

    add = (data, ID) => this.post('/commissions', data);

    update = (data, ID) => this.patch(`/commissions/${ID}`, data);

    getOne = (ID) => this.get(`/commissions/${ID}`);

    remove = (ID) => this.delete(`/commissions/${ID}`);
}

const commissionsService = new ServiceClass();

export default commissionsService;
