/*
  Imports
*/
import { Grid, Typography } from '@material-ui/core';
import { getDateString } from 'src/utils/dateTime';
import JsonFormatter from 'react-json-formatter';

/*
  Main Working
*/
export default ({ data }) => {
  /*
    States, Params, Navigation, Query, Variables.
  */
  const { name, details, changes, date } = data;

  const jsonStyle = {
    propertyStyle: { color: 'black' },
    stringStyle: { color: 'green' },
    numberStyle: { color: 'darkorange' }
  };

  /*
    Main Design
  */
  return (
    <>
      <Grid container spacing={0} style={{ paddingBottom: 20, paddingTop: 20 }}>
        <Grid item xs={12} sm={2} md={2}>
          <Typography fontWeight="bold">Date: </Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <Typography>{getDateString(date)}</Typography>
        </Grid>
        <Grid item xs={12} sm={2} md={2}>
          <Typography fontWeight="bold">Name: </Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <Typography>{name}</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={0} style={{ paddingBottom: 10, paddingTop: 10 }}>
        <Grid item xs={12} sm={2} md={2}>
          <Typography fontWeight="bold">Details: </Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <Typography>{details}</Typography>
        </Grid>
      </Grid>

      <br />
      <br />
      <br />

      <JsonFormatter json={changes} tabWith={4} jsonStyle={jsonStyle} />
    </>
  );
};
