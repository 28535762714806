import GenericService from './GenericService';
import qs from 'qs';

class ServiceClass extends GenericService {
    constructor() {
        super();
    }

    add = (data, id) => this.post('/services', data);

    update = (data, id) => this.patch(`/services/${id}`, data);

    getAll = () => this.get('/services');

    getOne = (ID) => this.get(`/services/${ID}`);


    remove = (ID) => this.delete(`/services/${ID}`);
}

const servicesService = new ServiceClass();

export default servicesService;
