/*
    Imports
*/
import { Icon } from '@iconify/react';
import {
    Alert,
    Avatar,
    Box,
    Button,
    Collapse,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    Stack,
    TextField,
    Typography
} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { Form, FormikProvider, useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AlertSnackbar from 'src/components/misc/alerts/AlertSnackbar';
import Dialog from 'src/components/misc/alerts/Dialog';
import LoadingFormButton from 'src/components/misc/Buttons/LoadingFormButton';
import { SettingsSchema } from 'src/config/form-schemas';
import { HidePasswordIcon, PasswordIcon, ShowPasswordIcon } from 'src/config/icons';
import { SETTINGS } from 'src/config/settings';
import { DashboardContext } from 'src/layouts/dashboard/DashboardLayout';
import userService from 'src/services/UserService';
import { getImage } from 'src/utils/misc';
import { ContentStyle, FormTheme } from '../../theme/form-pages';

/*
        Main Working
*/
export default ({ loggedInUser }) => {
    /*
          States, Params, Navigation, Query, Variables.
  */
    const [serverError, setServerError] = useState('');
    const [password, setPassword] = useState('');
    const [openDia, setOpenDia] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const navigate = useNavigate();

    const [selectedImage, setSelectedImage] = useState(null);
    const [imageUrl, setImageUrl] = useState(getImage(loggedInUser.image));
    const [wrongFile, setWrongFile] = useState(false);

    const userType = (useParams().type || '').toLowerCase();
    const userID = useParams().id;
    const ownSettings = !userID;

    const { reload } = useContext(DashboardContext);

    /*
          Form Setup
  */

    const formik = useFormik({
        initialValues: {
            name: loggedInUser.name,
            email: loggedInUser.email,
            image: loggedInUser.image ? loggedInUser.image : null,
            bank: loggedInUser.bank,
            account: loggedInUser.account,
            username: loggedInUser.username,
            number: loggedInUser.number
        },
        validationSchema: SettingsSchema(password),
        onSubmit: () => {
            addData();
        }
    });

    const {
        values,
        errors,
        touched,
        isSubmitting,
        handleSubmit,
        getFieldProps,
        setFieldValue,
        setSubmitting
    } = formik;

    /*
    Handlers
  */

    const addData = () => {
        setWrongFile(false);
        const updateValues = { ...values };
        delete updateValues.image;
        userService
            .updateMe(updateValues, selectedImage)
            .then(() => {
                userService
                    .isLoggedIn()
                    .then(() => {
                        setOpenDia(true);
                    })
                    .catch((err) => {
                        console.log('Error', err, err.response);
                    });
            })
            .catch((err) => {
                if (err.fileUploadError) {
                    setImageUrl(loggedInUser.image);
                    setWrongFile(err.msg);
                    setTimeout(() => setWrongFile(false), hideFileAlertIn);
                }
                setServerError('An Error Occured');
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const handleImageChange = () => {
        if (selectedImage) {
            setImageUrl(URL.createObjectURL(selectedImage));
        }
    };

    const handleClose = () => {
        setOpenDia(false);
        reload();
        navigate(`../profile`);
    };

    const handleShowPassword = () => {
        setShowPassword((show) => !show);
    };
    const handlePasswordUpdate = () => {
        setPassword(values.password);
    };

    /*
      Use Effect Hooks.
  */

    useEffect(handleImageChange, [selectedImage]);
    useEffect(handlePasswordUpdate, [values.password]);

    /*
          Main Design
  */
    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Typography variant="h6" gutterBottom>
                    Personal Details
                </Typography>

                <ContentStyle>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={6}>
                            <ThemeProvider theme={FormTheme}>
                                <InputLabel>Name</InputLabel>
                            </ThemeProvider>
                            <TextField
                                fullWidth
                                autoComplete="name"
                                {...getFieldProps('name')}
                                error={Boolean(touched.name && errors.name)}
                                helperText={touched.name && errors.name}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <ThemeProvider theme={FormTheme}>
                                <InputLabel>User Name</InputLabel>
                            </ThemeProvider>
                            <TextField
                                fullWidth
                                autoComplete="username"
                                {...getFieldProps('username')}
                                error={Boolean(touched.username && errors.username)}
                                helperText={touched.username && errors.username}
                            />
                        </Grid>
                    </Grid>
                </ContentStyle>
                <ContentStyle>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={6}>
                            <ThemeProvider theme={FormTheme}>
                                <InputLabel>Email</InputLabel>
                            </ThemeProvider>
                            <TextField
                                fullWidth
                                autoComplete="currentEmail"
                                {...getFieldProps('email')}
                                inputProps={{
                                    inputMode: 'email'
                                }}
                                error={Boolean(touched.email && errors.email)}
                                helperText={touched.email && errors.email}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <ThemeProvider theme={FormTheme}>
                                <InputLabel>Contact</InputLabel>
                            </ThemeProvider>
                            <TextField
                                fullWidth
                                autoComplete="number"
                                type="text"
                                format="####-#######"
                                {...getFieldProps('number')}
                                inputProps={{
                                    inputMode: 'number'
                                }}
                                error={Boolean(touched.number && errors.number)}
                                helperText={touched.number && errors.number}
                            />
                        </Grid>
                    </Grid>
                </ContentStyle>

                <ContentStyle>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={6}>
                            <ThemeProvider theme={FormTheme}>
                                <InputLabel>Bank</InputLabel>
                            </ThemeProvider>
                            <TextField
                                fullWidth
                                autoComplete="bank"
                                {...getFieldProps('bank')}
                                error={Boolean(touched.bank && errors.bank)}
                                helperText={touched.bank && errors.bank}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <ThemeProvider theme={FormTheme}>
                                <InputLabel>Account #</InputLabel>
                            </ThemeProvider>
                            <TextField
                                fullWidth
                                autoComplete="account"
                                {...getFieldProps('account')}
                                inputProps={{
                                    inputMode: 'numeric'
                                }}
                                error={Boolean(touched.account && errors.account)}
                                helperText={touched.account && errors.account}
                            />
                        </Grid>
                    </Grid>
                </ContentStyle>

                <ContentStyle>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={6}>
                            <input
                                accept={SETTINGS.acceptImageUpload}
                                type="file"
                                id="select-image"
                                style={{ display: 'none' }}
                                onChange={(e) => setSelectedImage(e.target.files[0])}
                            />
                            <label htmlFor="select-image">
                                <Button variant="outlined" color="primary" component="span">
                                    Upload Image
                                </Button>
                            </label>
                            {imageUrl && (
                                <Box mt={2} textAlign="center">
                                    <Avatar alt={values.name} src={imageUrl} />
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                </ContentStyle>

                <ContentStyle>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={6}>
                            <ThemeProvider theme={FormTheme}>
                                <InputLabel>New Password</InputLabel>
                            </ThemeProvider>
                            <TextField
                                fullWidth
                                type={showPassword ? 'text' : 'password'}
                                {...getFieldProps('password')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleShowPassword} edge="end">
                                                <Icon
                                                    icon={
                                                        showPassword
                                                            ? HidePasswordIcon
                                                            : ShowPasswordIcon
                                                    }
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon
                                                icon={PasswordIcon}
                                                inline="true"
                                                style={{ fontSize: 20 }}
                                            />
                                        </InputAdornment>
                                    )
                                }}
                                error={Boolean(touched.password && errors.password)}
                                helperText={touched.password && errors.password}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <ThemeProvider theme={FormTheme}>
                                <InputLabel>Confirm Password</InputLabel>
                            </ThemeProvider>
                            <TextField
                                fullWidth
                                type={showPassword ? 'text' : 'password'}
                                {...getFieldProps('confirm')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleShowPassword} edge="end">
                                                <Icon
                                                    icon={
                                                        showPassword
                                                            ? HidePasswordIcon
                                                            : ShowPasswordIcon
                                                    }
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon
                                                icon={PasswordIcon}
                                                inline="true"
                                                style={{ fontSize: 20 }}
                                            />
                                        </InputAdornment>
                                    )
                                }}
                                error={Boolean(touched.confirm && errors.confirm)}
                                helperText={touched.confirm && errors.confirm}
                            />
                        </Grid>
                    </Grid>
                </ContentStyle>

                <Dialog buttonText={'Close'} openDialog={openDia} handleButton={handleClose}>
                    Your data is updated
                </Dialog>
                <AlertSnackbar severity="warning" open={wrongFile}>
                    Extension not allowed
                </AlertSnackbar>

                <LoadingFormButton loading={isSubmitting}>Save</LoadingFormButton>
                {serverError && (
                    <Stack sx={{ width: '50%' }} marginTop={3}>
                        <Collapse in={!!serverError}>
                            <Alert severity="error">{serverError}</Alert>
                        </Collapse>
                    </Stack>
                )}
            </Form>
        </FormikProvider>
    );
};
