/*
  Imports
*/
import { Container } from '@material-ui/core';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import accountingService from 'src/services/AccountingServiceClass';
import Page from '../components/Page';
import ListPageTitle from 'src/components/misc/ListPageTitle';
import DatePickerWithSearch from '../components/misc/table/DatePickerWithSearch';
import AccountsList from './tables/AccountsList';
/*
  Main Working
*/
export default () => {
    /*
    States, Params, Navigation, Query, Variables.
  */
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);

    const [data, setData] = useState([]);

    const [date, setDate] = useState({
        startDate: null,
        endDate: null,
    });

    const title = `Accounts`;

    /*
      Handlers, Functions
    */
    const getData = () => {
        setLoading(true);
        accountingService
            .allAccounts(date)
            .then((res) => {
                console.log('Data', res);
                setData(res);
            })
            .catch((error) => {
                if (error.response) if (error.response.status === 401) navigate('/401');
                navigate('/404');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    /*
      Use Effect Hooks.
    */
    useEffect(getData, [date]);

    /*
    Use Effect Hooks.
  */
    /*
    Main Design
  */
    return (
        <Page title={title}>
            <Container>
                <ListPageTitle>{title}</ListPageTitle>

                <DatePickerWithSearch setDate={setDate} />
                <AccountsList data={data} loading={loading} />
            </Container>
        </Page>
    );
};
