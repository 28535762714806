/*
    Imports
*/
import { Container } from '@material-ui/core';
import { useEffect, useState } from 'react';
import CenterLoading from 'src/components/misc/CenterLoading';
import ListPageTitle from 'src/components/misc/ListPageTitle';
import Page from 'src/components/Page';
import otherService from 'src/services/OtherService';
import AddMemberForm from './forms/AddMemberForm';

/*
    Main Working
*/
export default ({ editing }) => {
  const [ranks, setRanks] = useState([]);
  const [loading, setLoading] = useState(true);

  function getRank() {
    setLoading(true);
    otherService
      .getRanks()
      .then((data) => {
        setRanks(data);
      })
      .catch((_err) => {
        //Error in getting coruses
        console.log(_err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    getRank();
  }, []);

  /*
    Main Design
  */

  return (
    <Page title={editing ? `Edit Member` : `Add Member`}>
      <Container maxWidth="xl">
        <ListPageTitle>{editing ? 'Editing Member' : 'Adding Member'}</ListPageTitle>
        {loading ? <CenterLoading /> : <AddMemberForm ranks={ranks} editing={editing} />}
      </Container>
    </Page>
  );
};
