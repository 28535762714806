/*
  Imports
*/
import { Container } from '@material-ui/core';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import productsService from 'src/services/ProductsServiceClass';
import CenterLoading from 'src/components/misc/CenterLoading';
import Page from '../components/Page';
import SingleProductDetails from './body/SingleProductDetails';
import StocksDetails from './tables/StocksDetails';
import stocksService from 'src/services/StocksServiceClass';
import ListPageTitle from 'src/components/misc/ListPageTitle';
import { SETTINGS } from 'src/config/settings';
import FloatingAdd from 'src/components/misc/Buttons/FloatingButton';
import { StockIcon } from 'src/config/icons';
import servicesService from 'src/services/ServicesServiceClass';
import QRCode from 'react-qr-code';
import { Button } from '@mui/material';
import { printDiv } from 'src/utils/misc';
import SingleServiceDetails from './body/SingleServiceDetails';
import SingleServiceProducts from './tables/SingleServiceProducts';

/*
  Main Working
*/
export default () => {
    /*
    States, Params, Navigation, Query, Variables.
  */
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);

    const [data, setData] = useState({
        items: []
    });

    const { id } = useParams();

    const title = `Service Details`;

    /*
      Handlers, Functions
    */
    const getData = () => {
        setLoading(true);
        servicesService
            .getOne(id)
            .then((res) => {
                console.log('Service', res);
                setData(res);
            })
            .catch((error) => {
                if (error.response) if (error.response.status === 401) navigate('/401');
                navigate('/404');
            })
            .finally(() => {
                setLoading(false);
            });
    };


    useEffect(getData, []);


    return (
        <Page title={title}>
            <Container>
                {loading ? (
                    <CenterLoading />
                ) : (
                    <>
                        <ListPageTitle>{title}</ListPageTitle>
                        <SingleServiceDetails data={data} />
                        <br />
                        <SingleServiceProducts data={data.items} />
                        <br />
                        <div
                            id='qrCode'
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column'
                            }}

                        >
                            <ListPageTitle>{data.name} - QR Code</ListPageTitle>
                            <QRCode
                                value={JSON.stringify([{
                                    type: 'service',
                                    id: data.id
                                }])}
                                size={200}
                                bgColor={"#ffffff"}
                                fgColor={"#000000"}
                                level={"L"}
                                includeMargin={true}
                                renderAs={"svg"}
                            ></QRCode>
                            <br />
                            <Button
                                variant="contained"
                                onClick={() => printDiv('qrCode')}
                            >
                                Print
                            </Button>
                        </div>


                        <br />
                    </>
                )}
            </Container>
        </Page>
    );
};
