/*
  Imports
*/
import { Card, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import { useState } from 'react';
import palette from 'src/theme/palette';
import { getDateString } from 'src/utils/dateTime';
import { fNumber } from 'src/utils/formatNumber';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/misc/alerts/SearchNotFound';
import { UserListHead } from '../../components/misc/table';

/*
  Global Variables, Functions
*/

/*
  Main Working
*/
export default ({ data, pagination, setPagination }) => {
    /*
    States, Params, Navigation, Query, Variables.
  */

    const TableHeader = [
        { id: 'date', label: 'Date', align: 'left' },
        { id: 'quantity', label: 'Quantity', align: 'left' },
        { id: 'total', label: 'Total', align: 'left' },
        { id: 'expense', label: 'Expense', align: 'left' },
        { id: 'received', label: 'Received', align: 'left' },
    ];

    const [selected, setSelected] = useState([]);

    const emptyRows = data.length;
    const isUserNotFound = data.length === 0;

    /*
    Handlers, Functions
  */
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = data.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    return (
        <>
            <Card>
                <Scrollbar>
                    <TableContainer sx={{ minWidth: 800 }}>
                        <Table>
                            <UserListHead
                                // order={pagination.order}
                                // orderBy={pagination.sort_by}
                                headLabel={TableHeader}
                                rowCount={data.length}
                                numSelected={selected.length}
                                // onRequestSort={handleRequestSort}
                                onSelectAllClick={handleSelectAllClick}
                                hideCheckBoxes={true}
                            />
                            <TableBody>
                                {data.map((row, id) => {
                                    let { date, quantity, total, expense, received } = row;

                                    quantity = quantity ? `${fNumber(quantity)}` : '-';
                                    total = total ? `Rs. ${fNumber(total)}` : '-';
                                    expense = expense ? `Rs. ${fNumber(expense)}` : '-';
                                    received = received ? `Rs. ${fNumber(received)}` : '-';

                                    const isItemSelected = selected.indexOf(id) !== -1;

                                    return (
                                        <TableRow
                                            hover
                                            key={id}
                                            tabIndex={-1}
                                            role="checkbox"
                                            selected={isItemSelected}
                                            aria-checked={isItemSelected}
                                            style={{
                                                backgroundColor: palette.background.hover,
                                                textDecoration: 'none',
                                            }}
                                        >
                                            <TableCell padding="checkbox"></TableCell>
                                            <TableCell align="left">
                                                {' '}
                                                {getDateString(date)}
                                            </TableCell>
                                            <TableCell align="left">{quantity}</TableCell>
                                            <TableCell align="left">{total}</TableCell>
                                            <TableCell align="left">{expense}</TableCell>
                                            <TableCell align="left">{received}</TableCell>
                                        </TableRow>
                                    );
                                })}
                                {emptyRows > 0 && (
                                    <TableRow style={{ emptyRows }}>
                                        <TableCell colSpan={TableHeader.length + 1} />
                                    </TableRow>
                                )}
                            </TableBody>
                            {isUserNotFound && (
                                <TableBody>
                                    <TableRow>
                                        <TableCell
                                            align="center"
                                            colSpan={TableHeader.length + 1}
                                            sx={{ py: 3 }}
                                        >
                                            <SearchNotFound />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Scrollbar>
            </Card>
        </>
    );
};
