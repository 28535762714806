/*
    Imports
*/
import { Container } from '@material-ui/core';
import ListPageTitle from 'src/components/misc/ListPageTitle';
import Page from 'src/components/Page';
import AddSlipForm from './forms/AddSlipForm';

/*
    Main Working
*/
export default ({ editing }) => {

    /*
      Main Design
    */
    return (
        <Page title={editing ? `Edit Slip` : `Add Slip`} >
            <Container maxWidth="xl">
                <ListPageTitle>
                    {editing ? `Editing Slip` : "Adding Slip"}
                </ListPageTitle>
                <AddSlipForm editing={editing} />
            </Container>
        </Page >
    );
};
