/*
  Imports
*/
import { Grid, Typography } from '@material-ui/core';
import { fNumber } from 'src/utils/formatNumber';
import Label from 'src/components/misc/Label';

/*
  Main Working
*/
export default ({ profit, loss }) => {
    /*
    States, Params, Navigation, Query, Variables.
  */

    /*
    Main Design
  */

    const total = profit - Math.abs(loss);

    return (
        <>
            <Grid container spacing={0} style={{ paddingBottom: 10, paddingTop: 20 }}>
                <Grid item xs={12} sm={2} md={2}>
                    <Typography fontWeight="bold">Profit Total: </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <Typography>Rs. {fNumber(profit)}</Typography>
                </Grid>
                <Grid item xs={12} sm={2} md={2}>
                    <Typography fontWeight="bold">Loss Total: </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <Typography>Rs. {fNumber(Math.abs(loss))}</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={0} style={{ paddingBottom: 10, paddingTop: 10 }}>
                <Grid item xs={12} sm={2} md={2}>
                    <Typography fontWeight="bold">Total: </Typography>
                </Grid>

                <Grid item xs={12} sm={4} md={4}>
                    <Label
                        variant="ghost"
                        color={(total > 0 && 'success') || (total < 0 && 'error') || 'info'}
                    >
                        Rs. {fNumber(Math.abs(total))}
                    </Label>
                </Grid>
            </Grid>
        </>
    );
};
