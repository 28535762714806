import GenericService from './GenericService';
import qs from 'qs';

class ServiceClass extends GenericService {
    constructor() {
        super();
    }

    add = (data, id) => this.post('/products', data);

    update = (data, id) => this.patch(`/products/${id}`, data);

    getAll = () => this.get('/products');

    getOne = (ID) => this.get(`/products/${ID}`);

    getRSOProducts = (ID, date) => this.get(`/products/rso/${ID}?${qs.stringify(date)}`);

    getCSRProducts = (ID, date) => this.get(`/products/csr/${ID}?${qs.stringify(date)}`);

    getRsoProduct = (user, product, pagination) => this.get(`/products/rso/${user}/${product}?${qs.stringify(pagination)}`);

    remove = (ID) => this.delete(`/products/${ID}`);
}

const productsService = new ServiceClass();

export default productsService;
