/*
    Imports
*/
import { Form, FormikProvider, useFormik } from 'formik';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

/*
    Imports:
        Material UI
*/
import { Grid, InputLabel, TextField, Typography, Select, MenuItem, Box } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { ThemeProvider } from '@material-ui/core/styles';
import Autocomplete from '@mui/material/Autocomplete';

/*
    Imports:
        Our Imports
        Components and Settings
        Services
*/
import Dialog from 'src/components/misc/alerts/Dialog';
import ServerError from 'src/components/misc/alerts/ServerError';
import LoadingFormButton from 'src/components/misc/Buttons/LoadingFormButton';
import { AddPaymentSchema } from 'src/config/form-schemas';
import { RoutePayments, RouteProducts } from 'src/config/routes';
import { ContentStyle, FormTheme } from '../../theme/form-pages';
import salaryService from 'src/services/SalaryServiceClass';
import userService from 'src/services/UserService';
import CenterLoading from 'src/components/misc/CenterLoading';

/*
    Main Working
*/
export default ({ account, user, editing }) => {

    /*
        States, Params, Navigation, Query, Variables.
    */
    const [serverError, setServerError] = useState('');
    const [openDia, setOpenDia] = useState(false);
    const [loading, setLoading] = useState(false);
    const [valueA, setValueA] = useState(account[0]);
    const [inputValueA, setInputValueA] = useState('');


    const navigate = useNavigate();

    const salaryID = useParams().salaryID;

    /*
        Form Setup
    */
    const formik = useFormik({
        initialValues: {
            member: user?.username,
            details: "Salary",
            amount: user?.salary,
            from_account: ''
        },
        validationSchema: AddPaymentSchema,
        onSubmit: (_values, { setFieldError }) => {
            addData();
        }
    });

    const { values, errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue, setSubmitting, resetForm, initialValues, setFieldError } = formik;

    /*
        Handlers
    */
    const addData = () => {
        setSubmitting(true);

        values.member = user?.id
        console.log(values, user)

        let FuncToCall = salaryService.add;
        if (editing) FuncToCall = salaryService.update;
        FuncToCall({ ...values }, salaryID)
            .then(() => {
                setOpenDia(true);
            })
            .catch((err) => {
                console.log("Error", err, err.response);
                if (err.response) {
                    for (const key in values) {
                        if (Object.hasOwnProperty.call(values, key)) {
                            if (err.response.data.message.includes(key))
                                setFieldError(key, 'Invalid or Already Added');
                        }
                    }
                }

                console.log("Error", err, err.response);
            }).finally(() => {
                setSubmitting(false);
            });
    };

    const handleClose = () => {
        setOpenDia(false);
        navigate(-1);
    };

    const handleEditing = () => {
        if (editing) {
            setLoading(true)
            salaryService.getOne(salaryID)
                .then((data) => {
                    if (!user) {
                        userService.getUser(data.member)
                            .then((data) => {
                                user = data;
                                setFieldValue('member', data.username)
                            })
                    }
                    setFieldValue('amount', data.amount);
                    setFieldValue('details', data.details);
                    setFieldValue('account', data.from_account);
                    setInputValueA(data.from_account);
                })
                .catch((_err) => console.log(_err.response))
                .finally(() => setLoading(false))
        }
    }

    /*
        Use Effect Hooks.
    */

    useEffect(handleEditing, []);

    /*
        Main Design
    */
    return (
        <>
            {loading &&
                <CenterLoading></CenterLoading>
            }
            {!loading &&
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>

                        <ContentStyle>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6} md={6}>
                                    <ThemeProvider theme={FormTheme}>
                                        <InputLabel>Member</InputLabel>
                                    </ThemeProvider>
                                    <TextField
                                        disabled={true}
                                        fullWidth
                                        {...getFieldProps('member')}
                                        error={Boolean(touched.member && errors.member)}
                                        helperText={touched.member && errors.member}
                                    />
                                </Grid>
                            </Grid>

                        </ContentStyle>

                        <ContentStyle>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6} md={6}>
                                    <ThemeProvider theme={FormTheme}>
                                        <InputLabel>Amount</InputLabel>
                                    </ThemeProvider>
                                    <TextField
                                        fullWidth
                                        customInput={TextField}
                                        autoComplete="number"
                                        type="text"
                                        {...getFieldProps('amount')}
                                        inputProps={{
                                            inputMode: 'numeric',
                                        }}
                                        error={Boolean(touched.amount && errors.amount)}
                                        helperText={touched.amount && errors.amount}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <ThemeProvider theme={FormTheme}>
                                        <InputLabel>Details</InputLabel>
                                    </ThemeProvider>
                                    <TextField
                                        fullWidth
                                        {...getFieldProps('details')}
                                        error={Boolean(touched.details && errors.details)}
                                        helperText={touched.details && errors.details}
                                    />
                                </Grid>
                            </Grid>

                        </ContentStyle>
                        <ContentStyle>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6} md={6}>
                                    <ThemeProvider theme={FormTheme}>
                                        <InputLabel>From Account</InputLabel>
                                    </ThemeProvider>
                                    <Autocomplete
                                        disableClearable={true}
                                        options={account}
                                        value={valueA}
                                        onChange={(event, newValue) => {
                                            setValueA(newValue);
                                        }}
                                        inputValue={inputValueA}
                                        onInputChange={(event, newInputValue) => {
                                            values.from_account = newInputValue;
                                            setInputValueA(newInputValue);
                                        }}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                fullWidth
                                                {...getFieldProps('from_account')}
                                                error={Boolean(touched.from_account && errors.from_account)}
                                                helperText={touched.from_account && errors.from_account}
                                            />}
                                    />
                                </Grid>
                            </Grid>
                        </ContentStyle>

                        <Dialog
                            buttonText={"Close"}
                            openDialog={openDia}
                            handleButton={handleClose}
                        >
                            {editing ? 'Salary is updated' : `Salary is added`}
                        </Dialog>

                        <LoadingFormButton loading={isSubmitting}>
                            {editing ? 'Edit' : 'Add'}
                        </LoadingFormButton>
                        <ServerError open={serverError}>
                            {serverError}
                        </ServerError>
                    </Form>
                </FormikProvider >
            }
        </>
    );
};

