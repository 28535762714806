/*
  Imports
*/
import {
    Avatar,
    Card,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    Typography,
} from '@material-ui/core';
import { filter } from 'lodash';
import { useState } from 'react';

import { SETTINGS } from 'src/config/settings';
import { Link, useNavigate } from 'react-router-dom';
import palette from 'src/theme/palette';
import { getImage } from 'src/utils/misc';
import { getComparator, stabilize } from 'src/utils/table';
import SearchNotFound from '../../components/misc/alerts/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../components/misc/table';
import Scrollbar from '../../components/Scrollbar';
import { EditIcon, ViewIcon, AddIcon } from 'src/config/icons';
import userService from 'src/services/UserService';
import { fNumber } from 'src/utils/formatNumber';
import Label from 'src/components/misc/Label';

/*
  Global Variables, Functions
*/

function applySortFilter(array, comparator, query) {
    let toSort = array;
    if (query) {
        toSort = filter(
            array,
            (element) =>
                element.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
                element.username.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
                String(element.receivable).toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
                String(element.payable).toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
                String(element.left).toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
                SETTINGS.RANKS_NAMES[element.rank].toLowerCase().indexOf(query.toLowerCase()) !==
                    -1,
        );
    }
    return stabilize(toSort, comparator);
}

/*
  Main Working
*/
export default ({ data }) => {
    /*
    States, Params, Navigation, Query, Variables.
  */

    const loggedInUser = userService.getLoggedInUser();

    const TableHeader = [
        { id: 'name', label: 'Name', align: 'left' },
        { id: 'username', label: 'Username', align: 'left' },
        { id: 'rank', label: 'Rank', align: 'left' },
        { id: 'receivable', label: 'Receivable', align: 'left' },
        { id: 'payable', label: 'Received', align: 'left' },
        { id: 'left', label: 'Left', align: 'left' },
    ];

    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState(TableHeader[2].id);
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(SETTINGS.defaultPerPage);

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;
    const filteredUsers = applySortFilter(data, getComparator(order, orderBy), filterName);
    const isUserNotFound = filteredUsers.length === 0;

    /*
    Handlers, Functions
  */
    const navigate = useNavigate();

    const handleRequestSort = (_event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = data.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (_event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const handleMoreMenuCell = (event) => {
        event.preventDefault();
    };

    const handleView = (ID) => {
        navigate(`./${ID}`);
    };

    const handleEdit = (ID) => {
        navigate(`./${ID}/edit/`);
    };

    const handleSalary = (ID) => {
        navigate(`./${ID}/add-salary/`);
    };

    const MORE_MENU = [{ text: 'View', icon: ViewIcon, event: handleView, id: 0 }];

    const MORE_MENU_MANAGER = [
        { text: 'View', icon: ViewIcon, event: handleView, id: 0 },
        { text: 'Edit', icon: EditIcon, event: handleEdit, id: 0 },
        { text: 'Add Salary', icon: AddIcon, event: handleSalary, id: 0 },
    ];

    /*
    Main Design.
  */
    return (
        <>
            <Card>
                <UserListToolbar
                    numSelected={selected.length}
                    filterName={filterName}
                    onFilterName={handleFilterByName}
                />
                <Scrollbar>
                    <TableContainer sx={{ minWidth: 800 }}>
                        <Table>
                            <UserListHead
                                order={order}
                                orderBy={orderBy}
                                headLabel={TableHeader}
                                rowCount={data.length}
                                numSelected={selected.length}
                                onRequestSort={handleRequestSort}
                                onSelectAllClick={handleSelectAllClick}
                                hideCheckBoxes={true}
                            />
                            <TableBody>
                                {filteredUsers
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                        const {
                                            id,
                                            name,
                                            username,
                                            image,
                                            rank,
                                            receivable,
                                            payable,
                                            left,
                                        } = row;
                                        const isItemSelected = selected.indexOf(id) !== -1;

                                        return (
                                            <TableRow
                                                hover
                                                key={id}
                                                component={Link}
                                                to={`./${username}`}
                                                tabIndex={-1}
                                                role="checkbox"
                                                selected={isItemSelected}
                                                aria-checked={isItemSelected}
                                                style={{
                                                    backgroundColor: palette.background.hover,
                                                    textDecoration: 'none',
                                                }}
                                            >
                                                <TableCell padding="checkbox">
                                                    {/* <Checkbox
                                checked={isItemSelected}
                                onChange={(event) => handleClick(event, name)}
                              /> */}
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    padding="none"
                                                >
                                                    <Stack
                                                        direction="row"
                                                        alignItems="center"
                                                        spacing={2}
                                                    >
                                                        <Avatar
                                                            alt={name}
                                                            src={
                                                                getImage(image) ||
                                                                SETTINGS.DefaultAvatar
                                                            }
                                                        />
                                                        <Typography variant="subtitle2" noWrap>
                                                            {name}
                                                        </Typography>
                                                    </Stack>
                                                </TableCell>
                                                <TableCell align="left"> {username}</TableCell>
                                                <TableCell align="left">
                                                    {' '}
                                                    {SETTINGS.RANKS_NAMES[rank]}
                                                </TableCell>
                                                <TableCell align="left">
                                                    Rs. {fNumber(receivable)}
                                                </TableCell>
                                                <TableCell align="left">
                                                    Rs. {fNumber(payable)}
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Label
                                                        variant="ghost"
                                                        color={
                                                            (left > 0 && 'success') ||
                                                            (left < 0 && 'error') ||
                                                            'info'
                                                        }
                                                    >
                                                        Rs. {fNumber(Math.abs(left))}
                                                    </Label>
                                                </TableCell>
                                                <TableCell
                                                    align="right"
                                                    onClick={handleMoreMenuCell}
                                                >
                                                    <UserMoreMenu
                                                        ID={[username]}
                                                        Options={
                                                            loggedInUser.rank >=
                                                                SETTINGS.RANKS.manager &&
                                                            loggedInUser.rank >= rank
                                                                ? MORE_MENU_MANAGER
                                                                : MORE_MENU
                                                        }
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={TableHeader.length + 1} />
                                    </TableRow>
                                )}
                            </TableBody>
                            {isUserNotFound && (
                                <TableBody>
                                    <TableRow>
                                        <TableCell
                                            align="center"
                                            colSpan={TableHeader.length + 1}
                                            sx={{ py: 3 }}
                                        >
                                            <SearchNotFound searchQuery={filterName} />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Scrollbar>

                <TablePagination
                    rowsPerPageOptions={SETTINGS.rowsPerPageList}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Card>
        </>
    );
};
