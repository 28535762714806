/*
  Imports
*/
import { Container } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import CenterLoading from 'src/components/misc/CenterLoading';
import ListPageTitle from 'src/components/misc/ListPageTitle';
import paymentsService from 'src/services/PaymentsServiceClass';
import slipsService from 'src/services/SlipsServiceClass';
import commissionsService from 'src/services/CommissionServiceClass';
import Page from '../components/Page';
import { SETTINGS } from 'src/config/settings';
import DatePickerWithSearch from '../components/misc/table/DatePickerWithSearch';
import Payments from './tables/PaymentsList';
import { PaymentsIcon, SlipsIcon, CommissionsIcon, CopyIcon } from 'src/config/icons';
import FloatingAdd from 'src/components/misc/Buttons/FloatingButton';
import Sum from './body/Sum';
import { func } from 'prop-types';
import { fNumber } from 'src/utils/formatNumber';
import { getDateString } from 'src/utils/dateTime';
/*
  Main Working
*/
export default () => {
    /*
    States, Params, Navigation, Query, Variables.
  */
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);

    const [sliploading, setSlipLoading] = useState(true);

    const [commissionloading, setCommissionLoading] = useState(true);

    const [pageLoading, setPageLoading] = useState(true);

    const [data, setData] = useState([]);

    const [slipData, setSlipData] = useState([]);

    const [commissionData, setCommissionData] = useState([]);

    const [pagination, setPagination] = useState({
        search: '',
        perPage: SETTINGS.defaultPerPage,
        page: 0,
        order: 'asc',
        sort_by: 'id',
    });

    const [slipTablePagination, setSlipTablePagination] = useState({
        search: '',
        perPage: SETTINGS.defaultPerPage,
        page: 0,
        order: 'asc',
        sort_by: 'id',
    });

    const [commissionTablePagination, setCommissionTablePagination] = useState({
        search: '',
        perPage: SETTINGS.defaultPerPage,
        page: 0,
        order: 'asc',
        sort_by: 'id',
    });

    const [date, setDate] = useState({
        startDate: null,
        endDate: null,
    });

    const title = 'Payments';

    /*
      Handlers, Functions
    */
    const getData = () => {
        setLoading(true);
        paymentsService
            .getPayments(pagination, date)
            .then((res) => {
                console.log('Payments List', res);
                setData(res);
            })
            .catch((error) => {
                if (error.response) if (error.response.status === 401) navigate('/401');
                navigate('/404');
            })
            .finally(() => {
                setLoading(false);
                setPageLoading(false);
            });
    };

    const getSlipsData = () => {
        setSlipLoading(true);
        slipsService
            .getSlips(pagination, date)
            .then((res) => {
                console.log('Slip List', res);
                setSlipData(res);
            })
            .catch((error) => {
                if (error.response) if (error.response.status === 401) navigate('/401');
                navigate('/404');
            })
            .finally(() => {
                setSlipLoading(false);
            });
    };

    const getCommissionsData = () => {
        setCommissionLoading(true);
        commissionsService
            .getCommissions(pagination, date)
            .then((res) => {
                console.log('Slip List', res);
                setCommissionData(res);
            })
            .catch((error) => {
                if (error.response) if (error.response.status === 401) navigate('/401');
                navigate('/404');
            })
            .finally(() => {
                setCommissionLoading(false);
            });
    };

    /*
          Use Effect Hooks.
        */

    useEffect(getData, [pagination, date]);

    useEffect(getSlipsData, [pagination, date]);

    useEffect(getCommissionsData, [pagination, date]);

    const handlePaymentAdd = () => {
        navigate('/payments/add');
    };

    const handleSlipAdd = () => {
        navigate('/slips/add');
    };

    const handleCommissionAdd = () => {
        navigate('/commissions/add');
    };

    const handleDeletePayment = (ID) => {
        paymentsService
            .remove(ID)
            .then(() => {
                getData();
            })
            .catch((err) => console.log(err));
    };

    const handleDeleteSlip = (ID) => {
        slipsService
            .remove(ID)
            .then(() => {
                getSlipsData();
            })
            .catch((err) => console.log(err));
    };

    const handleDeleteCommission = (ID) => {
        commissionsService
            .remove(ID)
            .then(() => {
                getCommissionsData();
            })
            .catch((err) => console.log(err));
    };

    function sum(data) {
        let Total = 0;
        for (let i = 0; i < data.length; i++) {
            Total += data[i].amount;
        }
        return Total;
    }

    const handleCopy = () => {
        let accounts = data.map((p) => p.details);
        console.log('Accounts', accounts);
        accounts = accounts.filter((v) => v.toLowerCase() != 'cash');
        accounts = new Set(accounts);
        let text = `🗓️ ${getDateString(date.startDate ?? new Date())}`;
        if (date.endDate) text += ` to ${getDateString(date.endDate)}`;
        text += '\n\n```💸 Payments```\n';
        if (data.length < 1) text += '\tNo Payments\n\n';
        let gtotal = 0;
        for (const account of accounts) {
            let total = 0;
            for (const payment of data) {
                if (account == payment.details) {
                    text += `\t${account} - ${fNumber(+payment.amount)}\n`;
                    total += +payment.amount;
                }
            }
            gtotal += total;
            if (total > 0) text += `*Total ${account} - ${fNumber(total)}*\n\n`;
        }
        text += `*Grand Total - ${fNumber(gtotal)}*\n\n`;

        if (commissionData.length > 0) {
            text += '```💳 Commission```\n';
            let total = 0;
            for (const com of commissionData) {
                text += `\t${fNumber(+com.amount)}\n`;
                total += +com.amount;
            }
            if (total > 0) text += `*Total - ${fNumber(total)}*\n\n`;
        }

        navigator.clipboard.writeText(text);
    };

    const handleKeyDown = (event) => {
        if (event.keyCode === 67) {
            handleCopy();
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown, false);

        return () => {
            document.removeEventListener('keydown', handleKeyDown, false);
        };
    }, [data, commissionData]);

    /*
      Use Effect Hooks.
    */

    /*
    Handlers, Functions
  */

    /*
    Main Design
  */
    return (
        <Page title={title}>
            <Container>
                {pageLoading ? (
                    <CenterLoading />
                ) : (
                    <>
                        <DatePickerWithSearch setDate={setDate} startDate={new Date()} />
                        <Sum
                            payment={{
                                total: sum(data),
                            }}
                            cpayment={sum(data.filter((v) => v.details.toLowerCase() == 'cash'))}
                            slip={{ total: sum(slipData) }}
                            commission={{ total: sum(commissionData) }}
                        />
                        <br />
                        <br />
                        <ListPageTitle>{title}</ListPageTitle>
                        <Payments
                            data={data}
                            pagination={pagination}
                            setPagination={setPagination}
                            loading={loading}
                            route={`/payments`}
                            handleDelete={handleDeletePayment}
                        />
                        <br />
                        <br />
                        <ListPageTitle>Slips</ListPageTitle>
                        <Payments
                            data={slipData}
                            pagination={slipTablePagination}
                            setPagination={setSlipTablePagination}
                            loading={sliploading}
                            route={`/slips`}
                            handleDelete={handleDeleteSlip}
                        />
                        <br />
                        <br />
                        <ListPageTitle>Commissions</ListPageTitle>
                        <Payments
                            data={commissionData}
                            pagination={commissionTablePagination}
                            setPagination={setCommissionTablePagination}
                            loading={commissionloading}
                            route={`/commissions`}
                            handleDelete={handleDeleteCommission}
                        />
                        <FloatingAdd
                            tooltip="add payment"
                            onClick={handlePaymentAdd}
                            bottomSpacing={(theme) => theme.spacing(2)}
                            icon={PaymentsIcon}
                        />
                        <FloatingAdd
                            tooltip="add slip"
                            onClick={handleSlipAdd}
                            bottomSpacing={(theme) => theme.spacing(10)}
                            icon={SlipsIcon}
                        />
                        <FloatingAdd
                            tooltip="add commission"
                            onClick={handleCommissionAdd}
                            bottomSpacing={(theme) => theme.spacing(18)}
                            icon={CommissionsIcon}
                        />
                        <FloatingAdd
                            tooltip="copy payments"
                            onClick={handleCopy}
                            bottomSpacing={(theme) => theme.spacing(26)}
                            icon={CopyIcon}
                        />
                    </>
                )}
            </Container>
        </Page>
    );
};
