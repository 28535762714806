/*
  Imports
*/
import { Container } from '@material-ui/core';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CenterLoading from 'src/components/misc/CenterLoading';
import Page from '../components/Page';
import ProductsDetails from './tables/ProductsDetails';
import ListPageTitle from 'src/components/misc/ListPageTitle';
import productsService from 'src/services/ProductsServiceClass';
import { ProductsIcon } from 'src/config/icons';
import FloatingAdd from 'src/components/misc/Buttons/FloatingButton';
import userService from 'src/services/UserService';
import { SETTINGS } from 'src/config/settings';

/*
  Main Working
*/
export default () => {
    /*
  States, Params, Navigation, Query, Variables.
*/
    const navigate = useNavigate();

    const loggedInUser = userService.getLoggedInUser();

    const [loading, setLoading] = useState(true);

    const [data, setData] = useState([]);

    const title = `Products Details`;

    /*
    Handlers, Functions
  */
    const getData = () => {
        setLoading(true);
        productsService
            .getAll()
            .then((res) => {
                console.log('Logs', res);
                setData(res);
            })
            .catch((error) => {
                if (error.response) if (error.response.status === 401) navigate('/401');
                navigate('/404');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    /*
    Use Effect Hooks.
  */
    useEffect(getData, []);

    const handleAdd = () => {
        navigate('./add');
    };

    const handleDeleteProduct = (ID) => {
        productsService
            .remove(ID)
            .then(() => {
                getData();
            })
            .catch((err) => console.log(err));
    };

    /*
  Use Effect Hooks.
*/
    /*
  Main Design
*/
    return (
        <Page title={title}>
            <Container>
                {loading ? (
                    <CenterLoading />
                ) : (
                    <>
                        <ListPageTitle>{title}</ListPageTitle>
                        <ProductsDetails data={data} handleDelete={handleDeleteProduct} />
                        {loggedInUser.rank >= SETTINGS.RANKS.manager && (
                            <FloatingAdd
                                tooltip="add product"
                                onClick={handleAdd}
                                bottomSpacing={(theme) => theme.spacing(2)}
                                icon={ProductsIcon}
                            />
                        )}
                    </>
                )}
            </Container>
        </Page>
    );
};
