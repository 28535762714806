/*
	Imports
*/
import { Icon } from '@iconify/react';
import {
	Alert,
	Collapse,
	IconButton, InputAdornment,
	Link, Stack,
	TextField
} from '@material-ui/core';
import { Form, FormikProvider, useFormik } from 'formik';
import { useState } from 'react';
import NumberFormat from 'react-number-format';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';

import ReactInputMask from 'react-input-mask';
import LoadingFormButton from 'src/components/misc/Buttons/LoadingFormButton';
import { LoginSchema } from 'src/config/form-schemas';
import { CNICIcon, HidePasswordIcon, PasswordIcon, ShowPasswordIcon, UsersIcon } from 'src/config/icons';
import { RouteDashboard, RouteForgetPass } from 'src/config/routes';
import palette from 'src/theme/palette';
import userService from 'src/services/UserService';

/*
	Main Working
*/
export default ({ }) => {
	/*
	  States, Params, Navigation, Query.
	*/
	const [serverError, setServerError] = useState('');
	const [showPassword, setShowPassword] = useState(false);

	const navigate = useNavigate();
	const location = useLocation();

	/*
	  Form Setup
	*/
	const formik = useFormik({
		initialValues: {
			username: '',
			password: ''
		},
		validationSchema: LoginSchema,
		onSubmit: (values, { setSubmitting, setFieldError }) => {

			userService.login(values.username, values.password)
				.then((_data) => {
					console.log("This worked fine");
					navigate(location.state?.from ? location.state.from : RouteDashboard, { replace: true });
					//navigate(RouteDashboard);
				})
				.catch((err) => {
					console.log("Error", err, err.response);
					setSubmitting(false);
					if (!err.response) {
						setServerError('Error occured please try later');
					} else {
						const { data } = err.response;
						if (data?.statusCode == 404)
							setFieldError('username', "User does not exist");
						if (data?.statusCode == 401)
							setFieldError('password', "Incorrect password");
					}
				});
		}
	});
	const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

	/*
	  Handlers
	*/

	const handleShowPassword = () => {
		setShowPassword((show) => !show);
	};

	/*
	  Use Effect Hooks.
	*/


	/*
	  Main Design
	*/
	return (

		<FormikProvider value={formik}>
			<Form autoComplete="off" noValidate onSubmit={handleSubmit}>
				<Stack spacing={3}>
					<TextField
						fullWidth
						autoComplete="current-username"
						type='text'
						label="Username"
						{...getFieldProps('username')}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<Icon icon={UsersIcon} inline="true" style={{ fontSize: 20 }} />
								</InputAdornment>
							)
						}}
						error={Boolean(touched.username && errors.username)}
						helperText={touched.username && errors.username}
					/>


					<br />

					<TextField
						fullWidth
						autoComplete="current-password"
						type={showPassword ? 'text' : 'password'}
						label="Password"
						{...getFieldProps('password')}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton onClick={handleShowPassword} edge="end">
										<Icon icon={showPassword ? HidePasswordIcon : ShowPasswordIcon} />
									</IconButton>
								</InputAdornment>
							),
							startAdornment: (
								<InputAdornment position="start">
									<Icon icon={PasswordIcon} inline="true" style={{ fontSize: 20 }} />
								</InputAdornment>
							)
						}}
						error={Boolean(touched.password && errors.password)}
						helperText={touched.password && errors.password}
					/>
					{/* {!isSubmitting &&
						<Link
							component={RouterLink}
							color={palette.error.light}
							style={{ textDecoration: 'none', textAlign: 'right' }}
							variant="subtitle2"
							to={RouteForgetPass}
						>
							Forgot password?
						</Link>
					} */}

				</Stack>

				<br />

				<LoadingFormButton
					style={{ padding: '0px 70px' }}
					size="large"
					type="submit"
					variant="contained"
					loading={isSubmitting}
				>
					Login
				</LoadingFormButton>
				{serverError &&
					<Stack sx={{ width: '50%' }} marginTop={3}>
						<Collapse in={!!serverError}>
							<Alert severity="error">
								{serverError}
							</Alert>
						</Collapse>
					</Stack>
				}
			</Form>
		</FormikProvider>
	);
};
