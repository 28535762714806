/*
  Imports
*/
import { Container } from '@material-ui/core';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import billService from 'src/services/BillServiceClass';
import Page from '../components/Page';
import ListPageTitle from 'src/components/misc/ListPageTitle';
import BillsList from './tables/CustomerBillsList';
import { SETTINGS } from 'src/config/settings';
import DatePickerWithSearch from '../components/misc/table/DatePickerWithSearch';
import CenterLoading from 'src/components/misc/CenterLoading';
import FloatingAdd from 'src/components/misc/Buttons/FloatingButton';
import { BillsIcon, ReturnBillIcon } from 'src/config/icons';
import userService from 'src/services/UserService';
/*
  Main Working
*/
export default () => {
    /*
    States, Params, Navigation, Query, Variables.
  */
    const navigate = useNavigate();

    const [pageLoading, setPageLoading] = useState(true);

    const [billTableLoading, setBillTableLoading] = useState(true);

    const [bill, setBill] = useState([]);

    const [billTablePagination, setBillTablePagination] = useState({
        search: '',
        perPage: SETTINGS.defaultPerPage,
        page: 0,
        order: 'desc',
        sort_by: 'id',
    });

    const [date, setDate] = useState({
        startDate: null,
        endDate: null,
    });

    const title = `Bills`;

    const user = userService.getLoggedInUser();

    /*
      Handlers, Functions
    */

    const getBill = () => {
        setBillTableLoading(true);
        billService
            .getAll(billTablePagination, date)
            .then((res) => {
                setBill(res);
            })
            .catch((error) => {
                if (error.response) if (error.response.status === 401) navigate('/401');
                navigate('/404');
                console.log('Error Response', error.response);
                console.log('Error ', error);
            })
            .finally(() => {
                setBillTableLoading(false);
                setPageLoading(false);
            });
    };

    /*
      Use Effect Hooks.
    */

    useEffect(getBill, [billTablePagination, date]);

    const handleAdd = () => {
        navigate('./generate');
    };

    const handleReturn = () => {
        navigate('/customers/return');
    };

    const handleDeleteBill = (ID) => {
        billService
            .remove(ID)
            .then(() => {
                getBill();
            })
            .catch((err) => console.log(err));
    };

    /*
    Use Effect Hooks.
  */
    /*
    Main Design
  */
    return (
        <Page title={title}>
            <Container>
                {pageLoading ? (
                    <CenterLoading />
                ) : (
                    <>
                        <DatePickerWithSearch setDate={setDate} />
                        <ListPageTitle>{title}</ListPageTitle>
                        <BillsList
                            data={bill}
                            pagination={billTablePagination}
                            setPagination={setBillTablePagination}
                            loading={billTableLoading}
                            handleDelete={handleDeleteBill}
                        />
                        <FloatingAdd
                            tooltip="add bills"
                            onClick={handleAdd}
                            bottomSpacing={(theme) => theme.spacing(2)}
                            icon={BillsIcon}
                        />
                        {user.rank >= SETTINGS.RANKS.manager && (
                            <FloatingAdd
                                tooltip="return bill"
                                onClick={handleReturn}
                                bottomSpacing={(theme) => theme.spacing(10)}
                                icon={ReturnBillIcon}
                            />
                        )}
                    </>
                )}
            </Container>
        </Page>
    );
};
